import React, { useEffect, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import bids from './bids.js';
import styles from './AdfoxAdv.module.scss';

const AdfoxAdv = ({
	container,
	p1,
	p2,
	isSticky,
	isStatic,
	withoutStyles,
	autoRefresh,
	ads,
	id,
	desktop,
	mobile,
	onTheRight,
	...rest
}) => {
	const ref = useRef();

	const theme = useSelector((state) => state.global.theme);
	const areAdScriptsLoaded = useSelector((state) => state.global.areAdScriptsLoaded);
	const [containerId, setContainerId] = useState();
	const [timesRendered, setTimesRendered] = useState(0);
	const [adBlockOn, setAdBlockOn] = useState(true);

	const deviceType = desktop ? ['desktop', 'tablet'] : mobile ? ['phone'] : null;

	const debounceTheme = useDebounce(theme, 1000);

	useEffect(() => {
		ref.current.style.minHeight = `${ref.current.getBoundingClientRect().height}px`;
		ref.current.innerHTML = '';
	}, [debounceTheme]);

	useEffect(() => {
		if (!areAdScriptsLoaded) return;
		// find out containerId
		let name = container;
		let finalId = '';
		if (name) {
			if (name.includes('adfox_151705922056116114')) {
				// create unique id for each reloading ads block for correct reload
				const key = _uniqueId();
				finalId = `adfox_aside_reloading_${key}`;
				if (
					window.innerWidth > 1200 &&
					window.Ya &&
					window.Ya.adfoxCode &&
					window.Ya.headerBidding
				) {
					window.Ya.headerBidding.pushAdUnits([
						{
							code: finalId,
							sizes: [[300, 600]],
							bids: bids.adfox_151705922056116114
						}
					]);
				}
			} else if (name.includes('adfox_151705922056116117')) {
				// render ids for mobileAds in materials
				const key = _uniqueId();
				finalId = `adfox_mobile_${key}`;
				if (
					window.Ya &&
					window.Ya.adfoxCode &&
					window.Ya.headerBidding &&
					window.innerWidth < 480
				) {
					window.Ya.headerBidding.pushAdUnits([
						{
							code: finalId,
							sizes: [[300, 250]],
							bids: bids.adfox_151705922056116117
						}
					]);
				}
			} else if (name.includes('adfox_737976580163075300')) {
				// render ids for desktop on picture
				const key = _uniqueId();
				finalId = `adfox_pictureDesktop_${key}`;
				if (
					window.innerWidth &&
					window.Ya?.headerBidding &&
					window.yaContextCb &&
					window.innerWidth > 480
				) {
					window.Ya.adfoxCode.hbCallbacks.push(() => {
						window.Ya.headerBidding.pushAdUnits([
							{
								code: finalId,
								sizes: [
									[970, 250],
									[1356, 250]
								],
								bids: bids.adfox_737976580163075300
							}
						]);
					});
					window.yaContextCb.push(() => {
						window.Ya.adfoxCode.createAdaptive(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				}
			} else if (name === 'adfox_151705922056116112') {
				// add to bidder map bids of ads after material
				const key = _uniqueId();
				finalId = `adfox_after_material_${key}`;
				if (
					window.Ya &&
					window.Ya.adfoxCode &&
					window.Ya.headerBidding &&
					window.innerWidth > 480
				) {
					window.Ya.headerBidding.pushAdUnits([
						{
							code: finalId,
							sizes: [
								[970, 250],
								[970, 90],
								[970, 240],
								[1120, 200]
							],
							bids: bids.adfox_151705922056116112
						}
					]);
				}
			} else if (name.includes('adfox_151705922056116858')) {
				// ads before 'read more' desktop block
				finalId = `adfox_readMore_desktop`;
				if (
					window.Ya &&
					window.Ya.adfoxCode &&
					window.Ya.headerBidding &&
					window.innerWidth > 480
				) {
					window.Ya.headerBidding.pushAdUnits([
						{
							code: finalId,
							sizes: [
								[670, 175],
								[580, 400]
							],
							bids: bids.adfox_151705922056116858
						}
					]);
				}
			} else if (name.includes('adfox_151705942291497217')) {
				// ads before 'read more' desktop block
				finalId = `adfox_readMore_mobile`;
				if (
					window.Ya &&
					window.Ya.adfoxCode &&
					window.Ya.headerBidding &&
					window.innerWidth < 480
				) {
					window.Ya.headerBidding.pushAdUnits([
						{
							code: finalId,
							sizes: [[300, 250]],
							bids: bids.adfox_151705942291497217
						}
					]);
				}
			} else finalId = `${name}_${_uniqueId()}`;
			setContainerId(finalId);
			setTimesRendered(1);
		}
	}, [areAdScriptsLoaded, debounceTheme]);

	useEffect(() => {
		if (!areAdScriptsLoaded) return;

		const returnedCallbacks = [];

		// check if adblock is turned on
		let advCanDisplay = true;
		let result;
		try {
			Ya && Ya.adfoxCode.hasOwnProperty('createScroll');
		} catch (e) {
			advCanDisplay = false;
		} finally {
			result = !advCanDisplay;
			setAdBlockOn(result);
		}

		if (!result && containerId && timesRendered === 1) {
			if (window.yaContextCb) {
				if (containerId.includes('adfox_mobile')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								},
								onError: (message) => console.log('error :>>', message),
								lazyLoad: {
									fetchMargin: 200,
									mobileScaling: 1
								}
							},
							deviceType,
							{
								tabletWidth: 1050,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('164542048788111928')) {
					const handleScroll = () => {
						const { clientHeight } = document.documentElement;

						if (clientHeight * 2 < window.scrollY) {
							window.removeEventListener('scroll', handleScroll);

							window.Ya.adfoxCode.hbCallbacks.push(() => {
								window.Ya.headerBidding.pushAdUnits([
									{
										code: 'adfox_164542048788111928',
										sizes: [[360, 100]],
										bids: bids.adfox_164542048788111928
									}
								]);
							});
							window.yaContextCb.push(() => {
								window.Ya.adfoxCode.createAdaptive(
									{
										ownerId: 256923,
										containerId: 'adfox_164542048788111928',
										type: 'floorAd',
										params: {
											p1,
											p2,
											pk: theme,
											pke: '1'
										}
									},
									['phone'],
									{
										tabletWidth: 830,
										phoneWidth: 480,
										isAutoReloads: false
									}
								);
							});
						}
					};
					// insert catfish ads
					window.addEventListener('scroll', handleScroll);

					returnedCallbacks.push(() => window.removeEventListener('scroll', handleScroll));
				} else if (containerId.includes('adfox_readMore_desktop')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								}
							},
							deviceType,
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('adfox_readMore_mobile')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								}
							},
							deviceType,
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('adfox_151705922056116118')) {
					window.yaContextCb.push(() => {
						window.Ya.adfoxCode.createAdaptive(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								},
								onError: (message) => console.log('error :>>', message),
								lazyLoad: {
									fetchMargin: 200,
									mobileScaling: 1
								}
							},
							deviceType,
							{
								tabletWidth: 1050,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('adfox_16625658409024519')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 150,
									mobileScaling: 1
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('adfox_16625659031964479')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 150,
									mobileScaling: 1
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('166256728065114518')) {
					const handleScroll = () => {
						const { clientHeight } = document.documentElement;

						if (clientHeight * 2 < window.scrollY) {
							window.removeEventListener('scroll', handleScroll);

							window.yaContextCb.push(() => {
								Ya.adfoxCode.createAdaptive(
									{
										ownerId: 309114,
										containerId,
										type: 'floorAd',
										params: {
											p1,
											p2
										}
									},
									['phone'],
									{
										tabletWidth: 830,
										phoneWidth: 480,
										isAutoReloads: false
									}
								);
							});
						}
					};
					// insert catfish ads
					window.addEventListener('scroll', handleScroll);

					returnedCallbacks.push(() => window.removeEventListener('scroll', handleScroll));
				} else if (containerId.includes('166256722403843331')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 200,
									mobileScaling: 1
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('166256623182496700')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 150,
									mobileScaling: 1
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('16625660686179208')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 150,
									mobileScaling: 1
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('16625660686179208')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 150,
									mobileScaling: 1
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('16625659754332892')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('16625670850546143')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 200,
									mobileScaling: 1
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('166256717635586273')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 309114,
								containerId,
								params: {
									p1,
									p2
								},
								lazyLoad: {
									fetchMargin: 200,
									mobileScaling: 1
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				} else if (containerId.includes('adfox_fullscreen')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['tablet', 'phone'],
							{
								tabletWidth: 1280,
								phoneWidth: 720,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_billboard_1')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_320x100_1')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_billboard_2')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_320x100_2')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_billboard_3')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet', 'phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_300x600_2')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet', 'phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_billboard_under_article_1')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createScroll(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['desktop', 'tablet'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_320x100_3')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['phone'],
							{
								tabletWidth: 830,
								phoneWidth: 480,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_Interscroller')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.createAdaptive(
							{
								ownerId: 386413,
								containerId,
								params: {
									p1,
									p2
								}
							},
							['phone'],
							{
								tabletWidth: 1280,
								phoneWidth: 720,
								isAutoReloads: true
							}
						);
					});
				} else if (containerId.includes('adfox_outstream')) {
					window.yaContextCb.push(() => {
						Ya.adfoxCode.create({
							ownerId: 386413,
							containerId,
							params: {
								p1,
								p2,
								insertAfter: '.bplace',
								insertPosition: '0',
								stick: false,
								stickTo: 'bottom-right',
								stickyColorScheme: 'light',
								replayMode: 'auto'
							}
						});
					});
				} else {
					window.yaContextCb.push(() => {
						window.Ya.adfoxCode.createAdaptive(
							{
								ownerId: 256923,
								containerId,
								params: {
									p1,
									p2,
									pk: theme,
									pke: '1'
								}
							},
							deviceType,
							{
								tabletWidth: 1050,
								phoneWidth: 480,
								isAutoReloads: false
							}
						);
					});
				}
			}

			if (autoRefresh) {
				const CONTAINER_ID = containerId;
				(function () {
					const ADFOX_CONTAINER_ID = CONTAINER_ID;
					const INTERVAL = 30000;
					function reload() {
						if (!window.Ya || !window.Ya.adfoxCode) {
						} else {
							return window.Ya.adfoxCode.reload(ADFOX_CONTAINER_ID);
						}
					}
					let timerId;
					function enableUpdateAdfox() {
						clearInterval(timerId);
						timerId = setInterval(reload, INTERVAL);
					}
					function disableUpdateAdfox() {
						return clearInterval(timerId);
					}
					function handleObserverEvent(entries) {
						const isAppeared = entries[0].intersectionRatio > 0;
						return isAppeared ? enableUpdateAdfox() : disableUpdateAdfox();
					}

					const adfoxContainer = document.getElementById(ADFOX_CONTAINER_ID);
					if (adfoxContainer && window.IntersectionObserver) {
						const observer = new IntersectionObserver(handleObserverEvent);
						observer.observe(adfoxContainer);
					}
					function isInViewport(element) {
						const rect = element.getBoundingClientRect();
						const html = document.documentElement;
						return (
							rect.top >= 0 &&
							rect.left >= 0 &&
							rect.bottom <= (window.innerHeight || html.clientHeight) &&
							rect.right <= (window.innerWidth || html.clientWidth)
						);
					}
					function onBlur() {
						disableUpdateAdfox();
					}
					function onFocus() {
						if (adfoxContainer && isInViewport(adfoxContainer)) {
							enableUpdateAdfox();
						}
					}

					const handleVisibilityChange = () => {
						if (document.hidden) {
							onBlur();
						} else {
							onFocus();
						}
					};

					document.addEventListener('visibilitychange', handleVisibilityChange);

					returnedCallbacks.push(() =>
						document.removeEventListener('visibilitychange', handleVisibilityChange)
					);
				})();
			}
		}

		return () => returnedCallbacks.forEach((callback) => callback());
	}, [containerId, areAdScriptsLoaded, debounceTheme]);

	return (
		<div
			className={cx({
				[styles.empty]: adBlockOn,
				[styles.root]: !adBlockOn && !withoutStyles,
				[styles.sticky]: isSticky,
				[styles.static]: isStatic,
				[styles.onTheRight]: onTheRight
			})}
		>
			<div ref={ref} className={styles.container} id={containerId} {...rest} />
		</div>
	);
};

export default AdfoxAdv;
