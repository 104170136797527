import { configureStore } from '@reduxjs/toolkit';
import { dev } from 'src/api';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import * as slices from 'src/store/slices/exportSlices';

const store = configureStore({
	reducer: slices,
	devTools: dev
});

store.subscribe(() => {
	const {
		profile: { userSubscriptions },
		constructorState: { isUserSubscriptionsVisible, isPictureOfTheDayVisible }
	} = store.getState();

	localStorage.setItem(LOCAL_STORAGE_KEYS.USER_SUBSCRIPTIONS, JSON.stringify(userSubscriptions));
	localStorage.setItem(
		LOCAL_STORAGE_KEYS.IS_USER_SUBSCRIPTIONS_VISIBLE,
		isUserSubscriptionsVisible
	);
	localStorage.setItem(
		LOCAL_STORAGE_KEYS.IS_PICTURE_OF_THE_DAY_VISIBLE,
		JSON.stringify(isPictureOfTheDayVisible)
	);
});

export default store;
