import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';
import notify from 'src/components/Base/Elements/Toast';

export const fetchIssue = createAsyncThunk(
	`ISSUEs/fetchIssue`,
	async (id, { rejectWithValue }) => {
		const response = await fetch(`${root}/get/newspaper?slug=${id}`)
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
					throw data.error;
				} else {
					return data;
				}
			})
			.catch((err) => {
				notify(err);
				if (dev) console.log(`Error fetch issue: ${err}`);
				return rejectWithValue(err);
			});
		return response;
	}
);

export const fetchNewspapersForMain = createAsyncThunk(
	`ISSUES/fetchNewspapersForMain`,
	async (_, { rejectWithValue }) => {
		const response = await fetch(`${root}/get/newspapers`)
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
					throw data.error;
				} else {
					return data;
				}
			})
			.catch((err) => {
				if (dev) console.log(`Error fetch newspapers: ${err}`);
				return rejectWithValue(err);
			});
		return response;
	}
);

const issuesSlice = createSlice({
	name: 'ISSUES',
	initialState: {
		issue: null,
		newspapers: null,
		error: false,
	},
	reducers: {
		clearError: (state) => (state.error = false),
	},
	extraReducers: {
		// fetchIssue
		[fetchIssue.fulfilled]: (state, action) => {
			state.error = false;
			state.issue = action.payload;
		},
		[fetchIssue.rejected]: (state, action) => {
			state.error = action.payload;
			state.issue = null;
		},
		// fetchNewspapersForMain
		[fetchNewspapersForMain.fulfilled]: (state, action) => {
			state.error = false;
			state.newspapers = action.payload.newspapers;
		},
		[fetchNewspapersForMain.rejected]: (state, action) => {
			state.error = action.payload;
			state.newspapers = null;
		},
	},
});

export default issuesSlice.reducer;
