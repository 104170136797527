import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { iOS, isEurope, isMR7, pushNotification } from 'src/api';
import { fetchNetwork } from 'src/store/slices/networkSlice';
import { fetchMeta } from 'src/store/slices/metaSlice';
import { fetchMain } from 'src/store/slices/mainSlice';
import { fetchDistricts } from './store/slices/mr7Slice';
import { fetchArchivePosts } from './store/slices/europeSlice';
import store from './store';
import App from './App';

store.dispatch(fetchNetwork());
store.dispatch(fetchMeta());
store.dispatch(fetchMain());

if (isMR7) {
	store.dispatch(fetchDistricts());
}

if (isEurope) {
	store.dispatch(fetchArchivePosts(0));
}

const root = createRoot(document.getElementById('ng-root'));

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

if (pushNotification.config && !iOS) {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('/firebase-messaging-sw.js')
			.then((registration) => {
				console.log('Registration successful, scope is:', registration.scope);
			})
			.catch((err) => {
				console.log('Service worker registration failed, error:', err);
			});
	}
}
