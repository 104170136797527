export const MAIN = {
	ARTICLE: '/articles/:year/:month/:day/:code',
	ARTICLE_TEST_RESULT: '/articles/:year/:month/:day/:code/result/:md5Hex',
	AUTHOR: '/authors/:authorId',
	AUTHORS: '/authors',
	DONATE: '/donate',
	DONATE_MONTHLY: '/donate-monthly',
	SINGLE_NEWS: '/news/:year/:month/:day/:code',
	INDEX: '/',
	ISSUE: '/issues/:issueId',
	ISSUES: '/issues',
	LECTURES: '/lectures',
	NEWS: '/news',
	PROMO: '/promo',
	PROMO_MEDIA: '/no/promo',
	SEARCH_SUBSCRIPTION: '/search/subscription',
	TAG: '/tags',
	THEME: '/themes/:themeId',
	VIRUS: '/coronavirus',
	LONGREADS: '/longreads/:slug'
};

export const OLD = {
	POLITICS: '/politics/:code',
	INQUESTS: '/inquests/:code',
	COLUMNS: '/columns/:code',
	ECONOMY: '/economy/:code',
	COMMENTS: '/comments/:code',
	SOCIETY: '/society/:code',
	ARTS: '/arts/:code',
	SPORTS: '/sports/:code',
	PHOTOS: '/photos/:code',
	ARTICLE: '/articles/:oldCode',
};
