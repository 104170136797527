import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as api from 'src/api';
import { setAreAdScriptsLoaded } from 'src/store/slices/globalSlice';

const useDynamicAds = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (api.isNoAdsProfile) return;

		let numberOfRequiredScripts = 0;

		const createScript = (src, onLoad, crossorigin) => {
			numberOfRequiredScripts++;

			const script = document.createElement('script');

			script.src = src;

			if (crossorigin) script.crossorigin = 'anonymous';

			document.head.appendChild(script);

			script.addEventListener('load', onLoad);
			script.addEventListener('error', onLoad);
			script.addEventListener('abort', onLoad);
		};

		const events = ['scroll', 'click', 'mousemove', 'touchstart'];

		let counter = 0;

		const onLoad = () => {
			counter++;

			if (counter === numberOfRequiredScripts) dispatch(setAreAdScriptsLoaded(true));
		};

		const createAdScripts = () => {
			createScript('https://yandex.ru/ads/system/context.js', onLoad);
			createScript('https://yastatic.net/pcode/adfox/header-bidding.js', onLoad);
			createScript('//static.criteo.net/js/ld/publishertag.js', () => {
				window.Criteo = window.Criteo || {};
				window.Criteo.events = window.Criteo.events || [];

				window.callAdblock = (zoneid, width, height) => {
					Criteo.events.push(() => {
						Criteo.DisplayAcceptableAdIfAdblocked({
							zoneid,
							containerid: `crt-${zoneid}`,
							overrideZoneFloor: false
						});
					});
					window.addEventListener(
						'message',
						(e) => {
							if (e.data && e.data == zoneid)
								document.getElementById(`crt-${zoneid}`).style.display = 'none';
						},
						false
					);
					var el = document.getElementById(`crt-${zoneid}`);
					el.style.width = `${width}px`;
					el.style.height = `${height}px`;
				};
				onLoad();
			});

			events.forEach((event) => window.removeEventListener(event, createAdScripts));
		};

		if (api.isMR7) {
			createAdScripts();
		} else {
			events.forEach((event) => window.addEventListener(event, createAdScripts));
		}
	}, []);
};

export default useDynamicAds;
