import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { changeLang } from 'src/store/slices/globalSlice';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { CIS_languages } from 'src/utils/CIS_languages';
import * as api from 'src/api';

const useAppLanguage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const language = useSelector((state) => state.global.language);
	const { pathname } = history.location;

	useLayoutEffect(() => {
		let languageFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);

		if (!api.isMultiLang) {
			localStorage.removeItem(LOCAL_STORAGE_KEYS.LANGUAGE);
			pathname === '/en' && history.push('/');
		} else {
			if (!languageFromLocalStorage) {
				let lang = 'ru';
				let userLang = 'ru';
				try {
					userLang = navigator.language;
				} catch (error) {}

				const isRusLang = CIS_languages.findIndex((lang) => userLang.includes(lang)) !== -1;

				if (!isRusLang || pathname === '/en' || (api.isEurope && pathname === '/donate/en')) {
					lang = 'en';
				}
				if (lang !== language) {
					dispatch(changeLang(lang));
				}
			} else if (pathname === '/en' || (api.isEurope && pathname === '/donate/en')) {
				dispatch(changeLang('en'));
			}

			if (pathname === '/en') history.push('/');
			if (pathname === '/donate/en') history.push('/donate');
		}
	}, []);
};

export default useAppLanguage;
