import qs from 'querystring';
import * as api from 'src/api';
import url from 'url';
import { Component } from 'react';

class AuthPopup extends Component {
	constructor(...args) {
		super(...args);
		this.polling = false;
		this.pollPopup = this.pollPopup.bind(this);
	}

	render() {
		return null;
	}

	componentDidMount() {
		const options = {
			width: 500,
			height: 500,
			top: window.screenY + (window.outerHeight - 500) / 2.5,
			left: window.screenX + (window.outerWidth - 500) / 2,
		};

		this.pollPopup(
			window.open(this.props.url, '_blank', qs.stringify(options, ','))
		);
	}

	componentWillUnmount() {
		if (this.polling) {
			clearInterval(this.polling);
		}
	}

	pollPopup(window) {
		const redirectUri = url.parse(this.props.redirectUri);
		const redirectUriPath = redirectUri.host + redirectUri.pathname;

		this.polling = setInterval(() => {
			if (!window || window.closed) {
				clearInterval(this.polling);
			}

			try {
				const popupUrlPath = window.location.host + window.location.pathname;

				if (popupUrlPath === redirectUriPath) {
					if (window.location.search || window.location.hash) {
						const query = qs.parse(
							window.location.search.substring(1).replace(/\/$/, '')
						);
						const hash = qs.parse(
							window.location.hash.substring(1).replace(/[/$]/, '')
						);
						const params = { ...query, ...hash };

						if (params.error) {
							if (api.dev) {
								console.error(params.error);
							}
						} else {
							window.close();
							this.props.onAuth(params);
						}
					} else {
						if (api.dev) {
							console.info(
								'OAuth redirect has occurred but no query or hash parameters were found.'
							);
						}
					}
				}
			} catch (err) {
				if (api.dev) {
					console.log(err);
				}
			}
		}, 300);
	}
}

export default AuthPopup;
