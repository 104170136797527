import React, { lazy } from 'react';
import MainLayout from 'src/layouts/MainLayout';
import { transcription } from 'src/config/static';
import { isBaltia, isEurope, isMR7 } from 'src/api';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const Genre = lazy(() => import('src/content/Genre/Genre'));

const CategoryStories = (props) => {
	const { language } = useSelector((state) => state.global);
	const isRus = language === 'ru';
	const title = useChangeWindowTitle({
		currentTitle: isBaltia || isEurope || isMR7 ? 'all.stories' : 'all.genre.reportazhi'
	});

	let type = transcription[props.location.pathname.substring(1)];

	if (isBaltia) {
		type = 'istorii';
	}

	if (isEurope) {
		type = isRus ? 'istorii' : 'stories';
	}

	if (isMR7) {
		type = 'istorii';
	}

	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Genre type={type} pathname={props.location.pathname} />
		</MainLayout>
	);
};

export default CategoryStories;
