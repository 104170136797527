import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createProfile } from 'src/store/slices/profileSlice';
import Loader from 'react-loader-spinner';
import { REDIRECTS } from 'src/config/redirects';
import { notify } from 'src/components/Base/Elements/Toast';
import { isMobile } from 'react-device-detect';
import { route } from 'src/api';
import styles from './Sign.module.scss';

const Sign = (props) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const [password, setPassword] = useState('');
	const [password_confirmation, setPassword_confirmation] = useState('');
	const [email, setEmail] = useState('');
	const [displayName, setDisplayName] = useState('');

	const history = useHistory();

	useEffect(() => {
		if (profile.user) {
			props.onClose();
			history.push(isMobile ? '/profile' : REDIRECTS.PROFILE);
		}
	}, [profile.user]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (password === password_confirmation) {
			dispatch(
				createProfile({
					email,
					displayName,
					password
				})
			);
		} else {
			notify('Убедитесь, что поля пароля и подтверждения пароля полностью совпадают.');
		}
	};

	return (
		<>
			<Helmet>
				<title>Регистрация</title>
			</Helmet>
			<form className={styles.root} onSubmit={handleSubmit}>
				<label>Email</label>
				<input
					type='email'
					name='email'
					value={email}
					className={styles.input}
					onChange={(e) => setEmail(e.target.value.trim())}
					required
				/>

				<label>Отображаемое имя</label>
				<input
					type='text'
					name='name'
					value={displayName}
					className={styles.input}
					onChange={(e) => setDisplayName(e.target.value.trim())}
					required
				/>

				<label>Введите пароль</label>
				<input
					type='password'
					name='password'
					value={password}
					className={styles.input}
					onChange={(e) => setPassword(e.target.value.trim())}
					required
				/>

				<label>Подтвердите пароль</label>
				<input
					type='password'
					name='password_confirmation'
					value={password_confirmation}
					className={styles.input}
					onChange={(e) => setPassword_confirmation(e.target.value.trim())}
					required
				/>
				<footer className={styles.footer}>
					<p>
						Авторизуясь, я принимаю{' '}
						<a rel='noreferrer' href={`https://${route}/privacy`} target='_blank'>
							условия пользовательского соглашения и политику конфиденциальности
						</a>{' '}
					</p>
					<button
						type='submit'
						className={cx(styles.submit, {
							[styles.pending]: profile.isPending
						})}
					>
						<span>Готово</span>
						<Loader type='TailSpin' color='#8b8c8c' height='30' width='100' />
					</button>
				</footer>
			</form>
		</>
	);
};

export default Sign;
