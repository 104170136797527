import React from 'react';
import useUrlParams from 'src/hooks/useUrlParams';
import { Link } from 'react-router-dom';
import { footer } from 'config/data';
import Column from '../Footer/Column/Column';
import styles from './index.module.scss';
import MoscowConnection from '../Footer/Connection/MoscowConnection';

const LandingConstructorFooter = () => {
	const { isPrint } = useUrlParams();
	const { newsroom, socials, push, error, donater } = footer;

	return !isPrint ? (
		<footer className={styles.root}>
			<div className={styles.root_container}>
				<div className={styles.root_navigation}>
					<div className={styles.root_navigation_first}>
						<div className={styles.root_navigation_first_one}>
							<Column {...socials} />
							<Column {...push} />
							<Column {...error} notShowOnMobile />
						</div>
						<div className={styles.root_navigation_first_two}>
							<Column {...newsroom} />
							<Column {...donater} />
						</div>
					</div>
					<div className={styles.root_navigation_second}>
						<MoscowConnection />
					</div>
				</div>
				<p className={styles.root_text}>
					<span>Учредитель и издатель:</span> АО «Новая газета». <span>Редакция:</span> АНО
					«Редакционно-издательский дом «Новая газета». <span>Адрес:</span> 101000, Москва,
					Потаповский переулок, 3, строение 1. Ⓒ АО «ИД «Новая газета», АНО «РИД «Новая газета»,
					2022 г. Любое использование материалов допускается только по согласованию с Редакцией.
					18+. <Link to='/terms'>Пользовательское соглашение</Link>.{' '}
					<Link to='/privacy'>Политика конфиденциальности</Link>.
				</p>
				<div className={styles.logo}>
					<img src='/logo_constructor_frontend.svg' alt='logo' />
					<span>новая рассказ- газета</span>
				</div>
			</div>
		</footer>
	) : null;
};

export default LandingConstructorFooter;
