import React, { lazy, Suspense, useEffect } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { isConstructor, route, staticUrl } from 'src/api';
import MainLayout from 'src/layouts/MainLayout';
import { fetchAuthor } from 'src/store/slices/authorsSlice';

const AuthorContent = lazy(() => import('src/content/Authors/Author/Author'));
const SubscriptionContent = lazy(() => import('src/content/Subscription'));

const Author = (props) => {
	const { authorId } = useParams();
	const dispatch = useDispatch();
	const { author, isPending } = useSelector((state) => state.authors);
	const renderAuthor = !isEmpty(author);
	const { promoPicture, avatarUrl, name, surname, caption, description, records, id } =
		author || {};

	useEffect(() => {
		dispatch(fetchAuthor(authorId));
	}, [dispatch, authorId]);

	const url = `https://${route}${window.location.pathname}`;

	let seoData = {
		author: '',
		authorCaption: '',
		picture: '',
		short_title: '',
		long_title: ''
	};

	if (renderAuthor && id === +authorId) {
		seoData = {
			author: `${name} ${surname}`,
			picture: `${staticUrl}/previews/author_${id}.jpeg`,
			short_title: `${name} ${surname}`,
			long_title: `${name} ${surname} - ${caption}`
		};
	}

	useEffect(() => {
		if (renderAuthor) {
			document.title = seoData.author;
		}
	}, [author, authorId]);

	return (
		<MainLayout>
			{renderAuthor && (
				<Helmet>
					<title>{seoData.author}</title>
					<meta
						itemProp='author'
						itemType='https://schema.org/Person'
						content={seoData.author}
						small={caption}
					/>
					<meta itemProp='image' content={seoData.picture} />
					<meta name='twitter:image' content={seoData.picture} />
					<meta property='og:image' content={seoData.picture} />
					<meta property='og:image:width' content={1200} />
					<meta property='og:image:height' content={630} />
					<meta name='twitter:title' content={seoData.short_title} />
					<meta property='og:title' content={seoData.long_title} />
					<meta property='og:url' content={url} />
					<meta name='url' content={url} />
					<meta name='identifier-URL' content={url} />
					<meta property='og:long_title' content={seoData.long_title} />
					<meta name='twitter:description' content={caption} />
					<meta name='description' content={caption} />
					<meta property='og:description' content={caption} />
				</Helmet>
			)}
			<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
				{isConstructor ? (
					<SubscriptionContent
						image={promoPicture || avatarUrl}
						title={seoData.author}
						description={description || caption}
						records={records}
						isLoading={!name || isPending}
						type='author'
						slug={id}
					/>
				) : (
					<AuthorContent {...props} />
				)}
			</Suspense>
		</MainLayout>
	);
};
export default Author;
