export const LOCAL_STORAGE_KEYS = {
	THEME: 'theme',
	LANGUAGE: 'language',
	NO_ADS: 'isNoAdsProfile',
	FIREBASE_MESSAGING_TOKEN: 'FirebaseMessagingToken',
	FIREBASE_UPDATE: 'FirebaseUpdate',
	LAST_REFRESHED_TIME: 'lastRefreshedTime',
	USER_MAIL: 'userMail',
	USER: 'user',
	IS_PARTNER_MODE_ENABLED: 'isPartnerModeEnabled',
	Y_OFFSET: 'Y offset',
	DONATE_POPUP_CLOSE_COUNTER_EUROPE: 'donatePopupCloseCounter_europe',
	ANONYMOUS_ID: 'anonymousId',
	USER_SUBSCRIPTIONS: 'userSubscriptions',
	IS_USER_SUBSCRIPTIONS_VISIBLE: 'isUserSubscriptionsVisible',
	IS_PICTURE_OF_THE_DAY_VISIBLE: 'isPictureOfTheDayVisible',
	BILLING: 'billing',
	MR7_SELECTED_DISTRICTS: 'MR7_selectedDistricts',
	START_POSITION_OVERLAY_SEARCH: 'startPositionOverlaySearch',
};
