import React from 'react';
import useTranslates from 'src/hooks/useTranslates';
import cx from 'classnames';
import styles from './index.module.scss';
import Title from '../Title/Title';

const MoscowConnection = () => {
	const [address, questions, ads, distribution] = useTranslates(
		'all.footer.contacts.russia.address',
		'all.footer.contacts.questions',
		'all.footer.contacts.ads',
		'all.footer.contacts.distribution'
	);

	return (
		<div className={cx(styles.root, styles.wide)}>
			<Title title='all.footer.contacts' />
			<ul className={styles.listContatcs}>
				<li className={styles.item}>
					<div className={styles.textRoot}>
						<p className={styles.text}>{address}</p>
						<p className={cx(styles.text, styles.marginTop)}>{questions}</p>
						<a href='tel:+74959262001'>+7 (495) 926-20-01</a>
						<a href='mailto:2022@novayagazeta.ru'>2022@novayagazeta.ru</a>
						<p className={cx(styles.text, styles.marginTop)}>{ads}</p>
						<a href='tel:+74956215776'>+7 (495) 621-57-76</a>
						<a href='mailto:reklama@novayagazeta.ru'>reklama@novayagazeta.ru</a>
						<p className={cx(styles.text, styles.marginTop)}>{distribution}</p>
						<a href='tel:+74956215877'>+7 (495) 621-58-77</a>
						<a href='tel:+74956235475'>+7 (495) 623 54-75</a>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default MoscowConnection;
