import { createSlice } from '@reduxjs/toolkit';
import {
	windowTitle,
	snippet_picture_rus,
	snippet_description,
	snippet_keywords,
	snippet_picture_eng,
} from 'src/api';

const seoSlice = createSlice({
	name: 'SEO',
	initialState: {
		pathname: '/',
		short_title: windowTitle,
		long_title: windowTitle,
		description: snippet_description,
		keywords: snippet_keywords,
		picture: snippet_picture_rus,
		picture_eng: snippet_picture_eng,
	},
	reducers: {
		setSeoPathname(state, action) {
			state.pathname = action.payload;
		},
	},
});
export const { setSeoPathname } = seoSlice.actions;
export default seoSlice.reducer;
