import React, { Component } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

class Container extends Component {
	render() {
		const { className, center, block, row, ...other } = this.props;
		const classes = cx(styles.root, {
			[className]: Boolean(className),
			[styles.center]: center,
			[styles.block]: block,
			[styles.row]: row,
		});

		return <div className={classes} {...other} />;
	}
}

export default Container;
