import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
	name: 'ERROR',
	initialState: {
        error: false
    },
	reducers: {
		clearError: (state, action) => {
			state.error = false;
		},
        globalError:(state, action) => {
            state.error = action.error;
        }
	},
});

export const { clearError, globalError } = errorSlice.actions;

export default errorSlice.reducer;
