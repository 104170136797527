import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { previews } from 'src/api';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const NewsContent = lazy(() => import('src/content/News'));

const News = () => {
	const title = useChangeWindowTitle({ currentTitle: 'all.news' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
				{previews.news && <link rel='image_src' href={previews.news} />}
				{previews.news && <meta itemProp='image' content={previews.news} />}
				{previews.news && <meta name='twitter:image' content={previews.news} />}
				{previews.news && <meta property='og:image' content={previews.news} />}
				{previews.news && <meta property='og:image:width' content={1200} />}
				{previews.news && <meta property='og:image:height' content={630} />}
			</Helmet>
			<NewsContent />
		</MainLayout>
	);
};

export default News;
