import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';
import { LONGREADS_REDIRECTS_MR7_TO_TILDA } from 'src/config/redirects';

const IndexScreen = lazy(() => import('src/content/IndexPage'));
const NotFound = lazy(() => import('src/content/NotFound/NotFound'));

const Longreads = (props) => {
	const [isNotFound, setIsNotFound] = useState(false);
	const { slug } = useParams();

	useEffect(() => {
		if (LONGREADS_REDIRECTS_MR7_TO_TILDA[slug]) {
            window.location.href = LONGREADS_REDIRECTS_MR7_TO_TILDA[slug]
		} else {
			setIsNotFound(true);
		}
	}, [slug]);

	if (!isNotFound) {
		return (
			<div style={{ minHeight: '95vh' }}>
				<Preloader />
			</div>
		);
	}

	return (
		<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
			<NotFound wrongMaterialLink />
			<IndexScreen />
		</Suspense>
	);
};

export default Longreads;
