import { isMultiLang } from 'src/api';

export const filterRecordsByLanguage = (records, language) => {
	if (!isMultiLang) return records;

	return records.filter((record) =>
		language === 'ru' ? !record.lang || record.lang === 'ru' : record.lang === language
	);
};

export const filterAuthorsByLanguage = (authors, language) => {
	if (!isMultiLang) return authors;

	let res;

	if (language === 'ru') {
		res = authors;
	} else {
		res = authors
			.map((author) => ({
				...author,
				...author.locale?.find((locale) => locale.lang === language)
			}))
			.filter((author) => author.lang === language);
	}
	return res;
};
