import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchVacancies = createAsyncThunk(
	`VACANCY/fetchVacancies`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/service/page?type=vacancies`)
				.then((res) => res.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						let count = 0;
						if (data.page.body) {
							count = data.page.body.filter(
								(item) => item.type === 'text/framed'
							).length;
						}
						return { data, count };
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetch vacancies: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const vacancySlice = createSlice({
	name: 'VACANCY',
	initialState: {
		isPending: true,
		data: null,
		count: null,
		error: null,
	},
	reducers: {},
	extraReducers: {
		// fetchVacancies
		[fetchVacancies.pending]: (state, action) => {
			state.isPending = true;
			state.data = null;
			state.error = null;
		},
		[fetchVacancies.fulfilled]: (state, action) => {
			state.data = action.payload.data;
			state.count = action.payload.count;
			state.isPending = false;
			state.error = null;
		},
		[fetchVacancies.rejected]: (state, action) => {
			state.error = action.payload;
			state.data = null;
			state.isPending = false;
		},
	},
});

export default vacancySlice.reducer;
