import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { socialAuthentication, userAuthentication } from 'src/store/slices/profileSlice';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { googleAuthClientId, isConstructor, isMoscow, route } from 'src/api';
import { loadGoogleAccount } from 'src/utils/asyncScripts';
import { REDIRECTS } from 'src/config/redirects';
import {
	OAUTH_PROVIDER_VK
	//  OAUTH_PROVIDER_FACEBOOK
} from './_/Oauth';
import * as M$ from './_';
import styles from './Login.module.scss';

const Login = ({ onClose, onForgot }) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);

	const googleRef = useRef(null);

	const [state, setState] = useState({
		email: '',
		password: '',
		errors: null,
		path: ''
	});

	let history = useHistory();

	useEffect(() => {
		setState({ ...state, [path]: `${window.location.origin}/auth/callback` });
	}, []);

	useEffect(() => {
		if (profile.user) {
			onClose();
			if (!window.location.pathname.startsWith('/articles')) {
				history.push(REDIRECTS.PROFILE);
			}
		}
	}, [profile.user]);

	useEffect(() => {
		if (!isConstructor) return;

		const handleCredentialResponse = ({ credential }) => {
			// удаляем cookie g_state, так как он ломает куки строчку
			document.cookie = 'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

			dispatch(
				socialAuthentication({
					token: credential,
					type: 3
				})
			);
		};

		loadGoogleAccount(() => {
			google.accounts.id.initialize({
				client_id: googleAuthClientId,
				callback: handleCredentialResponse
			});

			google.accounts.id.renderButton(googleRef.current, {
				type: 'standart',
				theme: 'filled_blue',
				size: 'large',
				shape: 'pill',
				text: 'signin_with',
				logo_alignment: 'right',
				width: '250'
			});
		});
	}, []);

	const handleEmailChange = (e) => {
		setState({ ...state, email: e.target.value });
	};

	const handlePasswordChange = (e) => {
		setState({ ...state, password: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const body = {
			email: state.email,
			password: state.password
		};
		dispatch(userAuthentication(body));
	};

	const { email, path, password } = state;

	return (
		<div className={styles.root}>
			<Helmet>
				<title>Авторизация</title>
			</Helmet>
			<footer className={styles.socials}>
				<div className={styles.caption}>
					<hr />
					<p>Войти с помощью</p>
				</div>
				<div className={styles.list}>
					{/* <M$.Oauth
						className={cx(styles.item, styles.facebook)}
						icon='Facebook'
						provider={OAUTH_PROVIDER_FACEBOOK}
					/> */}
					{isMoscow && (
						<M$.Oauth
							className={cx(styles.item, styles.vkontakte)}
							icon='Vkontakte'
							provider={OAUTH_PROVIDER_VK}
						/>
					)}
					{isConstructor && <div className={styles.googleAuth} ref={googleRef} />}
				</div>
			</footer>
			<form action='/' method='POST' className={styles.root} onSubmit={handleSubmit}>
				<label>Email</label>
				<input
					type='email'
					name='email'
					value={email}
					className={styles.input}
					onChange={handleEmailChange}
					required
				/>

				<label>Введите пароль</label>
				<input
					type='password'
					name='password'
					value={password}
					className={styles.input}
					onChange={handlePasswordChange}
					required
				/>

				<footer className={styles.footer}>
					<p>
						Авторизуясь, я принимаю{' '}
						<a href={`https://${route}/terms`} target='_blank' rel='noreferrer'>
							условия пользовательского соглашения
						</a>{' '}
						и{' '}
						<a href={`https://${route}/privacy`} target='_blank' rel='noreferrer'>
							политику конфиденциальности
						</a>{' '}
					</p>
					<button
						type='submit'
						className={cx(styles.submit, {
							[styles.pending]: profile.isPending
						})}
					>
						<span>Войти</span>
						<Loader type='TailSpin' color='#8b8c8c' height='30' width='100' />
					</button>

					<button onClick={onForgot} type='button' className={styles.forgot}>
						Забыли пароль?
					</button>
				</footer>
			</form>
		</div>
	);
};

export default Login;
