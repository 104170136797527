export const USERS = {
	AUTH: '/auth',
	BOOKMARKS: '/profile/bookmarks',
	CALLBACK: '/auth/callback',
	EMAIL_CONFIRMATIONS: '/confirm/email',
	PARTNER: '/profile/partner',
	PASSWORD_RESET: '/reset/password',
	PROFILE_MAIN: '/profile',
	SETTINGS: '/profile/settings',
	SUBSCRIPTIONS: '/profile/subscriptions'
};
