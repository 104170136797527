import React, { lazy } from 'react';
import MainLayout from 'src/layouts/MainLayout';

const ArticleContent = lazy(() => import('src/content/Article'));

const Photos = (props) => (
  <MainLayout>
    <ArticleContent {...props} />
  </MainLayout>
);

export default Photos;
