import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const ProfileContent = lazy(() => import('src/content/Profile/Profile'));

const Profile = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.profile' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<ProfileContent {...props} />
		</MainLayout>
	);
};

export default Profile;
