import React, { lazy, useEffect, useState } from 'react';
import { isMR7, root } from 'src/api';
import MainLayout from 'src/layouts/MainLayout';
import Container from 'src/components/Base/Grid/Container';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';

const PictureOfTheDay = lazy(() => import('src/components/Base/PictureOfTheDay'));

const CategoryProjects = () => {
	const title = useChangeWindowTitle({ currentTitle: 'Спецпроекты' });
	const [materials, setMaterials] = useState([]);

	useEffect(() => {
		const getMaterials = async () => {
			const type = 'projects';
			const result = await fetch(`${root}/get/main?c=${type}`).then((response) => response.json());
			const { records } = result;
			const materials = [];
			if (records && !!records.length) {
				records.forEach((record) => {
					materials.push({ type: '1/1', blocks: [record] });
				});
			}

			!!materials.length && setMaterials(materials);
		};

		getMaterials();
	}, []);

	const containerStyle = {
		minHeight: '100vh'
	};

	if (isMR7) {
		containerStyle.maxWidth = '1194px';
	}

	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Container style={containerStyle}>
				{materials && !!materials.length && <PictureOfTheDay records={materials} />}
				{materials.length === 0 && <Preloader />}
			</Container>
		</MainLayout>
	);
};

export default CategoryProjects;
