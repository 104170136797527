import React from 'react';
import SearchSubscriptionRedirect from 'src/content/Constructor/SearchSubscriptionRedirect';
import MainLayout from 'src/layouts/MainLayout';

const SearchSubscription = (props) => (
	<MainLayout>
		<SearchSubscriptionRedirect {...props} />
	</MainLayout>
);

export default SearchSubscription;
