import React from 'react';
import 'src/content/NotFound/NotFound.module.scss';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null, reloaded: false };
	}

	componentDidCatch(error, errorInfo) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (error.message && chunkFailedMessage.test(error.message) && !this.state.reloaded) {
			this.setState({
				...this.state,
				reloaded: true
			});
			console.log(error, errorInfo);
			window.location.reload();
			return;
		}
		// Catch errors in any components below and re-render with error message
		this.setState({
			...this.state,
			error,
			errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<div className='error-page-root' style={{ width: '100%', height: this.props.isTypes ? '400px' : '100vh' }}>
					<h2>Что-то пошло не так.</h2>

					{this.state.error && this.state.error.toString()}
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;
