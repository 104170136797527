import useTranslates from 'src/hooks/useTranslates';
import React from 'react';
import styles from './Title.module.scss';

const Title = ({ title }) => {
  const [formattedTitle] = useTranslates(title);

  return <h2 className={styles.root}>{formattedTitle}</h2>
}

export default Title;
