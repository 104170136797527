import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

const useUrlParams = () => {
	const { search } = useLocation();
	const [params, setParams] = useState({
		isPrint: false,
		isExport: false
	});

	useEffect(() => {
		if (!search) return;

		const newParams = {
			isPrint: search === '?print=true',
			isExport: search === '?export=1'
		};

		const paramsKeys = Object.keys(params);
		const isChanged = paramsKeys.some((key) => params[key] !== newParams[key]);

		if (isChanged) {
			setParams(newParams);
		}
	}, [search]);

	return params;
};
export default useUrlParams;
