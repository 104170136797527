import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { route, windowTitle } from 'src/api';
import { urlTypes, urlDifTypes } from 'src/config/static';
import useTranslates from 'src/hooks/useTranslates';
import useUrlParams from 'src/hooks/useUrlParams';
import { textWithoutTags } from 'src/utils/string';

const Onthe = () => {
	const history = useHistory();
	const location = history.location.pathname;
	const { language } = useSelector((state) => state.global);
	const { article } = useSelector((state) => state.article);
	const [title] = useTranslates(windowTitle);
	const isMainPage = location === '/';
	const isAllowedSectionsPage = urlTypes.includes(location);
	const isAdditionalPages = urlDifTypes.some((page) => location.includes(page));
	const { isExport, isPrint } = useUrlParams();

	const generateOntheScript = (pageType) => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.id = 'onthe_io_config';

		let url = `https://${route}${location}`;

		if (!isMainPage) {
			url = `${url}/`;
		}

		s.innerHTML = `
                    window._io_config = window._io_config || {};
                    window._io_config["0.2.0"] = window._io_config["0.2.0"] || [];
                    window._io_config["0.2.0"].push({
                    page_url: "${url}",
                    page_url_canonical: "${url}",
                    page_title: "${title}",
                    page_type: "${pageType}",
                    page_language: "${language}",
                    });
                `;
		document.body.appendChild(s);
	};

	const generateArticleOntheScript = () => {
		if (isExport || isPrint) return;

		const {
			body,
			tags,
			authors,
			typeDisplayName,
			typeGenreDisplayName,
			typeRubricDisplayName,
			date,
			title,
			lang
		} = article;

		const language = lang || 'ru';
		const isEnglish = lang && lang === 'en';
		const arrWord = [];
		const newTitle = title && textWithoutTags(title);

		const wordsSum = (body) => {
			body.forEach((el) => {
				arrWord.push(el.data);
			});
			return arrWord.join(' ');
		};

		const bodyString = typeof body === 'object' ? wordsSum(body) : null;

		const tagToString = (tags) => {
			const str = [];
			tags.forEach((el) => {
				str.push(`"${el}"`);
			});
			return str;
		};

		const tag = tags ? tagToString(tags).join(', ') : null;

		const authorsName = [];

		if (authors) {
			authors.map((e) => authorsName.push(e.name));
		}

		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.id = 'onthe_io_config';

		const url = `https://${route}${location}/`;

		s.innerHTML = `
					window._io_config = window._io_config || {};
					window._io_config["0.2.0"] = window._io_config["0.2.0"] || [];
					window._io_config["0.2.0"].push({
					page_url: "${url}",
					page_url_canonical: "${url}",
					page_title: "${newTitle.replace(/"/g, '')}",
					page_type: "article",
					page_language: "${language}",
					tag: [${tag || null}],
					article_authors: ${authors.length > 0 ? JSON.stringify(authorsName) : "['РЕДАКЦИЯ']"},
					article_categories: ["${typeDisplayName || null}"],
					article_subcategories: ${
						isEnglish
							? '["English"]'
							: `["${typeGenreDisplayName || ''}", "${typeRubricDisplayName || ''}"]`
					},
					article_type: "longread",
					article_word_count: "${bodyString ? bodyString.split(' ').length : null}",
					article_publication_date: "${new Date(date).toUTCString()}"
					});
					`;

		document.body.appendChild(s);
	};

	useEffect(() => {
		const existingScript = document.getElementById('onthe_io_config');
		existingScript && document.body.removeChild(existingScript);

		const articlePath = '/articles/';
		const isArticlePage = location.startsWith(articlePath);
		const articleSlug = isArticlePage && location.replace(articlePath, '');

		if (isMainPage) {
			generateOntheScript('main');
		} else if (isAllowedSectionsPage || isAdditionalPages) {
			generateOntheScript('default');
		} else if (isArticlePage && article && article.slug === articleSlug) {
			generateArticleOntheScript();
		}
	}, [location, article, language]);

	return null;
};
export default Onthe;
