import React from 'react';
import { isBaltia, constructor, isEurope, isMR7 } from 'src/api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import cx from 'classnames';
import styles from './Preloader.module.scss';

const Preloader = ({ className, small, lightColor, ...other }) => {
	const isCircularProgress = isEurope || constructor || isMR7;
	return (
		<div
			className={cx({
				'baltia-preloader': isBaltia,
				'standart-preloader': isCircularProgress,
				'loader-light-color': lightColor
			})}
		>
			{isCircularProgress ? (
				<div className={cx(styles.root, className, { [styles.small]: small })} {...other}>
					<Box sx={{ display: 'flex' }}>
						<CircularProgress classes={styles.loader_europe} />
					</Box>
				</div>
			) : (
				<div className={cx(styles.root, className, { [styles.small]: small })} {...other}>
					<div className={styles.left} />
					<div className={styles.center} />
					<div className={styles.right} />
				</div>
			)}
		</div>
	);
};

Preloader.defaultProps = {
	small: false
};

export default Preloader;
