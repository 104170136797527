import React, { lazy } from 'react';
import MainLayout from 'src/layouts/MainLayout';

const Preview = lazy(() => import('src/content/Tech/Preview'));

const ArticlePreview = (props) => (
  <MainLayout>
    <Preview {...props} />
  </MainLayout>
);

export default ArticlePreview;
