import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MAIN, OLD } from 'config/main';
import { CATEGORY } from 'config/categories';
import { SERVICE } from 'config/services';
import { USERS } from 'config/users';
import { showDonate } from './api';
import Index from './pages/Index';
import News from './pages/News';
import Tag from './pages/Tag';
import Article from './pages/Article';
import SingleNews from './pages/SingleNews';
import Politics from './pages/Politics';
import Inquests from './pages/Inquests';
import Columns from './pages/Columns';
import Economy from './pages/Economy';
import Comments from './pages/Comments';
import Society from './pages/Society';
import Arts from './pages/Arts';
import Sports from './pages/Sports';
import Photos from './pages/Photos';
import Authors from './pages/Authors';
import Author from './pages/Author';
import CoronaVirus from './pages/CoronaVirus';
import CategoryProjects from './pages/CategoryProjects';
import CategoryData from './pages/CategoryData';
import CategoryVideo from './pages/CategoryVideo';
import CategoryFeatures from './pages/CategoryFeatures';
import CategoryPartners from './pages/CategoryPartners';
import CategoryPetitions from './pages/CategoryPetitions';
import CategoryInquiries from './pages/CategoryInquiries';
import CategoryPodcast from './pages/CategoryPodcast';
import CategoryNewsletter from './pages/CategoryNewsletter';
import CategorySpb from './pages/CategorySpb';
import CategoryStories from './pages/CategoryStories';
import CategoryViews from './pages/CategoryViews';
import CategoryPolitics from './pages/CategoryPolitics';
import CategoryEconomy from './pages/CategoryEconomy';
import CategorySociety from './pages/CategorySociety';
import CategoryCulture from './pages/CategoryCulture';
import Donate from './pages/Donate';
import Theme from './pages/Theme';
import ArticlePreview from './pages/ArticlePreview';
import Issues from './pages/Issues';
import Issue from './pages/Issue';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Regulation from './pages/Regulation';
import Advertising from './pages/Advertising';
import AgeLimit from './pages/AgeLimit';
import SubscriptionsDigital from './pages/SubscriptionsDigital';
import SubscriptionsPrinted from './pages/SubscriptionsPrinted';
import SubscriptionsEmail from './pages/SubscriptionsEmail';
import Vacancies from './pages/Vacancies';
import Auth from './pages/Auth';
import Confirmation from './pages/Confirmation';
import PasswordReset from './pages/PasswordReset';
import SearchSubscription from './pages/SearchSubscription';
import Profile from './pages/Profile';
import Page404 from './pages/404';
import NOPromo from './pages/NOPromo';
import RestrictedRoute from './components/RestrictedRoute';
import Longreads from './pages/Longreads';

const BALTIA = 'novayagazeta.ee';
const EUROPE = 'novayagazeta.eu';
const MOSCOW = 'novayagazeta.ru';
const MEDIA = 'novaya.media';
const NO = 'novaya.no';
const DEV = 'novayagazeta.dev';
const NEWMR7 = 'new.mr-7.ru';
const MR7 = 'mr-7.ru';

const Routes = () => (
	<Switch>
		<Route path={MAIN.INDEX} exact component={Index} />
		<RestrictedRoute
			availableSites={[DEV, BALTIA, EUROPE, MOSCOW, MR7, NEWMR7]}
			path={MAIN.NEWS}
			exact
			component={News}
		/>
		<Route path={[MAIN.ARTICLE, MAIN.ARTICLE_TEST_RESULT]} exact component={Article} />
		<RestrictedRoute
			availableSites={[DEV, BALTIA, MR7, NEWMR7]}
			path={MAIN.TAG}
			exact
			component={Tag}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={OLD.ARTICLE}
			exact
			component={Article}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={MAIN.SINGLE_NEWS}
			exact
			component={SingleNews}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={OLD.POLITICS}
			exact
			component={Politics}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={OLD.INQUESTS}
			exact
			component={Inquests}
		/>
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.COLUMNS} exact component={Columns} />
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.ECONOMY} exact component={Economy} />
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={OLD.COMMENTS}
			exact
			component={Comments}
		/>
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.SOCIETY} exact component={Society} />
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.ARTS} exact component={Arts} />
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.SPORTS} exact component={Sports} />
		<RestrictedRoute availableSites={[DEV, MOSCOW]} path={OLD.PHOTOS} exact component={Photos} />
		<RestrictedRoute
			availableSites={[DEV, BALTIA, EUROPE, MOSCOW, MEDIA, NO]}
			path={MAIN.AUTHORS}
			exact
			component={Authors}
		/>
		<Route path={MAIN.AUTHOR} exact component={Author} />
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={MAIN.VIRUS}
			exact
			component={CoronaVirus}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={CATEGORY.PROJECTS}
			exact
			component={CategoryProjects}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={CATEGORY.PARTNERS}
			exact
			component={CategoryPartners}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, EUROPE]}
			path={CATEGORY.DATA}
			exact
			component={CategoryData}
		/>
		<RestrictedRoute
			availableSites={[DEV, EUROPE]}
			path={CATEGORY.VIDEO}
			exact
			component={CategoryVideo}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={CATEGORY.FEATURES}
			exact
			component={CategoryFeatures}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={CATEGORY.PETITIONS}
			exact
			component={CategoryPetitions}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, EUROPE]}
			path={CATEGORY.INQUIRIES}
			exact
			component={CategoryInquiries}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={CATEGORY.PODCAST}
			exact
			component={CategoryPodcast}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={CATEGORY.NEWSLETTER}
			exact
			component={CategoryNewsletter}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={CATEGORY.SPB}
			exact
			component={CategorySpb}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, BALTIA, EUROPE, MR7, NEWMR7]}
			path={CATEGORY.STORIES}
			exact
			component={CategoryStories}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, BALTIA, EUROPE]}
			path={CATEGORY.VIEWS}
			exact
			component={CategoryViews}
		/>
		<RestrictedRoute
			availableSites={[DEV, BALTIA]}
			path={CATEGORY.POLITICS}
			exact
			component={CategoryPolitics}
		/>
		<RestrictedRoute
			availableSites={[DEV, BALTIA]}
			path={CATEGORY.ECONOMY}
			exact
			component={CategoryEconomy}
		/>
		<RestrictedRoute
			availableSites={[DEV, BALTIA]}
			path={CATEGORY.SOCIETY}
			exact
			component={CategorySociety}
		/>
		<RestrictedRoute
			availableSites={[DEV, BALTIA]}
			path={CATEGORY.CULTURE}
			exact
			component={CategoryCulture}
		/>
		{showDonate && (
			<RestrictedRoute
				availableSites={[DEV, MOSCOW, MEDIA, EUROPE]}
				path={[MAIN.DONATE, MAIN.DONATE_MONTHLY]}
				exact
				component={Donate}
			/>
		)}
		<Route path={MAIN.THEME} exact component={Theme} />
		<RestrictedRoute availableSites={[DEV, NO]} path={MAIN.PROMO} exact component={NOPromo} />
		<RestrictedRoute
			availableSites={[DEV, MEDIA]}
			path={MAIN.PROMO_MEDIA}
			exact
			component={NOPromo}
		/>
		<Route path={SERVICE.ARTICLE_PREVIEW} exact component={ArticlePreview} />
		<RestrictedRoute
			availableSites={[DEV, MEDIA, NO, MOSCOW, EUROPE, BALTIA]}
			path={MAIN.ISSUES}
			exact
			component={Issues}
		/>
		<RestrictedRoute
			availableSites={[DEV, MEDIA, NO, MOSCOW, EUROPE, BALTIA]}
			path={MAIN.ISSUE}
			exact
			component={Issue}
		/>
		<RestrictedRoute
			availableSites={[DEV, MEDIA]}
			path={MAIN.SEARCH_SUBSCRIPTION}
			exact
			component={SearchSubscription}
		/>
		<Route path={SERVICE.TERMS} exact component={Terms} />
		<Route path={SERVICE.PRIVACY} exact component={Privacy} />
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MR7, NEWMR7]}
			path={SERVICE.REGULATION}
			exact
			component={Regulation}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={SERVICE.ADVERTISING}
			exact
			component={Advertising}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={SERVICE.AGE_LIMIT}
			exact
			component={AgeLimit}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={SERVICE.SUBSCRIPTIONS_DIGITAL}
			exact
			component={SubscriptionsDigital}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW]}
			path={SERVICE.SUBSCRIPTIONS_PRINTED}
			exact
			component={SubscriptionsPrinted}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, EUROPE]}
			path={SERVICE.SUBSCRIPTIONS_EMAIL}
			exact
			component={SubscriptionsEmail}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MR7, NEWMR7]}
			path={SERVICE.VACANCIES}
			exact
			component={Vacancies}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={[USERS.AUTH, USERS.CALLBACK]}
			exact
			component={Auth}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={USERS.EMAIL_CONFIRMATIONS}
			exact
			component={Confirmation}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={USERS.PASSWORD_RESET}
			exact
			component={PasswordReset}
		/>
		<RestrictedRoute
			availableSites={[DEV, MOSCOW, MEDIA]}
			path={[
				USERS.BOOKMARKS,
				USERS.PROFILE_MAIN,
				USERS.PARTNER,
				USERS.SETTINGS,
				USERS.SUBSCRIPTIONS
			]}
			exact
			component={Profile}
		/>
		<RestrictedRoute
			availableSites={[DEV, MR7, NEWMR7]}
			path={MAIN.LONGREADS}
			exact
			component={Longreads}
		/>
		<Redirect from='/inquests' to='/inquiries' />
		<Redirect from='/subscription' to='/subscription/printed' />
		<Route component={Page404} />
	</Switch>
);

export default Routes;
