import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchTheme = createAsyncThunk(`THEME/fetchTheme`, async (id, { rejectWithValue }) => {
	try {
		const response = await fetch(`${root}/get/theme?slug=${id}`)
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
					throw data.error;
				} else {
					return data;
				}
			});
		return response;
	} catch (err) {
		if (dev) console.log(`Error fetch article: ${err}`);
		return rejectWithValue(err);
	}
});

const themeSlice = createSlice({
	name: 'THEME',
	initialState: {
		isPending: false,
		data: null,
		error: null
	},
	reducers: {},
	extraReducers: {
		// fetchTheme
		[fetchTheme.pending]: (state, action) => {
			state.isPending = true;
			state.data = null;
			state.error = null;
		},
		[fetchTheme.fulfilled]: (state, { payload }) => {
			const { theme } = payload;

			if (theme) {
				state.data = payload;
				state.error = null;
			} else {
				state.error = true;
				state.data = null;
			}
			state.isPending = false;
		},
		[fetchTheme.rejected]: (state, { payload }) => {
			state.error = payload;
			state.data = null;
			state.isPending = false;
		}
	}
});

export default themeSlice.reducer;
