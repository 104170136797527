import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { transcription } from 'src/config/static';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const Genre = lazy(() => import('src/content/Genre/Genre'));

const CategoryEconomy = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.rubric.jekonomika' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Genre
				type={transcription[props.location.pathname.substring(1)]}
				pathname={props.location.pathname}
			/>
		</MainLayout>
	);
};

export default CategoryEconomy;
