import { NUMBER_REGEXP } from './regexp';

const subscriptionType = {
	author: 'author-no:',
	theme: 'theme-no:',
	web: ''
};

export const generateSubscriptionString = (type, slug) => `${subscriptionType[type]}${slug}`;

export const getSubscriptionType = (str) =>
	Object.entries(subscriptionType).find(([key, value]) => str.startsWith(value))[0];

export const getSubscriptionTypes = (arr) => ({
	authors: arr.filter((str) => str.startsWith(subscriptionType.author)),
	themes: arr.filter((str) => str.startsWith(subscriptionType.theme)),
	web: arr.filter(
		(str) => !str.startsWith(subscriptionType.author) && !str.startsWith(subscriptionType.theme)
	)
});

export const getLocalStorageParsedValue = (key) => {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (e) {
		localStorage.removeItem(key);

		return null;
	}
};

export const changeWebsiteTitle = (title) => {
	let newTitle = title;
	if (!title.length) {
		return title;
	}
	title = title.replace(': ', ' - ');
	const dashs = [' - ', ' – ', ' ‒ ', ' — ', ' ― '];

	title = title[0].toUpperCase() + title.slice(1);
	let index;
	dashs.some((dash, i) => {
		const isContains = title.includes(dash);
		if (isContains) {
			index = i;
		}
		return isContains;
	});

	if (index !== undefined) {
		newTitle = title.split(dashs[index])[0].trim();
	}

	return newTitle;
};

export const splitInTwoLines = (text) => {
	if (!text.length) {
		return text;
	}
	let newText = text;
	let wordCount = newText.split(' ').length;

	if (wordCount === 2) {
		newText = newText.replace(' ', ' </br> ');
	}

	return newText;
};
