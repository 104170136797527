import React, { lazy, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import cx from 'classnames';
import { getCookie, removeCookie } from 'src/utils/cookies';
import { fetchBilling, fetchProfile, setLastRefreshedTime } from 'src/store/slices/profileSlice';
import * as api from 'src/api';
import Typo from 'src/components/Modules/Typo/Typo';
import Toast from 'src/components/Units/Toast';
import { withRouter, useHistory, useParams } from 'react-router';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import 'react-toastify/scss/main.scss';
import './styles/index.scss';
import styles from './styles/index.module.scss';
import 'src/components/sharedStylesBetweenRoutes';
import GlobalManager from './components/GlobalManager';
import Onthe from './components/Onthe';
import MainMetaTags from './components/MainMetaTags';
import translations from './translations.json';
import Routes from './routes';
import './components/Base/Grid/Container/index.module.scss';
import { SPECIAL_CHARACTERS_REGEXP } from './utils/regexp';

// const DonateInformer = lazy(() => import('./components/Modules/DonateInformer'));
const DonateInformerEurope = lazy(() => import('./components/Modules/DonateInformerEurope'));
const CookiesInformer = lazy(() => import('./components/Modules/CookiesInformer/CookiesInformer'));
const SubscriptionInformer = lazy(() =>
	import('./components/Modules/SubscriptionInformer/SubscriptionInformer')
);
const Fonts = lazy(() => import('./components/Fonts/Fonts'));
const FontsPTSerif = lazy(() => import('./components/Fonts/FontsPTSerif'));
const RobotoFlex = lazy(() => import('./components/Fonts/RobotoFlex'));
const FontsPTSans = lazy(() => import('./components/Fonts/FontsPTSans'));

const App = () => {
	const history = useHistory();
	const pathname = history.location.pathname;
	const [shouldLoadMaterialFonts, setShouldLoadMaterialFonts] = useState(pathname !== '/');
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.profile);
	const { email } = useSelector((state) => state.profile);
	const { lastRefreshedTime } = useSelector((state) => state.profile);
	const language = useSelector((state) => state.global.language);
	const token = getCookie('token');
	const messages = translations[language];
	const isMainPage = pathname === '/';

	useLayoutEffect(() => {
		// проверка на спецсимволы в конце пути
		const path = pathname;
		const lastCharacter = path[path.length - 1];
		const articlePath = '/articles/';
		if (SPECIAL_CHARACTERS_REGEXP.test(lastCharacter)) {
			// не удалять спецсимвол в конце ссылки у старых материалов МР7 (пример ссылки: https://mr-7.ru/articles/249359/ ) 
			const MR7_oldArticleCode = api.isMR7 && path.includes(articlePath) && +path.split(articlePath)[1].replace('/', '');
			!MR7_oldArticleCode && history.push(path.slice(0, path.length - 1));
		}
	}, [pathname]);

	useEffect(() => {
		if (!isMainPage || (isMainPage && api.constructor)) setShouldLoadMaterialFonts(true);
	}, [pathname]);

	useEffect(() => {
		if (user) localStorage.setItem(LOCAL_STORAGE_KEYS.NO_ADS, user.isNoAdsProfile || false);
	}, [user]);

	useEffect(() => {
		const getTimeDiff = (currenttime) => {
			const lastUpdateTime = lastRefreshedTime || 0;
			return parseInt(currenttime) - parseInt(lastUpdateTime);
		};
		const currenttime = +new Date();
		const minute = 60 * 1000;

		const updateRequest = getTimeDiff(currenttime) > minute;

		if (updateRequest) {
			if (localStorage.getItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID)) {
				dispatch(fetchProfile());
				dispatch(setLastRefreshedTime(currenttime));
			}

			if (email && token) {
				dispatch(fetchProfile());
				dispatch(fetchBilling(email));
				dispatch(setLastRefreshedTime(currenttime));
			} else {
				removeCookie('token', '', { 'max-age': -1 });
			}
		}
	}, [pathname, email]);

	return (
		<IntlProvider onError={(e) => {}} locale='en' messages={messages}>
			<GlobalManager />
			{api.onthe_key && <Onthe />}
			{api.dev && <RobotoFlex />}
			{api.dev && <FontsPTSans />}
			<main
				className={cx(styles.root, {
					[styles.novaya_europe_theme]: api.isEurope,
					[styles.novaya_constructor_theme]: api.constructor,
					[styles.mr7_theme]: api.isMR7
				})}
				id='mainNode'
			>
				<MainMetaTags />
				{shouldLoadMaterialFonts ? api.isMR7 ? <FontsPTSerif /> : <Fonts /> : null}
				<Toast />
				<Routes />
				<div id='adfox_inpage_video' />
				{/* {api.showDonate && <DonateInformer />} */}
				{(api.isMoscow || api.isEurope) && <SubscriptionInformer />}
				{api.isEurope && <DonateInformerEurope />}
				{api.showCookiesPopup && <CookiesInformer />}
				<Typo />
			</main>
		</IntlProvider>
	);
};

const AppRouter = withRouter(App);

export default AppRouter;
