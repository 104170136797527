import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const IndexScreen = lazy(() => import('src/content/IndexPage'));
const NotFound = lazy(() => import('src/content/NotFound/NotFound'));

const Page404 = () => {
	const title = useChangeWindowTitle({ currentTitle: '404' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<NotFound wrongMaterialLink />
			<IndexScreen />
		</MainLayout>
	);
};

export default Page404;
