import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { root } from 'src/api';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { getLocalStorageParsedValue } from 'src/utils';

export const fetchThemes = createAsyncThunk(
	`CONSTRUCTOR/fetchThemes`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/themes`)
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						notify(data.error);
						throw data.error;
					} else {
						return data;
					}
				});

			return response;
		} catch (err) {
			if (dev) console.log(`fetchThemes was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const isPictureOfTheDayVisible = getLocalStorageParsedValue(
	LOCAL_STORAGE_KEYS.IS_PICTURE_OF_THE_DAY_VISIBLE
);

const construcorSlice = createSlice({
	name: 'CONSTRUCTOR',
	initialState: {
		isPictureOfTheDayVisible: isPictureOfTheDayVisible === null ? true : isPictureOfTheDayVisible,
		isUserSubscriptionsVisible:
			getLocalStorageParsedValue(LOCAL_STORAGE_KEYS.IS_USER_SUBSCRIPTIONS_VISIBLE) || false,
		isSubscriptionOverlaySearchVisible: false,
		userBreakPoint: null,
		themes: [],
		preloadedSearch: '',
		isBlueBorderOnUserCardInLeftMenu: false
	},
	reducers: {
		setIsPictureOfTheDayVisibleVisible: (state, { payload }) => {
			state.isPictureOfTheDayVisible = payload;
		},
		setIsUserSubscriptionsVisible: (state, { payload }) => {
			state.isUserSubscriptionsVisible = payload;
		},
		setIsSubscriptionOverlaySearchVisible: (state, { payload }) => {
			state.isSubscriptionOverlaySearchVisible = payload;
		},
		setUserBreakPoint: (state, { payload }) => {
			state.userBreakPoint = payload;
		},
		setPreloadedSearch: (state, { payload }) => {
			state.preloadedSearch = payload;
		},
		setBlueBorderOnUserCardInLeftMenu: (state, { payload }) => {
			state.isBlueBorderOnUserCardInLeftMenu = payload;
		}
	},
	extraReducers: {
		[fetchThemes.fulfilled]: (state, { payload }) => {
			state.themes = payload.themes;
		}
	}
});

export const {
	setIsPictureOfTheDayVisibleVisible,
	setIsUserSubscriptionsVisible,
	setIsSubscriptionOverlaySearchVisible,
	setUserBreakPoint,
	setPreloadedSearch,
	setBlueBorderOnUserCardInLeftMenu
} = construcorSlice.actions;

export default construcorSlice.reducer;
