import React, { lazy, useLayoutEffect } from 'react';

// const NOPromoContent = lazy(() => import('src/content/NOPromo'));

// const NOPromo = (props) => <NOPromoContent {...props} />;

const NOPromo = (props) => {
	useLayoutEffect(() => {
		window.location.href = 'https://novayagazeta.info/promo';
	}, []);

	return null;
};

export default NOPromo;
