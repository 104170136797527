export const removeCookie = (name, value, newOptions = {}) => {

    let options = {
      path: '/',
      ...newOptions
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  
    for (let optionKey in options) {
      updatedCookie += `;${optionKey}`;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    }
    document.cookie = updatedCookie;
  }

export const getCookie = (name) => {
	const dc = document.cookie;
	const prefix = `${name}=`;
	let begin = dc.indexOf(`; ${prefix}`);
	let end = 0;
	if (begin === -1) {
		begin = dc.indexOf(prefix);
		if (begin !== 0) return null;
	} else {
		begin += 2;
		end = document.cookie.indexOf(';', begin);
		if (end === -1) {
			end = dc.length;
		}
	}
	return decodeURI(dc.substring(begin + prefix.length, end));
};
