/* eslint-disable */
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { googleTrackingId, isMR7, yandexTrackingId } from 'src/api';

const useAnalytics = () => {
	const location = useLocation();

	useEffect(() => {
		window.ym(yandexTrackingId, 'hit', location.pathname);

		window.gtag('config', googleTrackingId, {
			page_path: location.pathname
		});

		if (isMR7) {
			new Image().src =
				'//counter.yadro.ru/hit;mr7group?r' +
				escape(document.referrer) +
				(typeof screen == 'undefined'
					? ''
					: ';s' +
					  screen.width +
					  '*' +
					  screen.height +
					  '*' +
					  (screen.colorDepth ? screen.colorDepth : screen.pixelDepth)) +
				';u' +
				escape(document.URL) +
				';h' +
				escape(document.title.substring(0, 150)) +
				';' +
				Math.random();
		}
	}, [location.pathname]);
};

export default useAnalytics;
