import { useEffect } from 'react';

const useAppHeight = () => {
	useEffect(() => {
		const handleResize = () => {
			document.documentElement.style.setProperty('--appHeight', `${window.innerHeight}px`);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);
};

export default useAppHeight;
