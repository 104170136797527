import React, { lazy } from 'react';
import MainLayout from 'src/layouts/MainLayout';

const AuthContent = lazy(() => import('src/content/Loader/Loader'));

const Auth = (props) => (
  <MainLayout>
    <AuthContent {...props} />
  </MainLayout>
);

export default Auth;
