import React, { Suspense, lazy, useEffect } from 'react';
import useUrlParams from 'src/hooks/useUrlParams';
import Error from 'src/components/Error';
import {
	isBaltia,
	isConstructor,
	isEurope,
	isLandingConstructor,
	isMedia,
	isMoscow,
	isMR7Component,
	isNO,
	isPictureOfDayComponent
} from 'src/api';
import cx from 'classnames';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearch, removeTagValue, setSearch } from 'src/store/slices/searchSlice';
import { isEmpty } from 'lodash';
import AdfoxAdv from 'src/components/Modules/AdsBanner/AdfoxAdv/AdfoxAdv';
import { closeLogin } from 'src/store/slices/profileSlice';
import Footer from './Footer';
import LandingConstructorFooter from './LandingConstructorFooter';
import ConstructorFooter from './ConstructorFooter';
import Registered from './Registered';
import LoginModal from './LoginModal';
import styles from './index.module.scss';

const OverlaySearch = lazy(() => import('./OverlaySearch'));
const PasswordReset = lazy(() => import('src/content/Profile/PasswordReset/PasswordReset'));
const SubscriptionOverlaySearch = lazy(() => import('./SubscriptionOverlaySearch'));
const DonateOverlayForm = lazy(() => import('./DonateOverlayForm'));
const Menu = lazy(() => import('./Menu'));
const MenuEurope = lazy(() => import('./MenuEurope'));
const Mr7Menu = lazy(() => import('./Mr7Menu'));
const Mr7Footer = lazy(() => import('./Mr7Footer'));
const ConstructorMenu = lazy(() => import('./ConstructorMenu'));
const LandingConstructorMenu = lazy(() => import('./LandingConstructorMenu'));
const СhronologyMenuEurope = lazy(() => import('./СhronologyMenuEurope'));
const HaulingGroupMR7 = lazy(() =>
	import('src/components/Base/PictureOfTheDay/HaulingGroup/HaulingGroupMR7')
);

const MainLayout = ({ children, ...rest }) => {
	const dispatch = useDispatch();
	const { isPrint } = useUrlParams();
	const isOpenSearch = useSelector((state) => state.search.isOpen);
	const haulingGroupState = useSelector((state) => state.main.haulingGroup);
	const isRenderHaulingGroup = !isEmpty(haulingGroupState);
	const isOpenLogin = useSelector((state) => state.profile.isOpen);
	const isSubscriptionOverlaySearchVisible = useSelector(
		(state) => state.constructorState.isSubscriptionOverlaySearchVisible
	);
	const isDonateOverlayFormVisible = useSelector(
		(state) => state.global.isDonateOverlayFormVisible
	);
	const isOpenPasswordReset = useSelector((state) => state.profile.passwordResetToken);

	useEffect(() => {
		if (!isOpenSearch) localStorage.removeItem(LOCAL_STORAGE_KEYS.START_POSITION_OVERLAY_SEARCH);
	}, [isOpenSearch]);

	return (
		<>
			{isMR7Component && !isPrint && (
				<AdfoxAdv container='adfox_fullscreen' p1='cqjty' p2='hhum' withoutStyles />
			)}
			{isOpenSearch && (
				<Suspense fallback={<div />}>
					<OverlaySearch
						onClose={() => {
							dispatch(removeTagValue());
							dispatch(setSearch('all'));
							dispatch(closeSearch());
						}}
					/>
				</Suspense>
			)}
			{isOpenPasswordReset && (
				<Suspense fallback={<div />}>
					<PasswordReset />
				</Suspense>
			)}
			{isSubscriptionOverlaySearchVisible && (
				<Suspense fallback={<div />}>
					<SubscriptionOverlaySearch />
				</Suspense>
			)}
			{isDonateOverlayFormVisible && (
				<Suspense fallback={<div />}>
					<DonateOverlayForm />
				</Suspense>
			)}
			{isOpenLogin && <LoginModal onClose={() => dispatch(closeLogin())} />}
			{isMedia && <ConstructorMenu {...rest} />}
			{isNO && <LandingConstructorMenu {...rest} />}
			{(isMoscow || isBaltia) && <Menu />}
			{isEurope && <MenuEurope />}
			{isEurope && <СhronologyMenuEurope />}
			{isMR7Component && <Mr7Menu />}
			{isMR7Component && isRenderHaulingGroup && !isPrint && (
				<HaulingGroupMR7 materials={haulingGroupState.blocks} isFisrtLine />
			)}
			<div className={cx(styles.container, { [styles.print]: isPrint })} id='mainContainer'>
				<Error>
					<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>{children}</Suspense>
				</Error>
				<>
					<Error>
						{isPictureOfDayComponent && <Footer />}
						{isLandingConstructor && <LandingConstructorFooter />}
						{isConstructor && <ConstructorFooter />}
						{isMR7Component && <Mr7Footer />}
					</Error>
					{isPictureOfDayComponent && <Registered />}
				</>
			</div>
		</>
	);
};

export default MainLayout;
