import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const PrivacyContent = lazy(() => import('src/content/Tech/Privacy'));

const Privacy = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.footer.privacy' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<PrivacyContent {...props} />
		</MainLayout>
	);
};

export default Privacy;
