import { isEmpty } from 'lodash';
import React, { lazy, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { route, staticUrl } from 'src/api';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';
import MainLayout from 'src/layouts/MainLayout';
import { fetchIssue } from 'src/store/slices/issuesSlice';

const IssueContent = lazy(() => import('src/content/Issues/Issue/IssuePage'));

const Issue = () => {
	const dispatch = useDispatch();
	const { issue } = useSelector((state) => state.issues);
	const { newspaper } = issue || {};
	const renderIssue = !isEmpty(newspaper);
	const { title, createdAt } = newspaper || {};
	const { issueId } = useParams();

	useEffect(() => {
		dispatch(fetchIssue(issueId));
	}, [issueId]);

	const issueTitle = `Выпуск ${title}`;
	const issueImage = `${staticUrl}/previews/newspaper_${issueId}.jpeg`;
	const ogUrl = `https://${route}/issues/${issueId}/`;

	useLayoutEffect(() => {
		if (!renderIssue) return;
		document.title = issueTitle;
	}, [issue]);

	return (
		<MainLayout>
			{renderIssue ? (
				<>
					<Helmet>
						<title>{issueTitle}</title>
						<meta itemProp='image' content={issueImage} />
						<meta name='twitter:image' content={issueImage} />
						<meta property='og:image' content={issueImage} />
						<meta property='og:image:width' content={1200} />
						<meta property='og:image:height' content={630} />
						<meta name='twitter:title' content={title} />
						<meta property='og:title' content={issueTitle} />
						<meta property='og:url' content={ogUrl} />
						<meta name='url' content={ogUrl} />
						<meta name='identifier-URL' content={ogUrl} />
						<meta property='og:long_title' content={issueTitle} />
						<meta property='article:published_time' content={createdAt} />
					</Helmet>
					<IssueContent />
				</>
			) : (
				<div style={{ minHeight: '100vh' }}>
					<Preloader />
				</div>
			)}
		</MainLayout>
	);
};

export default Issue;
