import { createSlice } from '@reduxjs/toolkit';


const loaderSlice = createSlice({
	name: 'LOADER',
	initialState: false,
	reducers: {
        showLoader: (state) => state = true,
        hideLoader: (state) => state = false,
    },
});
export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice.reducer;