import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const SubscriptionInformerDesktop = lazy(() =>
	import('src/components/Modules/SubscriptionInformer/SubscriptionInformerDesktop')
);

const SubscriptionsEmail = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.footer.subscribe.title' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<SubscriptionInformerDesktop {...props} />
		</MainLayout>
	);
};

export default SubscriptionsEmail;
