import React from 'react';
import { Route } from 'react-router';
import { host } from 'src/api';
import Page404 from 'src/pages/404';

const RestrictedRoute = ({ availableSites, ...rest }) => {
	const isAvailablePath = availableSites.find((site) => site === host);

	return isAvailablePath ? <Route {...rest} /> : <Route component={Page404} />;
};

export default RestrictedRoute;
