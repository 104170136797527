import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from 'src/utils/cookies';
import { notify } from 'src/components/Base/Elements/Toast';
import { root, dev, isBaltia, isConstructor, isMR7 } from 'src/api';
import { getLocalStorageParsedValue } from 'src/utils';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { setIsUserSubscriptionsVisible } from './constructorSlice';

export const fetchProfile = createAsyncThunk(
	`PROFILE/fetchProfile`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/user`, {
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			}).then((response) => {
				if (!response.ok) {
					throw response.statusText;
				} else {
					return response.json();
				}
			});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchProfile: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const userAuthentication = createAsyncThunk(
	`PROFILE/authentication`,
	async (arg, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				profile: { userSubscriptions }
			} = getState();

			const response = await fetch(`${root}/auth/email`, {
				method: 'POST',
				body: JSON.stringify(arg),
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						localStorage.setItem(LOCAL_STORAGE_KEYS.USER_MAIL, arg.email);
						return { data, email: arg.email };
					}
				});

			if (isConstructor) {
				if (userSubscriptions.length) {
					await fetch(`${root}/merge/user/subscriptions`, {
						method: 'POST',
						credentials: 'include'
					});
					localStorage.removeItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID);
				}
				if (response.data.user.subscriptions.length || userSubscriptions.length) {
					dispatch(setIsUserSubscriptionsVisible(true));
				}
			}

			return response;
		} catch (err) {
			if (dev) console.log(`Error userAuthentication: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const fetchBilling = createAsyncThunk(
	`PROFILE/fetchBilling`,
	async (email, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/user/billing?email=${email}`, {
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchBilling: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const socialAuthentication = createAsyncThunk(
	`PROFILE/socialAuthentication`,
	async (arg, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				profile: { userSubscriptions }
			} = getState();

			const response = await fetch(`${root}/auth/social`, {
				method: 'POST',
				body: JSON.stringify(arg),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});

			if (isConstructor) {
				if (userSubscriptions.length) {
					await fetch(`${root}/merge/user/subscriptions`, {
						method: 'POST',
						credentials: 'include'
					});
					localStorage.removeItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID);
				}
				if (response.user.subscriptions.length || userSubscriptions.length) {
					dispatch(setIsUserSubscriptionsVisible(true));
				}
			}

			return response;
		} catch (err) {
			notify('Что-то пошло не так. Попробуйте снова.');
			if (dev) console.log(`Auth was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const uploadAvatar = createAsyncThunk(
	`PROFILE/uploadAvatar`,
	async (b64, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/upload/avatar`, {
				method: 'POST',
				body: JSON.stringify({ file: b64 }),
				credentials: 'include',
				json: true,
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Upload avatar was not succeeded: ${err}`);
			notify(err);
			return rejectWithValue(err);
		}
	}
);

export const updateUser = createAsyncThunk(
	`PROFILE/updateUser`,
	async (user, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/update/user`, {
				method: 'POST',
				body: JSON.stringify(user),
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Update user was not succeeded: ${err}`);
			notify(err);
			return rejectWithValue(err);
		}
	}
);

export const forgotPassword = createAsyncThunk(
	`PROFILE/forgotPassword`,
	async (email, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/reset/password`, {
				method: 'POST',
				body: JSON.stringify({ email }),
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data;
					} else {
						notify(false, data.success);
						return true;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`forgot password was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const userBookmarks = createAsyncThunk(
	`PROFILE/userBookmarks`,
	async (arg, { rejectWithValue }) => {
		const { type, id, add } = arg;
		try {
			const response = await fetch(`${root}/${add ? 'add' : 'remove'}/user/bookmark`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ type, target: id }),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return { data, slug: id, add };
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`${add ? 'add ' : 'remove '}bookmark was not succeeded: ${err}`);
			notify(err);
			return rejectWithValue(err);
		}
	}
);

export const userAppeal = createAsyncThunk(
	`PROFILE/userAppeal`,
	async (arg, { rejectWithValue }) => {
		const { type, data } = arg;
		let url;
		switch (type) {
			case 'change_summ':
				url = `${root}/donate/payment/change`;
				break;
			case 'card_confirm':
				url = `${root}/donate/payment/other`;
				break;
			case 'change_date':
				url = `${root}/donate/payment/change/date`;
				break;
			case 'support':
				url = `${root}/donate/send/support`;
				break;
			case 'unsubscribe':
				url = `${root}/donate/payment/cancel`;
				break;
			case 'addEmail':
				url = `${root}/donate/attach/email`;
				break;
			case 'email':
				url = `${root}/donate/change/email`;
				break;
			default:
		}
		try {
			const response = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						notify('Ошибка при отправке письма.');
						throw data.error;
					} else {
						notify(false, 'Мы получили Ваше письмо, скоро ответим.');
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`userAppeal was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const updatePassword = createAsyncThunk(
	`PROFILE/updatePassword`,
	async (data, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/change/password`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						notify(false, data.success);
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`update password was not succeeded: ${err}`);
			notify(err);
			return rejectWithValue(err);
		}
	}
);

export const confirmEmail = createAsyncThunk(
	`PROFILE/confirmEmail`,
	async (email, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/add/confirm/email/request`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ email }),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						notify(false, data.success);
						return email;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`confirm email was not succeeded: ${err}`);
			notify(err);
			return rejectWithValue(err);
		}
	}
);

export const resetPassword = createAsyncThunk(
	`PROFILE/resetPassword`,
	async (arg, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/change/password/by/token`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ resetToken: arg.token, password: arg.password }),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						notify(false, data.success);
						return true;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`reset password was not succeeded: ${err}`);
			notify('Что-то пошло не так. Запросите восстановление пароля еще раз.');
			return rejectWithValue(err);
		}
	}
);

export const confirmEmailLink = createAsyncThunk(`PROFILE/confirmEmailLink`, async (token) => {
	try {
		await fetch(`${root}/confirm/email?confirmToken=${token}`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.error) {
					notify('Что-то пошло не так. Запросите подтверждение почты еще раз.');
				} else {
					notify(false, data.success);
				}
			});
	} catch (err) {
		if (dev) console.log(`reset password was not succeeded : ${err}`);
	}
});

export const createProfile = createAsyncThunk(
	`PROFILE/createProfile`,
	async (body, { rejectWithValue, getState }) => {
		try {
			const {
				profile: { userSubscriptions }
			} = getState();

			const response = await fetch(`${root}/add/user`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(body),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						notify(data.error);
						throw data.error;
					} else {
						notify(false, data.success);
						localStorage.setItem(LOCAL_STORAGE_KEYS.USER_MAIL, body.email);
						return data;
					}
				});

			if (userSubscriptions.length && isConstructor) {
				await fetch(`${root}/merge/user/subscriptions`, { method: 'POST', credentials: 'include' });
				localStorage.removeItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID);
			}
			return response;
		} catch (err) {
			if (dev) console.log(`create Profile was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const createAnonymousUser = () => async (_, getState) => {
	const { global } = getState();

	await fetch(`${root}/add/anonymous/user`, {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			resolution: {
				width: window.screen.width,
				height: window.screen.height
			},
			language: global.language
		})
	})
		.then((res) => res.json())
		.then(({ userId }) => {
			localStorage.setItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID, userId);
		});
};

export const addUserSubscriptions = createAsyncThunk(
	'PROFILE/addUserSubscriptions',
	async (subscriptions, { rejectWithValue, getState, dispatch }) => {
		try {
			const { profile } = getState();

			if (!profile.user && !localStorage.getItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID)) {
				await dispatch(createAnonymousUser());
			}

			const result = await Promise.all(
				subscriptions.map((subscription) =>
					fetch(`${root}/add/user/subscription`, {
						method: 'POST',
						credentials: 'include',
						body: JSON.stringify({ data: subscription }),
						headers: { 'Content-Type': 'application/json' }
					}).then((response) => response.json())
				)
			);

			dispatch(setIsUserSubscriptionsVisible(true));

			return result;
		} catch (err) {
			if (dev) console.log(`addUserSubscriptions was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const addUserSubscription = createAsyncThunk(
	`PROFILE/addUserSubscription`,
	async (body, { rejectWithValue, getState, dispatch }) => {
		try {
			const { profile } = getState();

			if (!profile.user && !localStorage.getItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID)) {
				await dispatch(createAnonymousUser());
			}

			const response = await fetch(`${root}/add/user/subscription`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ ...body }),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						!isMR7 && notify(data.error);
						throw data.error;
					} else {
						!isMR7 && notify(false, data.success);
						return data;
					}
				});

			dispatch(setIsUserSubscriptionsVisible(true));

			return response;
		} catch (err) {
			if (dev) console.log(`addUserSubscription was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const removeUserSubscription = createAsyncThunk(
	`PROFILE/removeUserSubscription`,
	async (id, { rejectWithValue, getState, dispatch }) => {
		const { profile } = getState();

		try {
			await fetch(`${root}/remove/user/subscription`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({ id }),
				headers: { 'Content-Type': 'application/json' }
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						!isMR7 && notify(data.error);
						throw data.error;
					} else {
						!isMR7 && notify(false, data.success);
						return data;
					}
				});

			if (!profile?.displayName && profile.userSubscriptions.length === 1) {
				dispatch(setIsUserSubscriptionsVisible(false));
			}

			return id;
		} catch (err) {
			if (dev) console.log(`addUserSubscription was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const removeUserSubscriptions = createAsyncThunk(
	'PROFILE/removeUserSubscriptions',
	async (subscriptions, { rejectWithValue, getState, dispatch }) => {
		try {
			const { profile } = getState();
			const res = [];

			await Promise.all(
				subscriptions.map((subscription) =>
					fetch(`${root}/remove/user/subscription`, {
						method: 'POST',
						credentials: 'include',
						body: JSON.stringify({ id: subscription }),
						headers: { 'Content-Type': 'application/json' }
					})
						.then((response) => response.json())
						.then((data) => res.push({ id: subscription, status: data }))
				)
			);

			if (!profile?.displayName && profile.userSubscriptions.length === 1) {
				dispatch(setIsUserSubscriptionsVisible(false));
			}

			const isSuccess = res.every((res) => res.status.success);
			const isFail = res.every((res) => res.status.error);

			if (isSuccess) {
				notify(false, 'Подписки успешно удалены');
			} else if (isFail) {
				notify('Не удалось удалить подписки. Обновите страницу и попробуйте снова');
			} else {
				notify('Не удалось удалить некоторые подписки. Обновите страницу и попробуйте снова');
			}

			return res;
		} catch (err) {
			if (dev) console.log(`removeUserSubscriptions was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const getUserSubscriptionsData = createAsyncThunk(
	`PROFILE/getUserSubscriptionsData`,
	async (_, { rejectWithValue, dispatch, getState }) => {
		try {
			const { profile } = getState();

			if (profile.userSubscriptions.every(({ id }) => profile.fetchedSubscriptions.includes(id)))
				return [];

			dispatch(setFetchedSubscriptions());

			const response = await fetch(`${root}/get/user/subscriptions/data`, {
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			}).then((response) => response.json());

			return response;
		} catch (err) {
			if (dev) console.log(`getUserSubscriptionsData was not succeeded: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const removeSettingsFromLocalStorage = () => {
	localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
	localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_MAIL);
	localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_REFRESHED_TIME);
	localStorage.removeItem(LOCAL_STORAGE_KEYS.BILLING);
	localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_SUBSCRIPTIONS);
	const noAds = isBaltia;
	localStorage.setItem(LOCAL_STORAGE_KEYS.NO_ADS, noAds);
};

export const checkDonateUser = (billing) => {
	let isDonate = false;
	if (billing.length > 0) {
		const month = 3600 * 24 * 30;
		const copyBillings = billing.slice(0);
		const sortBillings = copyBillings.sort((a, b) => b.crefateAt - a.createAt);
		const lastBilling = Math.round(sortBillings[0].createdAt / 1000);
		const today = Math.round(Date.now() / 1000);
		const difference = today - lastBilling;
		if (month > difference) {
			isDonate = true;
		}
	}
	return isDonate;
};

const token = getCookie('token');

const getInitialUser = () => {
	const email = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_MAIL);
	if (token && email) {
		if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) {
			let user = null;
			try {
				user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
			} catch (e) {
				if (dev) console.error('Failed to parse user, error:', e);
			}
			return user;
		}
	} else {
		removeSettingsFromLocalStorage();
	}
	return null;
};

const billingFromLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.BILLING)) || [];

const profileSlice = createSlice({
	name: 'PROFILE',
	initialState: {
		email: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_MAIL) || null,
		isPending: false,
		errors: null,
		user: getInitialUser(),
		passwordResetToken: null,
		isPartnerUser:
			token && billingFromLocalStorage.length ? checkDonateUser(billingFromLocalStorage) : false,
		preuser: null,
		message: '',
		token: '',
		billing: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.BILLING)) || [],
		isOpen: false,
		userSubscriptions: getLocalStorageParsedValue(LOCAL_STORAGE_KEYS.USER_SUBSCRIPTIONS) || [],
		fetchedSubscriptions: [],
		userSubscriptionsUpdates: [],
		lastRefreshedTime: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_REFRESHED_TIME))
	},
	reducers: {
		resetFields: (state) => {
			state.user = null;
			state.email = null;
			state.userSubscriptions = [];
			state.billing = [];
			state.isPartnerUser = false;
			state.passwordResetToken = null;
			state.lastRefreshedTime = null;
		},
		clear: (state) => {
			state.message = '';
			state.errors = null;
		},
		openLogin: (state) => {
			state.isOpen = true;
		},
		closeLogin: (state) => {
			state.isOpen = false;
		},
		toggleUserSubscriptionVisibility: (state, { payload }) => {
			state.userSubscriptions = state.userSubscriptions.map((subscription) =>
				subscription.id === payload
					? { ...subscription, isHidden: !subscription.isHidden }
					: subscription
			);
		},
		hideUserSubscriptionsVisibility: (state, { payload }) => {
			state.userSubscriptions = state.userSubscriptions.map((subscription) =>
				payload.includes(subscription.id) ? { ...subscription, isHidden: true } : subscription
			);
		},
		showUserSubscriptions: (state, { payload }) => {
			state.userSubscriptions = state.userSubscriptions.map((subscription) =>
				payload.includes(subscription.id) ? { ...subscription, isHidden: false } : subscription
			);
		},
		setPasswordResetToken: (state, { payload }) => {
			state.passwordResetToken = payload;
		},
		setLastRefreshedTime: (state, { payload }) => {
			state.lastRefreshedTime = payload;
			localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_REFRESHED_TIME, payload);
		},
		setFetchedSubscriptions: (state) => {
			state.fetchedSubscriptions = state.userSubscriptions.map((subscription) => subscription.id);
		}
	},
	extraReducers: {
		// fetchProfile
		[fetchProfile.pending]: (state) => {
			state.isPending = true;
		},
		[fetchProfile.fulfilled]: (state, action) => {
			state.isPending = false;

			if (isConstructor || isMR7) {
				const hiddenSubscriptions = state.userSubscriptions.reduce((acc, { isHidden, id }) => {
					acc[id] = !!isHidden;

					return acc;
				}, {});

				const user = action.payload.user || action.payload.userAnonymous;
				state.user = action.payload.user;
				if (user.subscriptions.length) {
					state.userSubscriptions = user.subscriptions.map((subscription) => ({
						...subscription,
						isHidden: hiddenSubscriptions[subscription.id]
					}));
				}
			}

			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(action.payload.user || ''));
			localStorage.setItem(LOCAL_STORAGE_KEYS.NO_ADS, action.payload.user?.isNoAdsProfile || false);
		},
		[fetchProfile.rejected]: (state, action) => {
			state.isPending = false;
			state.errors = action.payload;
		},
		// fetchBilling
		[fetchBilling.fulfilled]: (state, { payload }) => {
			const { billing } = payload;
			if (billing.length) {
				state.billing = billing;
				const res = checkDonateUser(billing);
				state.isPartnerUser = res;
			}
			localStorage.setItem(LOCAL_STORAGE_KEYS.BILLING, JSON.stringify(billing));
		},
		[fetchBilling.rejected]: (state, action) => {
			state.errors = action.payload;
		},
		// userAuthentication
		[userAuthentication.pending]: (state) => {
			state.isPending = true;
		},
		[userAuthentication.fulfilled]: (state, action) => {
			state.isPending = false;
			state.user = action.payload.data.user;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(action.payload.data.user));
			state.email = action.payload.email;
		},
		[userAuthentication.rejected]: (state, action) => {
			notify(action.payload);
			state.isPending = false;
		},
		// socialAuthentication
		[socialAuthentication.pending]: (state) => {
			state.isPending = true;
		},
		[socialAuthentication.fulfilled]: (state, action) => {
			state.isPending = false;
			state.user = action.payload.user;
			state.email = action.payload.email;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(action.payload.user));
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER_MAIL, action.payload.email);
		},
		[socialAuthentication.rejected]: (state, action) => {
			state.isPending = false;
		},
		// uploadAvatar
		[uploadAvatar.pending]: (state) => {
			state.isPending = true;
		},
		[uploadAvatar.fulfilled]: (state, action) => {
			state.isPending = false;
			state.user.avatar = action.payload.url;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(state.user));
		},
		[uploadAvatar.rejected]: (state, action) => {
			state.isPending = false;
		},
		// updateUser
		[updateUser.pending]: (state) => {
			state.isPending = true;
		},
		[updateUser.fulfilled]: (state, action) => {
			state.isPending = false;
			state.user = action.payload.user;
			notify(false, action.payload.success);
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(action.payload.user));
		},
		[updateUser.rejected]: (state, action) => {
			state.isPending = false;
		},
		// userBookmarks
		[userBookmarks.fulfilled]: (state, action) => {
			notify(false, action.payload.data.success);
			let newArr;
			if (action.payload.add) {
				let newBookmark = { slug: action.payload.slug };
				newArr = [newBookmark, ...state.user.recordsBookmarks];
			} else {
				newArr = state.user.recordsBookmarks.filter((item) => item.slug !== action.payload.slug);
			}
			state.user.recordsBookmarks = newArr;
			const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
			user.recordsBookmarks = newArr;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
		},
		// updatePassword
		[uploadAvatar.fulfilled]: (state, action) => {
			state.user.avatar = action.payload.url;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(state.user));
		},
		// confirmEmail
		[confirmEmail.fulfilled]: (state, action) => {
			state.email = action.payload;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER_MAIL, action.payload);
		},
		// forgotPassword
		[forgotPassword.pending]: (state, action) => {
			state.errors = null;
			state.isPending = true;
		},
		[forgotPassword.fulfilled]: (state, action) => {
			state.isPending = false;
			state.errors = null;
		},
		[forgotPassword.rejected]: (state, action) => {
			state.errors = action.payload.error;
			state.isPending = false;
		},
		// resetPassword
		[resetPassword.pending]: (state, action) => {
			state.errors = null;
			state.isPending = true;
		},
		[resetPassword.fulfilled]: (state, action) => {
			state.isPending = false;
			state.errors = null;
		},
		[resetPassword.rejected]: (state, action) => {
			state.errors = action.payload;
			state.isPending = false;
		},
		// createProfile
		[createProfile.pending]: (state, action) => {
			state.errors = null;
			state.isPending = true;
		},
		[createProfile.fulfilled]: (state, { payload }) => {
			state.isPending = false;
			state.user = payload.user;
			state.email = payload.email;
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(payload.user));
			localStorage.setItem(LOCAL_STORAGE_KEYS.NO_ADS, payload.user.isNoAdsProfile || false);
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER_MAIL, payload.email);
			state.errors = null;
		},
		[createProfile.rejected]: (state, action) => {
			state.errors = action.payload;
			state.isPending = false;
		},
		[addUserSubscription.fulfilled]: (state, { payload }) => {
			const subscription = {
				id: payload.id,
				targetTitle: payload.targetTitle,
				target: payload.target,
				type: payload.type,
				targetUrl: payload.targetUrl
			};
			const subscriptions = [...state.userSubscriptions];
			subscriptions.push(subscription);
			state.userSubscriptions = subscriptions;
		},
		[addUserSubscriptions.fulfilled]: (state, { payload }) => {
			const subscriptions = [...state.userSubscriptions];
			payload.forEach((subscription) => {
				if (subscription.error) return;

				subscriptions.push(subscription);
			});

			state.userSubscriptions = subscriptions;
		},
		[removeUserSubscription.fulfilled]: (state, { payload }) => {
			const subscriptions = state.userSubscriptions.filter(
				(subscription) => subscription.id !== payload
			);
			state.userSubscriptions = subscriptions;
			state.userSubscriptionsUpdates = state.userSubscriptionsUpdates.filter(
				({ subscriptionId }) => subscriptionId !== payload
			);
		},
		[removeUserSubscriptions.fulfilled]: (state, { payload }) => {
			let newSubscriptionsArr = [...state.userSubscriptions];
			let newSubscriptionsUpdatesArr = [...state.userSubscriptionsUpdates];
			payload.forEach(({ id, status }) => {
				if (status.hasOwnProperty('success')) {
					newSubscriptionsArr = newSubscriptionsArr.filter(
						(subscription) => subscription.id !== id
					);
					newSubscriptionsUpdatesArr = newSubscriptionsUpdatesArr.filter(
						({ subscriptionId }) => subscriptionId !== id
					);
				}
			});
			state.userSubscriptions = newSubscriptionsArr;
			state.userSubscriptionsUpdates = newSubscriptionsUpdatesArr;
		},
		[getUserSubscriptionsData.fulfilled]: (state, { payload }) => {
			const { records } = payload;
			if (!records || !records.length) return;

			const shouldBeAdded = records.every((record) => record.typeId.length);

			const sort = (arr) => arr.sort((a, b) => b.date - a.date);

			if (shouldBeAdded) {
				state.userSubscriptionsUpdates = sort(records);
			}
		}
	}
});
export const {
	resetFields,
	clear,
	openLogin,
	closeLogin,
	toggleUserSubscriptionVisibility,
	hideUserSubscriptionsVisibility,
	showUserSubscriptions,
	setPartnerMode,
	setPasswordResetToken,
	setLastRefreshedTime,
	setFetchedSubscriptions
} = profileSlice.actions;

export const signoutProfile = () => (dispatch) => {
	removeSettingsFromLocalStorage();
	localStorage.removeItem(LOCAL_STORAGE_KEYS.ANONYMOUS_ID);
	document.cookie.split(';').forEach((c) => {
		document.cookie = c
			.replace(/^ +/, '')
			.replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
	});

	dispatch(setIsUserSubscriptionsVisible(false));
	fetch(`${root}/sign/out`, {
		method: 'POST',
		credentials: 'include'
	});
	dispatch(resetFields());
};

export default profileSlice.reducer;
