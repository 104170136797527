import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchPodcasts = createAsyncThunk(
	`PODCASTS/fetchPodcasts`,
	async (_, { rejectWithValue }) => {
		const response = await fetch(`${root}/get/podcasts`)
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
					throw data.error;
				} else {
					return data;
				}
			})
			.catch((err) => {
				if (dev) console.log(`Error fetch podcasts: ${err}`);
				return rejectWithValue(err);
			});
		return response;
	}
);

const podcastsSlice = createSlice({
	name: 'PODCASTS',
	initialState: {
		podcasts: null,
		allPodcastsRecords: null,
		error: false,
		isPending: true,
	},
	reducers: {
		saveAllPodcastsRecordsinRedux(state, action) {
			state.allPodcastsRecords = action.payload;
		}
	},
	extraReducers: {
		// fetchPodcasts
		[fetchPodcasts.pending]: (state, action) => {
			state.isPending = true;
			state.error = false;
		},
		[fetchPodcasts.fulfilled]: (state, action) => {
			state.error = false;
			state.isPending = false;
			const sortPodcasts = action.payload.podcasts.sort((a, b) => b.lastEpisodeDate - a.lastEpisodeDate);
			state.podcasts = sortPodcasts;
		},
		[fetchPodcasts.rejected]: (state, action) => {
			state.error = action.payload;
			state.podcasts = null;
		},
	},
});

export const { saveAllPodcastsRecordsinRedux } = podcastsSlice.actions;

export default podcastsSlice.reducer;
