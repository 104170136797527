import React from 'react';
import { Link } from 'react-router-dom';
import { footer } from 'config/data';
import useTranslates from 'src/hooks/useTranslates';
import useUrlParams from 'src/hooks/useUrlParams';
import cx from 'classnames';
import Column from '../Footer/Column/Column';
import styles from './index.module.scss';
import MoscowConnection from '../Footer/Connection/MoscowConnection';

const ConstructorFooter = () => {
	const [cooperationTitle] = useTranslates('all.footer.cooperation');
	const { isPrint } = useUrlParams();
	const { newsroom, socials, push, error, donater, constructor } = footer;

	return !isPrint ? (
		<footer className={cx(styles.root, 'constructor-footer')}>
			<div className={styles.container}>
				<div className={styles.navigation}>
					<div className={styles.navigation_column}>
						<Column {...constructor}>
							<div className={styles.cooperation}>
								<p className={styles.cooperation_title}>{cooperationTitle}</p>
								<a href='mailto:web@novayagazeta.ru'>web@novayagazeta.ru</a>
							</div>
						</Column>
						<Column {...socials} />
					</div>
					<div className={cx(styles.navigation_column, 'constructor-footer-slim-column')}>
						<Column {...push} />
						<Column {...newsroom} />
					</div>
					<div>
						<Column {...donater} />
						<Column {...error} notShowOnMobile />
					</div>
					<div className='constructor-footer-contacts-column'>
						<MoscowConnection />
					</div>
				</div>
				<p className={styles.text}>
					Редакционный коллектив «Новой газеты», 2022. 18+.{' '}
					<Link to='/terms'>Пользовательское соглашение</Link>.{' '}
					<Link to='/privacy'>Политика конфиденциальности</Link>.
				</p>
				<div className={styles.logo}>
					<img src="/ic_puzzle_footer.svg" alt="logo" />
				</div>
			</div>
		</footer>
	) : null;
};

export default ConstructorFooter;
