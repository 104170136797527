import { isNumeric } from 'src/utils/number';
import { SPECIAL_CHARACTERS_REGEXP } from './regexp';

export function isString(str) {
	return typeof str === 'string';
}
export function withoutHashtag(str) {
	return isString(str) ? str.replace('#', '') : '';
}

export const formatString = (text) => {
	let block = text;

	block = block.split(' ##').join('##');
	block = block.split(' #').join('#');
	block = block.split('&amp;nbsp;').join(' ');
	block = block.split('&nbsp;').join(' ');
	block = block.split('	').join('');
	block = block.split('\\n\\t').join('');
	block = block.split('#### Закон').join('####* Закон');
	block = block.split('&lt;').join('<');
	block = block.split('&gt;').join('>');
	block = block.split('&amp;lt;').join('<');
	block = block.split('&amp;gt;').join('>');
	block = block.split('&amp;nbsp;').join(' ');
	block = block.split('&amp;').join('&');
	block = block.split('&amp;').join('&');

	return block;
};

export const textWithoutTags = (text) => formatString(text.replace(/<\/?[a-zA-Z]+>/gi, ''));

export const getAuthorInitials = (name, surname) => {
	let letterAvatarContent = `${name[0] === '«' ? name[1] : name[0]}${
		surname ? (surname[0] === '«' ? surname[1] : surname[0]) : ''
	}`;

	return letterAvatarContent.toLocaleUpperCase();
};

export const getThemeInitials = (title) => {
	let letterAvatarContent = '';
	if (isNumeric(title[0])) {
		let i = 0;
		while (isNumeric(title[i]) && letterAvatarContent.length < 4) {
			letterAvatarContent += title[i];
			i++;
		}
	} else {
		letterAvatarContent = title[0] === '«' ? title[1] : title[0];
	}

	return letterAvatarContent.toLocaleUpperCase();
};

export const generateArticleTitle = (title, subtitle) => {
	let newTitle = textWithoutTags(title).trim();
	const lastCharacter = newTitle[newTitle.length - 1];

	if (subtitle) {

		if (SPECIAL_CHARACTERS_REGEXP.test(lastCharacter)) {
			newTitle = `${newTitle} ${subtitle}`;
		} else {
			newTitle = `${newTitle}. ${subtitle}`;
		}
	}

	return newTitle;
};
