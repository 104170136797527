import { useLayoutEffect } from 'react';
import * as api from 'src/api';

const useConstructorTheme = () => {
	useLayoutEffect(() => {
		const novayaConstructorClass = 'novaya-constructor-theme';
		const novayaMediaClass = 'novaya-media-theme';
		if (api.constructor) {
			const mainContainer = document.getElementById('ng-root');
			mainContainer.classList.add(novayaConstructorClass, api.isConstructor && novayaMediaClass);
			document.documentElement.style.setProperty('--sansSerif', "'Roboto Flex', sans-serif");
		}
	}, []);
};

export default useConstructorTheme;
