import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REDIRECTS } from 'src/config/redirects';
import { setPasswordResetToken } from 'src/store/slices/profileSlice';

const DummyPasswordReset = (props) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const passwordResetToken = new URLSearchParams(props.location.search).get('token');

	useEffect(() => {
		if (!passwordResetToken) return;
		dispatch(setPasswordResetToken(passwordResetToken));
	}, [passwordResetToken]);

	return !profile.user ? <Redirect to='/' /> : <Redirect to={REDIRECTS.PROFILE} />;
};

export default DummyPasswordReset;
