import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchDistricts = createAsyncThunk(
	`MR7/fetchDistricts`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/themes`).then((response) => {
				if (!response.ok) {
					throw response.statusText;
				} else {
					return response.json();
				}
			});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchDistricts: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const mr7Slice = createSlice({
	name: 'MR7',
	initialState: {
		districts: [],
		isSideMenuOpen: false
	},
	reducers: {
		setIsSideMenuOpen: (state, { payload }) => {
			state.isSideMenuOpen = payload;
		}
	},
	extraReducers: {
		[fetchDistricts.fulfilled]: (state, { payload }) => {
			const { themes } = payload;
			const districts = themes.filter((theme) => theme.slug.startsWith('district-'));

			if (districts.length) {
				state.districts = districts.sort((a, b) => {
					const titleA = a.title;
					const titleB = b.title;
					if (titleA < titleB) return -1;
					if (titleA > titleB) return 1;
					return 0;
				});
			}
		}
	}
});

export const { setIsSideMenuOpen } = mr7Slice.actions;

export default mr7Slice.reducer;
