import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { confirmEmailLink } from 'src/store/slices/profileSlice';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';
import { Redirect } from 'react-router-dom';
import { REDIRECTS } from 'src/config/redirects';

const Confirmation = (props) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const [confirmed, setConfirmed] = useState(false);

	useEffect(() => {
		dispatch(confirmEmailLink(new URLSearchParams(props.location.search).get('token')));
		setConfirmed(true);
	}, []);

	return confirmed ? (
		<Redirect to={profile.user ? REDIRECTS.PROFILE : '/'} />
	) : (
		<div style={{ minHeight: '95vh' }}>
			<Preloader />
		</div>
	);
};

export default Confirmation;
