import { isConstructor } from 'src/api';

export const REDIRECTS = {
	PROFILE: isConstructor ? '/profile/subscriptions' : '/profile/bookmarks'
};


export const REDIRECTS_MR7_ARTICLES = {
	'/articles/249366/' : '/articles/2022/11/14/upravliaiushchuiu-organizatsiiu-oshtrafovali-za-treshchiny-v-paradnoi-doma-na-nevskom-uklon-pola-ot-stupenei-dostig-10-santimetrov-news',
	'/articles/249363/' : '/articles/2022/11/14/napavshego-na-feldsherov-skoroi-pomoshchi-otpravili-v-koloniiu-na-5-let-muzhchina-pytalsia-udarit-po-golove-nozhom-zhenshchinu-vracha-i-ee-kollegu-news',
	'/articles/249362/' : '/articles/2022/11/14/mariupolskie-babushki-nachali-vozvrashchatsia-iz-lenoblasti-domoi',
	'/articles/249364/' : '/articles/2022/11/14/anomalnye-pogodnye-iavleniia-meshaiut-uborke-listvy-v-pushkine-ob-etom-soobshchili-v-administratsii-munitsipalnogo-obrazovaniia-news',
	'/articles/249365/' : '/articles/2022/11/14/esli-my-ne-naidem-kompromissa-ia-gotov-zamurovat-eto-zhilio',
	'/articles/249358/' : '/articles/2022/11/14/meditsinskii-ermitazh-pokazyvaet-ne-kak-ubivat-a-kak-spasat',
	'/articles/249360/' : '/articles/2022/11/14/pamiatnik-pediatru-igoriu-vorontsovu-otkryli-u-bolnitsy-sviatoi-marii-magdaliny-news',
	'/articles/249361/' : '/articles/2022/11/14/direktora-leningradskogo-zooparka-irinu-skibu-uvolili-ona-rabotala-v-zooparke-s-1982-goda-a-glavoi-uchrezhdeniia-byla-pochti-20-let-news',
	'/articles/233799/' : '/longreads/mi-nemnogko-kosmonavti',
	'/articles/234141/' : '/longreads/teatralnaya-neotlogka',
	'/articles/234919/' : '/longreads/lifchikovaya-amnistiya',
	'/articles/235257/' : '/longreads/ladogskiy-burevestnik-pro-kommercheskie-svyatini-shtormovie-gonki-i-nastoyashchih-shamanov',
	'/articles/238017/' : '/longreads/zverinie-zakoni-o-pravah-givotnih-v-rossii-glavnaya'
}

export const LONGREADS_REDIRECTS_MR7_TO_TILDA = {
	'mi-nemnogko-kosmonavti' : 'http://longreads.mr7.tilda.ws/',
	'teatralnaya-neotlogka' : 'http://longreads.mr7.tilda.ws/teatralnayaneotlozhka',
	'lifchikovaya-amnistiya' : 'http://longreads.mr7.tilda.ws/bezlifchika',
	'ladogskiy-burevestnik-pro-kommercheskie-svyatini-shtormovie-gonki-i-nastoyashchih-shamanov' : 'http://longreads.mr7.tilda.ws/ladogakakonaest',
	'zverinie-zakoni-o-pravah-givotnih-v-rossii-glavnaya' : 'http://longreads.mr7.tilda.ws/zverinye_zakony',
}