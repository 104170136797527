import React, { lazy } from 'react';
import {
	isConstructor,
	isLandingConstructor,
	isMR7Component,
	isPictureOfDayComponent
} from 'src/api';
import MainMetaTags from 'src/components/MainMetaTags';
import MainLayout from 'src/layouts/MainLayout';

const IndexPage = lazy(() => import('src/content/IndexPage'));
const Constructor = lazy(() => import('src/content/Constructor'));
const LandingConstructor = lazy(() => import('src/content/LandingConstructor'));
const MR7 = lazy(() => import('src/content/MR7'));

const Index = () => (
	<MainLayout isExtendedConstructorMenu isBigConstructorMenu>
		<MainMetaTags />
		{isConstructor && <Constructor />}
		{isLandingConstructor && <LandingConstructor />}
		{isPictureOfDayComponent && <IndexPage spb={false} />}
		{isMR7Component && <MR7 />}
	</MainLayout>
);

export default Index;
