import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchArchivePosts = createAsyncThunk(
	`EUROPE/fetchArchivePosts`,
	async (page, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/records/chronological?page=${page}`).then(
				(response) => {
					if (!response.ok) {
						throw response.statusText;
					} else {
						return response.json();
					}
				}
			);
			return { page, records: response.records };
		} catch (err) {
			if (dev) console.log(`Error fetchPosts: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const europeSlice = createSlice({
	name: 'EUROPE',
	initialState: {
		isDisplayArchive: false,
		posts: {},
		page: 0,
		additionalRequestDisabled: false
	},
	reducers: {
		setIsDisplayArchive(state, { payload }) {
			state.isDisplayArchive = payload;
		}
	},
	extraReducers: {
		[fetchArchivePosts.fulfilled]: (state, { payload }) => {
			const { records, page } = payload;
			if (!page) {
				if (records.length) state.posts = { records };
			} else {
				if (records.length) {
					const concatRecords = state.posts.records.concat(records);
					state.posts = { records: concatRecords };
				} else {
					state.additionalRequestDisabled = true;
				}
				state.page = page;
			}
		}
	}
});
export const { setIsDisplayArchive, setPage } = europeSlice.actions;
export default europeSlice.reducer;
