import React from 'react';
import { isMedia, isMoscow, isNO } from 'src/api';
import { isMobile } from 'react-device-detect';
import useTranslates from 'src/hooks/useTranslates';
import styles from './Column.module.scss';

const Columns = ({ type }) => {
	const [errorText, donateText, donateQuestion, phone] = useTranslates(
		'all.footer.error.text',
		'all.footer.donate.text',
		'all.footer.donate.question',
		'all.phone'
	);

	const textSelector = () => {
		switch (type) {
			case 'error':
				return (
					<div className={styles.textRoot}>
						<p className={styles.text} style={{ marginBottom: 40 }}>
							{errorText} <span className={styles.highlighted}>ctrl+enter</span>.
						</p>
					</div>
				);
			case 'donater':
				return (
					<div className={styles.textRoot}>
						<p className={styles.text}>
							{isMedia && (
								<a
									href={
										isMobile
											? 'https://bit.ly/construct_footer_mob_co'
											: 'https://bit.ly/construct_footer_co'
									}
									target='_blank'
									rel='noopener noreferrer'
								>
									Как стать соучастником «Новой газеты»
								</a>
							)}
							{isNO && (
								<a
									href={
										isMobile ? 'https://bit.ly/no_footer_mob_co' : 'https://bit.ly/no_footer_co'
									}
									target='_blank'
									rel='noopener noreferrer'
								>
									{donateText}
								</a>
							)}
							{isMoscow && (
								<a
									href={
										isMobile ? 'https://bit.ly/ng_footer_mob_co' : 'https://bit.ly/ng_footer_co'
									}
									target='_blank'
									rel='noopener noreferrer'
								>
									{donateText}
								</a>
							)}
						</p>
						<p className={styles.text}>
							{donateQuestion}
							<br />
							<a href='mailto:donate@novayagazeta.ru'>donate@novayagazeta.ru</a>
							{phone}:{' '}
							<a href='tel:+79296120368' style={{ display: 'inline' }}>
								+7 (929) 612-03-68
							</a>
						</p>
					</div>
				);
			default:
				return { type };
		}
	};
	return textSelector();
};

export default Columns;
