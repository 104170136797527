const bids = {
	'adfox_151705922056116114': [
		{
			'bidder': 'criteo',
			'params': {
				'placementId': '1139240'
			}
		},
		{
			'bidder': 'myTarget',
			'params': {
				'placementId': '343435'
			}
		},
		{
			'bidder': 'betweenDigital',
			'params': {
				'placementId': '2511157'
			}
		},
		{
			'bidder': 'segmento',
			'params': {
				'placementId': '358'
			}
		},
		{
			'bidder': 'rtbhouse',
			'params': {
				'placementId': 'XLWbc6H2UuRpsXlTBle9'
			}
		},
		{
			'bidder': 'adriver',
			'params': {
				'placementId': '41:novayagazeta_300x600_2'
			}
		},
		{
			'bidder': 'buzzoola',
			'params': {
				'placementId': '1217880'
			}
		},
		{
			'bidder': 'otm',
			'params': {
				'placementId': '8193'
			}
		},
		{
			'bidder': 'hybrid',
			'params': {
				'placementId': '5ec504351d922e993c33e068'
			}
		},
		{
			'bidder': 'adfox_WiseUP',
			'params': {
				'pp': 'h',
				'ps': 'cjkq',
				'p2': 'gewe'
			}
		},
		{
			"bidder": "adfox_adsmart",
			"params": {
				"p1": "cqgvb",
				"p2": "hhro"
			}
		}
	],

	'adfox_151705922056116117': [
		{
			bidder: 'criteo',
			params: {
				placementId: '1139243',
			},
		},
		{
			bidder: 'rtbhouse',
			params: {
				placementId: 'hx26iyLhNUfi6Qaa7KAp',
			},
		},
		{
			bidder: 'betweenDigital',
			params: {
				placementId: '2511161',
			},
		},
		{
			bidder: 'myTarget',
			params: {
				placementId: '343461',
			},
		},
		{
			bidder: 'buzzoola',
			params: {
				placementId: '1221369',
			},
		},
		{
			bidder: 'segmento',
			params: {
				placementId: '358',
			},
		},
		{
			bidder: 'adriver',
			params: {
				placementId: '41:novayagazeta_300х250mob_1',
			},
		},
		{
			bidder: 'otm',
			params: {
				placementId: '8186',
			},
		},
		{
			bidder: 'hybrid',
			params: {
				placementId: '5ec4f7e1637387fc640d209d',
			},
		},
	],

	'adfox_151705922056116112': [
		{
			bidder: 'rtbhouse',
			params: {
				placementId: 'LqPwO2ot5PvEt1JboOyn',
			},
		},
		{
			bidder: 'criteo',
			params: {
				placementId: '1365902',
			},
		},
		{
			bidder: 'segmento',
			params: {
				placementId: '358',
			},
		},
		{
			bidder: 'myTarget',
			params: {
				placementId: '368603',
			},
		},
		{
			bidder: 'adriver',
			params: {
				placementId: '41:novayagazeta_970х250',
			},
		},
		{
			bidder: 'hybrid',
			params: {
				placementId: '60392186d59a0d87fcc187f8',
			},
		},
		{
			bidder: 'betweenDigital',
			params: {
				placementId: '2706970',
			},
		},
		{
			bidder: 'otm',
			params: {
				placementId: '10744',
			},
		},
		{
			bidder: 'buzzoola',
			params: {
				placementId: '1221688',
			},
		},
		{
			bidder: 'adfox_adsmart',
			params: {
				p1: 'bxbpj',
				p2: 'ul',
			},
		},
	],

	'adfox_151705922056116858': [
		{
			"bidder": "criteo",
			"params": {
				"placementId": "1365906"
			}
		},
		{
			"bidder": "rtbhouse",
			"params": {
				"placementId": "kHiZI90jB8wJegwveKEf"
			}
		},
		{
			"bidder": "relap",
			"params": {
				"placementId": "inWKAsICNRTunujo"
			}
		},
		{
			"bidder": "segmento",
			"params": {
				"placementId": "358"
			}
		},
		{
			"bidder": "myTarget",
			"params": {
				"placementId": "343447"
			}
		},
		{
			"bidder": "buzzoola",
			"params": {
				"placementId": "1217879"
			}
		},
		{
			"bidder": "hybrid",
			"params": {
				"placementId": "5ec50321637387fc640d20a0"
			}
		},
		{
			"bidder": "adfox_adsmart",
			"params": {
				"p1": "bxbpj",
				"p2": "ul"
			}
		}],

	'adfox_151705942291497217': [
		{
			'bidder': 'criteo',
			'params': {
				'placementId': '1139236'
			}
		},
		{
			'bidder': 'myTarget',
			'params': {
				'placementId': '343459'
			}
		},
		{
			'bidder': 'betweenDigital',
			'params': {
				'placementId': '2511162'
			}
		},
		{
			'bidder': 'rtbhouse',
			'params': {
				'placementId': 'ZMTqvzZ1L1oXYZ6TY4nb'
			}
		},
		{
			'bidder': 'segmento',
			'params': {
				'placementId': '358'
			}
		},
		{
			'bidder': 'hybrid',
			'params': {
				'placementId': '5ec4f7e1637387fc640d209d'
			}
		},
		{
			'bidder': 'adriver',
			'params': {
				'placementId': '41:novayagazeta_300х250mob_3'
			}
		},
		{
			'bidder': 'adfox_adsmart',
			'params': {
				'p1': 'bxbpj',
				'p2': 'ul'
			}
		},
		{
			'bidder': 'otm',
			'params': {
				'placementId': '8188'
			}
		},
		{
			'bidder': 'adfox_WiseUP',
			'params': {
				'p1': 'caaqv',
				'p2': 'flhb'
			}
		}
	],
	'adfox_164542048788111928': [
		{
			"bidder": "adfox_adsmart",
			"params": {
				"p1": "cslzg",
				"p2": "hhuq"
			}
		}
	],
	'adfox_737976580163075300': [
		{
			"bidder": "criteo",
			"params": {
				"placementId": "1365904"
			}
		},
		{
			"bidder": "betweenDigital",
			"params": {
				"placementId": "2706969"
			}
		},
		{
			"bidder": "segmento",
			"params": {
				"placementId": "358"
			}
		},
		{
			"bidder": "adriver",
			"params": {
				"placementId": "41:novayagazeta_970x250_1"
			}
		},
		{
			"bidder": "otm",
			"params": {
				"placementId": "8194"
			}
		},
		{
			"bidder": "hybrid",
			"params": {
				"placementId": "5ec504e31d922e993c33e069"
			}
		},
		{
			"bidder": "myTarget",
			"params": {
				"placementId": "343455"
			}
		},
		{
			"bidder": "buzzoola",
			"params": {
				"placementId": "1221689"
			}
		},
		{
			"bidder": "adfox_adsmart",
			"params": {
				"pp": "h",
				"ps": "ewxh",
				"p2": "ul"
			}
		}
	]
};

export default bids;
