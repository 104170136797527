import { cssTransition, toast } from 'react-toastify';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import './styles.css';

const swirl = cssTransition({
	enter: 'swirl-in-fwd',
	exit: 'swirl-out-bck'
});

export const notify = (err, text) => {
	const language = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
	const english = language === 'en';
	let autoCloseDuration = 0;

	if (err) {
		autoCloseDuration = err ? err.length * 40 : 2000;
	} else {
		autoCloseDuration = text ? text.length * 40 : 2000;
	}

	const autoClose = autoCloseDuration > 2000 ? autoCloseDuration : 2000;

	err
		? toast.error(`${english ? 'Error' : 'Ошибка'}: ${err}`, {
				transition: swirl,
				autoClose
		  })
		: toast(text, {
				transition: swirl,
				autoClose
		  });
};

export default notify;
