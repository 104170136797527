import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { isConstructor, isEurope, isMR7Component, isMultiLang, root } from 'src/api';
import { shuffleArray } from 'src/utils/array';
import { CIS_languages } from 'src/utils/CIS_languages';

export const fetchMain = createAsyncThunk(`MAIN/fetchMain`, async (_, { rejectWithValue }) => {
	const langFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
	let language = 'ru';

	if (window.location.pathname === '/en') {
		language = 'en';
	} else if (langFromLocalStorage) {
		language = langFromLocalStorage;
	} else {
		let userLang = 'ru';
		try {
			userLang = navigator.language;
		} catch (err) {
			console.log('Не удалось получить язык браузера');
		}
		const isRusLang = CIS_languages.findIndex((lang) => userLang.includes(lang)) !== -1;
		if (!isRusLang) {
			language = 'en';
		}
	}

	let url = `${root}/get/main`;

	if (isMultiLang && language && language !== 'ru') {
		url += `?lang=${language}`;
	}

	try {
		const response = await fetch(`${url}`).then((res) => res.json());
		return { response, lang: language };
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

export const fetchMainSPB = createAsyncThunk(
	`MAIN/fetchMainSPB`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/main?id=saint-petersburg`).then((res) =>
				res.json()
			);
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchTopSix = createAsyncThunk(`MAIN/fetchTopSix`, async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${root}/get/records/top/6`).then((res) => res.json());
		return response;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

const mainSlice = createSlice({
	name: 'MAIN',
	initialState: {
		recommendations: null,
		records: null,
		recordsSPB: null,
		topSix: null,
		error: false,
		lang: null,
		subscriptionsForRecommendationsBlocks: {
			types: [], // 3 or 6, for example [3, 6, 3] or [6, 3], count blocks
			subscriptions: [] // includes all subscriptions together
		},
		haulingGroup: null
	},
	reducers: {
		cleanRecords: (state) => {
			state.records = null;
			state.error = false;
		}
	},
	extraReducers: {
		[fetchMain.pending]: (state) => {
			if (isEurope) {
				state.haulingGroup = null;
			}
		},
		[fetchMain.fulfilled]: (state, { payload }) => {
			if (payload.error) {
				state.error = payload.response.error;
			} else {
				let records = payload.response.records;

				if (isMR7Component) {
					const haulingGroup = records.filter((block) => block.type === 'krik_group');
					if (haulingGroup.length) {
						state.haulingGroup = haulingGroup[0];
					}
				}

				if (isEurope && records.length && records[0].type === 'krik_group') {
					state.haulingGroup = records[0];
					records.splice(0, 1);
				}

				state.lang = payload.lang;
				state.records = records;

				const recommendations = records.filter((block) => block.type === 'recommendation');
				if (!recommendations.length) return;

				if (isConstructor) {
					const types = [];
					let subscriptions = [];
					recommendations.forEach(({ data, items }) => {
						types.push(data);
						subscriptions = [...subscriptions, ...items];
					});

					const shuffledSubscriptions = shuffleArray(subscriptions).filter(
						({ type }) => type !== 'subscription'
					);

					state.recommendations = shuffledSubscriptions.slice(0, 3);

					subscriptions.forEach((subscription, index) => {
						if (subscription.type !== 'subscription') return;

						shuffledSubscriptions.splice(index, 0, subscription);
					});

					state.subscriptionsForRecommendationsBlocks = {
						types,
						subscriptions: shuffledSubscriptions
					};
				}
			}
		},
		[fetchMainSPB.fulfilled]: (state, action) => {
			if (action.payload.error) {
				state.error = action.payload.error;
			} else {
				state.recordsSPB = action.payload.records;
			}
		},
		[fetchTopSix.fulfilled]: (state, action) => {
			if (action.payload.error) {
				state.error = action.payload.error;
			} else {
				state.topSix = action.payload.records;
			}
		}
	}
});

export const { cleanRecords } = mainSlice.actions;

export default mainSlice.reducer;
