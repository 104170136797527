import { useLayoutEffect } from 'react';
import { isMR7 } from 'src/api';

const useMr7Theme = () => {
	useLayoutEffect(() => {
		const mr7Class = 'mr7-theme';
		if (isMR7) {
			const mainContainer = document.getElementById('ng-root');
			mainContainer.classList.add(mr7Class);
			document.documentElement.style.setProperty('--sansSerif', "'PT Sans', sans-serif");
			document.documentElement.style.setProperty('--serif', "'PT Serif', serif");
			document.body.style.backgroundColor = 'white';
		}
	}, []);
};

export default useMr7Theme;
