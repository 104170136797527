import * as api from 'src/api';

/* Header */
export const materials = api.headerMaterials;

/* Footer */
export const footer = {
	push: {
		title: 'all.footer.push.title',
		links: [...api.footerPush]
	},
	error: {
		title: 'all.footer.error',
		links: [
			{
				text: true,
				data: 'error'
			}
		]
	},
	donater: {
		title: 'all.donate.title',
		links: [
			{
				text: true,
				data: 'donater'
			}
		]
	},
	materials: { ...api.materials },
	rubrics: {
		title: 'all.rubrics',
		links: [
			{
				title: 'Политика',
				url: '/politics'
			},
			{
				title: 'Экономика',
				url: '/economy'
			},
			{
				title: 'Общество',
				url: '/society'
			},
			{
				title: 'Культура',
				url: '/culture'
			}
		]
	},
	ads: {
		title: 'Купить рекламу',
		links: [
			{
				text: true,
				data: 'ads'
			}
		]
	},
	socials: {
		title: 'all.footer.socials',
		links: api.socials.links,
		isSocial: true
	},
	look: {
		title: 'Посмотреть',
		links: [
			{
				title: 'Спецпроекты',
				target: '_blank',
				external: true,
				url: '//special.novayagazeta.ru/'
			}
		]
	},
	listen: {
		title: 'all.footer.listen',
		links: [...api.footerListen]
	},
	newsroom: {
		title: 'all.footer.editorial',
		links: api.newsroom.links
	},
	constructor: {
		title: 'all.footer.constructor',
		links: [
			{
				title: 'all.footer.constructor.how_use_it',
				url: '/articles/2022/08/11/moe-svobodnoe-prostranstvo'
			},
		]
	}
};

export 	const PNG_LOGOS = {
	'novayagazeta.dev' : 'https://novayagazeta.dev/logo.png',
	'novayagazeta.ru' : 'https://novayagazeta.ru/logo.png',
	'novaya.media' : 'https://novaya.media/logo_constructor_v5.png',
	'novaya.no' : 'https://novaya.no/logo_constructor_frontend.png',
	'novayagazeta.ee' : 'https://novayagazeta.ee/logo_baltia.png',
	'novayagazeta.eu' : 'https://novayagazeta.eu/logo_europe_v2.png',
	'mr-7.ru' : 'https://mr-7.ru/logo_mr.png',
}
