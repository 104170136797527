import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const AuthorsMain = lazy(() => import('src/content/Authors/Main'));

const Authors = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.authors' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<AuthorsMain {...props} />
		</MainLayout>
	);
};

export default Authors;
