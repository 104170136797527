import React from 'react';

export const Fb = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
		<title>Facebook</title>
		<path d='M 5 3 h 14 a 2 2 0 0 1 2 2 v 14 a 2 2 0 0 1 -2 2 H 5 a 2 2 0 0 1 -2 -2 V 5 a 2 2 0 0 1 2 -2 m 13 2 h -2.5 A 3.5 3.5 0 0 0 12 8.5 V 11 h -2 v 3 h 2 v 7 h 3 v -7 h 3 v -3 h -3 V 9 a 1 1 0 0 1 1 -1 h 2 V 5 Z' />
	</svg>
);

export const Twitter = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 24 24'
		style={{ padding: '2px' }}
		{...props}
	>
		<title>Twitter</title>
		<path d='M 22.46 6 c -0.77 0.35 -1.6 0.58 -2.46 0.69 c 0.88 -0.53 1.56 -1.37 1.88 -2.38 c -0.83 0.5 -1.75 0.85 -2.72 1.05 C 18.37 4.5 17.26 4 16 4 c -2.35 0 -4.27 1.92 -4.27 4.29 c 0 0.34 0.04 0.67 0.11 0.98 C 8.28 9.09 5.11 7.38 3 4.79 c -0.37 0.63 -0.58 1.37 -0.58 2.15 c 0 1.49 0.75 2.81 1.91 3.56 c -0.71 0 -1.37 -0.2 -1.95 -0.5 v 0.03 c 0 2.08 1.48 3.82 3.44 4.21 a 4.22 4.22 0 0 1 -1.93 0.07 a 4.28 4.28 0 0 0 4 2.98 a 8.521 8.521 0 0 1 -5.33 1.84 c -0.34 0 -0.68 -0.02 -1.02 -0.06 C 3.44 20.29 5.7 21 8.12 21 C 16 21 20.33 14.46 20.33 8.79 c 0 -0.19 0 -0.37 -0.01 -0.56 c 0.84 -0.6 1.56 -1.36 2.14 -2.23 Z' />
	</svg>
);

export const Vk = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 24 24'
		style={{ padding: '5px' }}
		{...props.style}
	>
		<title>VK</title>
		<defs />
		<path
			fillRule='evenodd'
			d='M8.3 0h7.4C22.4 0 24 1.6 24 8.3v7.4c0 6.7-1.6 8.3-8.3 8.3H8.3C1.6 24 0 22.4 0 15.7V8.3C0 1.6 1.6 0 8.3 0zm11.3 8.3c.2-.4 0-1.1-.5-1.1h-1.7c-.4 0-.6.5-.7.8v.1s-1 2.2-2.2 3.6c-.4.4-.6.5-.8.5-.2 0-.3-.1-.3-.5V8.2c0-.4-.2-1-.5-1H10c-.2 0-.4.6-.4.8 0 .2 0 .3.2.4.2.3.4.6.5 1.3v2.6c0 .6-.1.7-.3.7-.6 0-2-2.2-3-4.7v-.1c-.1-.5-.3-1-.7-1H4.6c-.5 0-.6.6-.6.9 0 .5.6 2.8 2.8 5.8a6.4 6.4 0 005.7 2.9c.8 0 .9 0 .9-.4V15c0-.6 0-.6.4-.6.3 0 .7.1 1.8 1.1 1.2 1.2 1.4 1.4 2 1.4h1.6c.5 0 1 0 .8-.4-.2-.5-.7-1.2-1.5-2a12.2 12.2 0 00-1.2-1.3c-.2-.4-.2-.5 0-.8l.1-.2c.5-.7 2-3 2.2-3.8z'
		/>
	</svg>
);

export const Ok = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 13 24'
		style={{ padding: '5px' }}
		{...props.style}
	>
		<title>Odnoklassniki</title>
		<defs />
		<g>
			<path
				fillRule='evenodd'
				d='M12 5.8C12 9 9.4 12 6 12a6 6 0 01-6-6 6 6 0 016-6c3.4 0 6 2.5 6 5.8zM8.6 6c0-1.4-1.2-2.5-2.6-2.5A2.5 2.5 0 003.4 6c0 1.4 1.2 2.5 2.6 2.5 1.4 0 2.6-1.1 2.6-2.5z'
			/>
			<path d='M8.9 17.7c1.1-.3 2.3-.8 3.3-1.4.8-.5 1-1.7.5-2.5s-1.5-1-2.3-.5a7.1 7.1 0 01-7.8 0c-.8-.5-1.8-.3-2.3.5-.5.8-.3 2 .5 2.5 1 .6 2.2 1.1 3.3 1.4L1 21c-.6.7-.6 1.8 0 2.5.4.3.8.5 1.2.5.5 0 .9-.2 1.2-.5l3.2-3.3 3.2 3.3c.6.7 1.7.7 2.4 0 .6-.7.6-1.8 0-2.5l-3.2-3.3z' />
		</g>
	</svg>
);

export const YouTube = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
		<title>YouTube</title>
		<path d='M 10 15 l 5.19 -3 L 10 9 v 6 m 11.56 -7.83 c 0.13 0.47 0.22 1.1 0.28 1.9 c 0.07 0.8 0.1 1.49 0.1 2.09 L 22 12 c 0 2.19 -0.16 3.8 -0.44 4.83 c -0.25 0.9 -0.83 1.48 -1.73 1.73 c -0.47 0.13 -1.33 0.22 -2.65 0.28 c -1.3 0.07 -2.49 0.1 -3.59 0.1 L 12 19 c -4.19 0 -6.8 -0.16 -7.83 -0.44 c -0.9 -0.25 -1.48 -0.83 -1.73 -1.73 c -0.13 -0.47 -0.22 -1.1 -0.28 -1.9 c -0.07 -0.8 -0.1 -1.49 -0.1 -2.09 L 2 12 c 0 -2.19 0.16 -3.8 0.44 -4.83 c 0.25 -0.9 0.83 -1.48 1.73 -1.73 c 0.47 -0.13 1.33 -0.22 2.65 -0.28 c 1.3 -0.07 2.49 -0.1 3.59 -0.1 L 12 5 c 4.19 0 6.8 0.16 7.83 0.44 c 0.9 0.25 1.48 0.83 1.73 1.73 Z' />
	</svg>
);

export const Inst = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 24 24'
		style={{ padding: '2px' }}
		{...props}
	>
		<title>Instagram</title>
		<path d='M 7.8 2 h 8.4 C 19.4 2 22 4.6 22 7.8 v 8.4 a 5.8 5.8 0 0 1 -5.8 5.8 H 7.8 C 4.6 22 2 19.4 2 16.2 V 7.8 A 5.8 5.8 0 0 1 7.8 2 m -0.2 2 A 3.6 3.6 0 0 0 4 7.6 v 8.8 C 4 18.39 5.61 20 7.6 20 h 8.8 a 3.6 3.6 0 0 0 3.6 -3.6 V 7.6 C 20 5.61 18.39 4 16.4 4 H 7.6 m 9.65 1.5 a 1.25 1.25 0 0 1 1.25 1.25 A 1.25 1.25 0 0 1 17.25 8 A 1.25 1.25 0 0 1 16 6.75 a 1.25 1.25 0 0 1 1.25 -1.25 M 12 7 a 5 5 0 0 1 5 5 a 5 5 0 0 1 -5 5 a 5 5 0 0 1 -5 -5 a 5 5 0 0 1 5 -5 m 0 2 a 3 3 0 0 0 -3 3 a 3 3 0 0 0 3 3 a 3 3 0 0 0 3 -3 a 3 3 0 0 0 -3 -3 Z' />
	</svg>
);

export const Tg = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
		<title>Telegram</title>
		<path d='M 9.78 18.65 l 0.28 -4.23 l 7.68 -6.92 c 0.34 -0.31 -0.07 -0.46 -0.52 -0.19 L 7.74 13.3 L 3.64 12 c -0.88 -0.25 -0.89 -0.86 0.2 -1.3 l 15.97 -6.16 c 0.73 -0.33 1.43 0.18 1.15 1.3 l -2.72 12.81 c -0.19 0.91 -0.74 1.13 -1.5 0.71 L 12.6 16.3 l -1.99 1.93 c -0.23 0.23 -0.42 0.42 -0.83 0.42 Z' />
	</svg>
);

export const Tiktok = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='4 4 42 42'
		fill=''
		style={{ padding: '5px' }}
	>
		<title>Tiktok</title>
		<path
			fillRule='evenodd'
			d='M41 4H9C6.243 4 4 6.243 4 9v32c0 2.757 2.243 5 5 5h32c2.757 0 5-2.243 5-5V9c0-2.757-2.243-5-5-5m-3.994 18.323a7.482 7.482 0 0 1-.69.035 7.492 7.492 0 0 1-6.269-3.388v11.537a8.527 8.527 0 1 1-8.527-8.527c.178 0 .352.016.527.027v4.202c-.175-.021-.347-.053-.527-.053a4.351 4.351 0 1 0 0 8.704c2.404 0 4.527-1.894 4.527-4.298l.042-19.594h4.02a7.488 7.488 0 0 0 6.901 6.685v4.67'
		/>
	</svg>
);

export const TiktokLogo = () => (
	<svg
		viewBox='119.51 70.49 561.02 517'
		xmlns='http://www.w3.org/2000/svg'
		height='34'
		width='34'
	>
		<title>Tiktok</title>
		<g fill='#ee1d52'>
			<path d='M196 498.32l1.64 4.63c-.21-.53-.81-2.15-1.64-4.63zM260.9 393.39c2.88-24.88 12.66-38.81 31.09-53.09 26.37-19.34 59.31-8.4 59.31-8.4V267a135.84 135.84 0 0 1 23.94 1.48V352s-32.93-10.94-59.3 8.41c-18.42 14.27-28.22 28.21-31.09 53.09-.09 13.51 2.34 31.17 13.53 46.44q-4.15-2.22-8.46-5.06c-24.65-17.27-29.14-43.18-29.02-61.49zM511.25 147c-18.14-20.74-25-41.68-27.48-56.39h22.82s-4.55 38.57 28.61 76.5l.46.51A132.76 132.76 0 0 1 511.25 147zM621.18 205.8v81.84s-29.12-1.19-50.67-6.91c-30.09-8-49.43-20.27-49.43-20.27s-13.36-8.75-14.44-9.36v169c0 9.41-2.47 32.91-10 52.51-9.83 25.64-25 42.47-27.79 45.91 0 0-18.45 22.75-51 38.07-29.34 13.82-55.1 13.47-62.8 13.82 0 0-44.53 1.84-84.6-25.33a169.63 169.63 0 0 1-24.16-20.26l.2.15c40.08 27.17 84.6 25.33 84.6 25.33 7.71-.35 33.47 0 62.8-13.82 32.52-15.32 51-38.07 51-38.07 2.76-3.44 18-20.27 27.79-45.92 7.51-19.59 10-43.1 10-52.51V231c1.08.62 14.43 9.37 14.43 9.37s19.35 12.28 49.44 20.27c21.56 5.72 50.67 6.91 50.67 6.91v-64.13c9.96 2.33 18.45 2.96 23.96 2.38z' />

			<path
				d='M597.23 203.42v64.11s-29.11-1.19-50.67-6.91c-30.09-8-49.44-20.27-49.44-20.27s-13.35-8.75-14.43-9.37V400c0 9.41-2.47 32.92-10 52.51-9.83 25.65-25 42.48-27.79 45.92 0 0-18.46 22.75-51 38.07-29.33 13.82-55.09 13.47-62.8 13.82 0 0-44.52 1.84-84.6-25.33l-.2-.15a157.5 157.5 0 0 1-11.93-13.52c-12.79-16.27-20.63-35.51-22.6-41a.24.24 0 0 1 0-.07c-3.17-9.54-9.83-32.45-8.92-54.64 1.61-39.15 14.81-63.18 18.3-69.2A162.84 162.84 0 0 1 256.68 303a148.37 148.37 0 0 1 42.22-25 141.61 141.61 0 0 1 52.4-11v64.9s-32.94-10.9-59.3 8.4c-18.43 14.28-28.21 28.21-31.09 53.09-.12 18.31 4.37 44.22 29 61.5q4.31 2.85 8.46 5.06a65.85 65.85 0 0 0 15.5 15.05c24.06 15.89 44.22 17 70 6.68C401.06 474.78 414 459.23 420 442c3.77-10.76 3.72-21.59 3.72-32.79V90.61h60c2.48 14.71 9.34 35.65 27.48 56.39a132.76 132.76 0 0 0 24.41 20.62c2.64 2.85 16.14 16.94 33.47 25.59a130.62 130.62 0 0 0 28.15 10.21z'
				fill='white'
			/>
			<path
				d='M187.89 450.39v.05l1.48 4.21c-.17-.49-.72-1.98-1.48-4.26z'
				fill='#69c9d0'
			/>
			<path
				d='M298.9 278a148.37 148.37 0 0 0-42.22 25 162.84 162.84 0 0 0-35.52 43.5c-3.49 6-16.69 30.05-18.3 69.2-.91 22.19 5.75 45.1 8.92 54.64a.24.24 0 0 0 0 .07c2 5.44 9.81 24.68 22.6 41a157.5 157.5 0 0 0 11.93 13.52 166.64 166.64 0 0 1-35.88-33.64c-12.68-16.13-20.5-35.17-22.54-40.79a1 1 0 0 1 0-.12v-.07c-3.18-9.53-9.86-32.45-8.93-54.67 1.61-39.15 14.81-63.18 18.3-69.2a162.68 162.68 0 0 1 35.52-43.5 148.13 148.13 0 0 1 42.22-25 144.63 144.63 0 0 1 29.78-8.75 148 148 0 0 1 46.57-.69V267a141.61 141.61 0 0 0-52.45 11z'
				fill='#69c9d0'
			/>
			<path
				d='M483.77 90.61h-60v318.61c0 11.2 0 22-3.72 32.79-6.06 17.22-18.95 32.77-36.13 39.67-25.79 10.36-45.95 9.21-70-6.68a65.85 65.85 0 0 1-15.54-15c20.49 10.93 38.83 10.74 61.55 1.62 17.17-6.9 30.08-22.45 36.12-39.68 3.78-10.76 3.73-21.59 3.73-32.78V70.49h82.85s-.93 7.92 1.14 20.12zM597.23 185.69v17.73a130.62 130.62 0 0 1-28.1-10.21c-17.33-8.65-30.83-22.74-33.47-25.59a93.69 93.69 0 0 0 9.52 5.48c21.07 10.52 41.82 13.66 52.05 12.59z'
				fill='#69c9d0'
			/>
		</g>
	</svg>
);
