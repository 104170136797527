import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'src/store/slices/globalSlice';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import * as api from 'src/api';
import useUrlParams from './useUrlParams';

const useColorTheme = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.global.theme);
  const { isPrint } = useUrlParams();

  useLayoutEffect(() => {
    if (isPrint) {
      dispatch(setTheme('light'));
      return;
    }

    if (!api.isDarkThemeEnabled) return;

    let theme = localStorage.getItem(LOCAL_STORAGE_KEYS.THEME);

    if (!theme && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme = 'dark';
    }

    if (theme) dispatch(setTheme(theme));
  }, [dispatch, isPrint]);

  useLayoutEffect(() => {
    const darkThemeClass = 'dark-user-theme';

    if (!api.isDarkThemeEnabled) return;

    document.body.classList.add('transition-all');

    if (theme === 'light') {
      document.body.classList.remove(darkThemeClass);
    } else {
      document.body.classList.add(darkThemeClass);
    }

    setTimeout(() => document.body.classList.remove('transition-all'), 300);
  }, [theme]);
};

export default useColorTheme;
