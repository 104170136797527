import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const IndexScreen = lazy(() => import('src/content/IndexPage'));

const CategorySpb = () => {
	const title = useChangeWindowTitle({ currentTitle: 'all.spb' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<IndexScreen spb />
		</MainLayout>
	);
};

export default CategorySpb;
