import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	setIsSubscriptionOverlaySearchVisible,
	setPreloadedSearch
} from 'src/store/slices/constructorSlice';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';
import { root } from 'src/api';
import { getSubscriptionType } from 'src/utils';

const SearchSubscriptionRedirect = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		const getPreloadedSearch = async () => {
			const searchParams = new URLSearchParams(props.location.search);
			const group = searchParams.get('group');

			if (group) {
				const subscriptions = await fetch(`${root}/get/group/subscriptions?slug=${group}`)
					.then((res) => res.json())
					.then((res) => res.data);

				dispatch(setPreloadedSearch(subscriptions));
			} else {
				dispatch(setPreloadedSearch(searchParams.get('s')));
			}
			dispatch(setIsSubscriptionOverlaySearchVisible(true));

			history.push('/');
		};

		getPreloadedSearch();
	}, []);

	return (
		<div style={{ minHeight: '100vh' }}>
			<Preloader />
		</div>
	);
};

export default SearchSubscriptionRedirect;
