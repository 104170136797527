export function getRangeObject(win) {
	// Gets the first range object
	win = win || window;

	if (win.getSelection) {
		// Firefox/Chrome/Safari/Opera/IE9
		try {
			return win.getSelection().getRangeAt(0); // W3C DOM Range Object
		} catch (err) {
			/* If no text is selected an exception might be thrown */
		}
	}

	return null;
}

export const createStyles = (css) => {
	const style = document.createElement('style');
	document.head.append(style);
	style.appendChild(document.createTextNode(css));
};