import React from 'react';
import MainLayout from 'src/layouts/MainLayout';
import LongreadsContent from 'src/content/Longreads';

const Longreads = (props) => (
	<MainLayout>
		<LongreadsContent {...props} />
	</MainLayout>
);

export default Longreads;
