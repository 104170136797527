import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { clear } from 'src/store/slices/profileSlice';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from './_';
import styles from './LoginModal.module.scss';


const LoginModal = ({ onClose }) => {
	const dispatch = useDispatch();
	const [type, setType] = useState('login'); // login || sign || forgot
	const profile = useSelector(state => state.profile);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
	}, []);

	const handleContentClick = (e) => {
		e.stopPropagation();
	}

	const handleClose = () => {
		dispatch(clear());
		onClose();
		document.body.style.overflow = '';
	}

	const handleForgot = () => {
		dispatch(clear());
		setType('forgot');
	}

	const handleLogin = () => {
		dispatch(clear());
		setType('login');
	}

	const handleSign = () => {
		dispatch(clear());
		setType('sign');
	}

	return (<div className={styles.root}>
		<div className={styles.overlay} onClick={handleClose} />
		<div className={styles.content} onClick={handleContentClick}>
			<button
				type='button'
				className={styles.close}
				onClick={handleClose}
			/>
			<div className={styles.contentBody}>
				{!profile.message && type !== 'forgot' ? (
					<div>
						<button
							onClick={handleLogin}
							type='button'
							className={cx(styles.type, {
								[styles.active]: type === 'login',
							})}
						>
							Войти
						</button>
						<button
							onClick={handleSign}
							type='button'
							className={cx(styles.type, {
								[styles.active]: type === 'sign',
							})}
						>
							Регистрация
						</button>
					</div>
				) : type === 'forgot' ? (
					<button type='button' className={cx(styles.forgot)}>
						Забыли пароль
					</button>
				) : null}
				{type === 'forgot' && <_.Forgot onClose={handleClose} />}
				{type === 'login' && <_.Login
					onClose={handleClose}
					onForgot={handleForgot}
				/>}
				{type === 'sign' && <_.Sign onClose={handleClose} />}
			</div>
		</div>
	</div>
	);
}

export default LoginModal;
