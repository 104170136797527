import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { isEurope, isMedia, isNO, windowTitle } from 'src/api';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const DonateEurope = lazy(() => import('src/content/DonateEurope'));

const Donate = (props) => {
	const { language } = useSelector((state) => state.global);
	const isEnglish = language === 'en';
	// если у кого-то появится такая страница надо добавить название страницы ниже (сейчас только у Европы)
	const title = useChangeWindowTitle({ currentTitle: isEurope ? 'FriendRising' : windowTitle });
	let component = null;

	if (isEurope) {
		const { href } = document.location;
		let ogUrl = href;

		if (isEnglish) {
			ogUrl = `${href}/en`;
		}

		component = (
			<MainLayout>
				<Helmet>
					<title>{title}</title>
					<meta property='og:url' content={ogUrl} />
					<meta name='url' content={ogUrl} />
					<meta name='identifier-URL' content={ogUrl} />
				</Helmet>
				<DonateEurope {...props} />
			</MainLayout>
		);
	} else {
		const href = isNO
			? 'https://bit.ly/no_direct_co'
			: isMedia
			? 'https://bit.ly/construct_direct_co'
			: 'https://bit.ly/ng_direct_co';

		document.location.href = href;
	}
	return component;
};

export default Donate;
