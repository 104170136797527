export const urlTypes = [
	'/inquiries',
	'/news',
	'/stories',
	'/features',
	'/subscription/printed',
	'/subscription/digital',
	'/issues',
	'/price',
	'/vacancies',
	'/views',
	'/politics',
	'/economy',
	'/society',
	'/culture',
	'/sport',
	'/multimedia',
	'/data'
];

export const urlDifTypes = ['/authors/', '/tags', '/themes/'];

export const transcription = {
	stories: 'reportazhi',
	inquiries: 'rassledovanija',
	features: 'sjuzhety',
	promo: 'reklama', // ??
	news: 'novosti',
	online: 'onlajn', // ??
	quiz: 'oprops', // ??
	announcement: 'anons', // ??
	digit: 'cifgra', // ??
	podcasts: 'podkast',
	columnists: 'mnenija-kolonka',
	comment: 'mnenija-kommentarij',
	data: 'data', // infografika
	politics: 'politika',
	economy: 'jekonomika',
	society: 'obshhestvo',
	culture: 'kultura',
	sport: 'sport',
	views: 'mnenija-kolonka', // not exactly
	interview: 'mnenija-kommentarij', // not exactly
	newsletter: 'newsletter',
	petitions: 'petition',
	partners: 'partners',
};

export const swapTranscription = {
	anons: 'announcement',
	cifgra: 'digit',
	jekonomika: 'economy',
	kultura: 'culture',
	'mnenija-kolonka': 'views',
	'mnenija-kommentarij': 'interview',
	novosti: 'news',
	obshhestvo: 'society',
	onlajn: 'online',
	oprops: 'quiz',
	podkasti: 'podcasts',
	politika: 'politics',
	rassledovanija: 'inquiries',
	reklama: 'promo',
	reportazhi: 'stories',
	sjuzhety: 'features',
	sport: 'sport'
};
