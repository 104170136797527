import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { isConstructor, route, staticUrl } from 'src/api';
import { isEmpty } from 'lodash';
import MainLayout from 'src/layouts/MainLayout';
import { fetchTheme } from 'src/store/slices/themeSlice';
import { useParams } from 'react-router';
import Preloader from 'src/components/Base/Elements/Preloader/Preloader';

const ThemeContent = lazy(() => import('src/content/Theme'));
const SubscriptionContent = lazy(() => import('src/content/Subscription'));
const IndexScreen = lazy(() => import('src/content/IndexPage'));
const NotFound = lazy(() => import('src/content/NotFound/NotFound'));

const Theme = () => {
	const { themeId } = useParams();
	const dispatch = useDispatch();
	const { data, error, isPending } = useSelector((state) => state.theme);
	const themeState = data || {};
	const { promoPicture, title, description, slug, color } = themeState.theme || {};
	const { attachedRecords } = themeState;
	const renderTheme = !isEmpty(themeState);
	const isIssueTheme = themeId === 'no';

	useEffect(() => {
		dispatch(fetchTheme(themeId));
	}, [dispatch, themeId]);

	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [data]);

	const defaultBackgroundColor = isIssueTheme
		? 'rgba(147, 136, 108, 0.15)'
		: 'rgba(230, 47, 200, 0.15)';

	const backgroundColor = color || defaultBackgroundColor;

	const snippets = {
		no: 'theme_snippet_no_v2.png',
		explainers: 'theme_snippet_explainers_v2.png',
		prodengi: 'theme_snippet_prodengi_v2.png',
		helloworld: 'theme_snippet_helloworld_v2.png',
		culguides: 'theme_snippet_culguides_v2.png',
		partners: 'theme_snippet_partners_v2.png',
		podcast_sudy: 'theme_snippet_podcast_sudy_v2.png',
		kino: 'theme_snippet_kino_v2.png',
		video: 'theme_snippet_video_v2.png',
		credo: 'theme_snippet_credo_v2.png',
		pastsimple: 'theme_snippet_pastsimple_v2.png',
		pravdagulaga: 'theme_snippet_pravdagulaga_v2.png',
		telekritika: 'theme_snippet_telekritika_v2.png',
		courts: 'theme_snippet_courts_v2.png',
		novayanauka: 'theme_snippet_novayanauka_v2.png',
		memorycard: 'theme_snippet_memorycard_v2.png',
		kozhavremeni: 'theme_snippet_kozhavremeni_v2.png',
		slovami: 'theme_snippet_slovami_v2.png',
		pushkinmaradona: 'theme_snippet_pushkinmaradona.png',
		antropology: 'theme_snippet_antropology.png',
		minkin: 'theme_snippet_minkin.png',
		prosport: 'theme_snippet_prosport.png'
	};

	const seoPicture = `${staticUrl}/previews/theme_${themeId}.jpeg`;
	const isConstructorSnippet = isConstructor && snippets[themeId];
	const shippet = isConstructorSnippet ? `https://${route}/${snippets[themeId]}` : seoPicture;

	return (
		<MainLayout>
			{renderTheme ? (
				<>
					<Helmet>
						<title>{title}</title>
						<meta itemProp='image' content={shippet} />
						<meta name='twitter:image' content={shippet} />
						<meta property='og:image' content={shippet} />
						<meta property='og:image:width' content={1200} />
						<meta property='og:image:height' content={isConstructorSnippet ? 675 : 630} />
						<meta name='twitter:title' content={title} />
						<meta property='og:title' content={title} />
						<meta property='og:long_title' content={title} />
						{isConstructor && <meta name='genre' content='Подписка' />}
						{description && <meta name='twitter:description' content={description} />}
						{description && <meta name='description' content={description} />}
						{description && <meta property='og:description' content={description} />}
						<meta property='og:type' content='webpage' />
						<link
							rel='image_src'
							href={isConstructorSnippet ? `https://${route}/${snippets[themeId]}` : seoPicture}
						/>
					</Helmet>
					{isConstructor ? (
						<SubscriptionContent
							key={themeId}
							image={promoPicture}
							backgroundColor={backgroundColor}
							title={title}
							description={description}
							records={attachedRecords}
							isLoading={!title || isPending}
							isIssueSubscription={isIssueTheme}
							type='theme'
							slug={slug}
						/>
					) : (
						<ThemeContent />
					)}
				</>
			) : (
				<div style={{ minHeight: '100vh' }}>
					{error ? (
						<Suspense fallback={<div />}>
							<NotFound wrongMaterialLink />
							<IndexScreen />
						</Suspense>
					) : (
						<Preloader />
					)}
				</div>
			)}
		</MainLayout>
	);
};

export default Theme;
