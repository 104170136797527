import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';

const globalSlice = createSlice({
	name: 'GLOBAL',
	initialState: {
		areAdScriptsLoaded: false,
		network: {},
		theme: 'light',
		language: localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE) || 'ru',
		isNotificationRequested: false,
		isDonateOverlayFormVisible: false
	},
	reducers: {
		setAreAdScriptsLoaded: (state, action) => {
			state.areAdScriptsLoaded = action.payload;
		},
		setTheme: (state, action) => {
			state.theme = action.payload;
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setIsNotificationRequested: (state, action) => {
			state.isNotificationRequested = action.payload;
		},
		setIsDonateOverlayFormVisible: (state, { payload }) => {
			state.isDonateOverlayFormVisible = payload;
		}
	}
});

export const {
	setAreAdScriptsLoaded,
	setTheme,
	setLanguage,
	setIsNotificationRequested,
	setIsDonateOverlayFormVisible
} = globalSlice.actions;

export const changeTheme = (theme) => (dispatch) => {
	localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, theme);

	dispatch(setTheme(theme));
};
export const changeLang = (lang) => (dispatch) => {
	localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, lang);
	dispatch(setLanguage(lang));
};

export default globalSlice.reducer;
