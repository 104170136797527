import React from 'react';
import MainLayout from 'src/layouts/MainLayout';
import DummyPasswordReset from 'src/content/Profile/PasswordReset/DummyPasswordReset';

const PasswordReset = (props) => (
  <MainLayout>
    <DummyPasswordReset {...props} />
  </MainLayout>
);

export default PasswordReset;
