import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { showSocialIconsInFooter, showSubscriptionFormInFooter } from 'src/api';
import useUrlParams from 'src/hooks/useUrlParams';
import styles from './index.module.scss';

const Subscription = lazy(() => import('./Subscription/Subscription'));
const SocialIcons = lazy(() => import('./SocialIcons/SocialIcons'));
const Navigation = lazy(() => import('./Navigation/Navigation'));

const Footer = () => {
	const { isPrint } = useUrlParams();
	const [showFooter, setShowFooter] = useState(false);
	const refFooter = useRef(null);

	useEffect(() => {
		const onScrollToFooter = () => {
			let current = refFooter.current;
			if (!current) return;
			if (current.getBoundingClientRect().top <= window.innerHeight) {
				setShowFooter(true);
			}
		};
		window.addEventListener('scroll', () => onScrollToFooter());
		return window.removeEventListener('scroll', () => onScrollToFooter());
	}, [refFooter]);

	return (
		<div ref={refFooter} className='Footer'>
			{showFooter && !isPrint ? (
				<footer className={styles.root}>
					<div className={styles.backy}>
						{showSubscriptionFormInFooter && (
							<Suspense fallback={<div />}>
								<Subscription data={1}/>
							</Suspense>
						)}
						<Suspense fallback={<div />}>
							<Navigation />
						</Suspense>
						{showSocialIconsInFooter && (
							<Suspense fallback={<div />}>
								<SocialIcons />
							</Suspense>
						)}
					</div>
				</footer>
			) : null}
		</div>
	);
};

export default Footer;
