import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchArticle = createAsyncThunk(
	`ARTICLE/fetchArticle`,
	async (slug, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/record?slug=${slug}`)
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetch article: ${err}`);
			return rejectWithValue(true);
		}
	}
);

export const fetchOldArticle = createAsyncThunk(
	`ARTICLE/fetchOldArticle`,
	async (slug, { rejectWithValue }) => {
		// for MR7.ru
		try {
			const response = await fetch(`${root}/get/old/record?url=https://mr-7.ru/articles/${slug}/`)
				.then((response) => {
					const err = 'Ошибка, попробуйте позже';
					if (!response.ok) throw err;
					return response.json();
				})
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetch old article: ${err}`);
			return rejectWithValue(true);
		}
	}
);

const articleSlice = createSlice({
	name: 'ARTICLE',
	initialState: {
		article: null,
		error: false,
		isLoading: false,
		firstArticleSlug: null
	},
	reducers: {
		clearError: (state) => {
			state.error = false;
		},
		clearArticle: (state) => {
			state.error = false;
			state.article = null;
		},
		setFirstArticleSlug: (state, { payload }) => {
			state.firstArticleSlug = payload;
		}
	},
	extraReducers: {
		// fetchArticle
		[fetchArticle.pending]: (state) => {
			state.article = null;
			state.error = false;
			state.isLoading = true;
		},
		[fetchArticle.fulfilled]: (state, { payload }) => {
			state.article = payload.record;
			state.error = false;
			state.isLoading = false;
		},
		[fetchArticle.rejected]: (state) => {
			state.article = null;
			state.error = true;
			state.isLoading = false;
		},
		// fetchOldArticle
		[fetchOldArticle.pending]: (state) => {
			state.article = null;
			state.error = false;
			state.isLoading = true;
		},
		[fetchOldArticle.fulfilled]: (state, { payload }) => {

			if (payload.record) {
				state.article = payload.record;
				state.error = false;
			} else {
				state.article = null;
				state.error = true;
			}

			state.isLoading = false;
		},
		[fetchOldArticle.rejected]: (state) => {
			state.article = null;
			state.error = true;
			state.isLoading = false;
		}
	}
});

export const { clearError, setFirstArticleSlug, clearArticle } = articleSlice.actions;

export default articleSlice.reducer;
