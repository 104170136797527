import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const Genre = lazy(() => import('src/content/Genre/Genre'));

const CategoryVideo = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.video' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Genre type='video' pathname={props.location.pathname} />
		</MainLayout>
	);
};

export default CategoryVideo;
