import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { windowTitle } from 'src/api';
import useTranslates from './useTranslates';

const useChangeWindowTitle = ({ currentTitle = windowTitle }) => {
	const { language } = useSelector((state) => state.global);
	const [title] = useTranslates(currentTitle);

	useLayoutEffect(() => {
		document.title = title;
	}, [language]);

	return title;
};

export default useChangeWindowTitle;
