export const CATEGORY = {
	COLUMINSTS: '/columnists',
	DATA: '/data',
	FEATURES: '/features',
	INQUIRIES: '/inquiries',
	NEWSLETTER: '/newsletter',
	SPB: '/spb',
	STORIES: '/stories',
	VIEWS: '/views',
	VIDEO: '/video',
	PETITIONS: '/petitions',
	PODCAST: '/podcasts/:id?',
	POLITICS: '/politics',
	ECONOMY: '/economy',
	SOCIETY: '/society',
	CULTURE: '/culture',
	PROJECTS: '/projects',
	PARTNERS: '/partners'
};
