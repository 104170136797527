import { NUMBER_REGEXP } from "./regexp";

export const calculateTarget = (count) => {
    const limitValue = 500000;

    return count > limitValue ?
        Math.trunc(count / limitValue) * limitValue + limitValue :
        [100, 500, 1000, 3000, 5000, 10000, 50000, 100000, limitValue].find((value) => count < value);
};

export const calculatePercentage = (numb, mainNumb) => {
    const num = (numb / mainNumb) * 100;

    return +num.toFixed(2);
}

export const isNumeric = (value) => NUMBER_REGEXP.test(value);