import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { previews } from 'src/api';
import MainLayout from 'src/layouts/MainLayout';
import { transcription } from 'src/config/static';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';

const Genre = lazy(() => import('src/content/Genre/Genre'));

const CategoryViews = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.opinions' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
				{previews.opinions && <link rel='image_src' href={previews.opinions} />}
				{previews.opinions && <meta itemProp='image' content={previews.opinions} />}
				{previews.opinions && <meta name='twitter:image' content={previews.opinions} />}
				{previews.opinions && <meta property='og:image' content={previews.opinions} />}
				{previews.opinions && <meta property='og:image:width' content={1200} />}
				{previews.opinions && <meta property='og:image:height' content={630} />}
			</Helmet>
			<Genre
				type={transcription[props.location.pathname.substring(1)]}
				pathname={props.location.pathname}
			/>
		</MainLayout>
	);
};

export default CategoryViews;
