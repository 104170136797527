import React, { lazy } from 'react';
import MainLayout from 'src/layouts/MainLayout';
import { transcription } from 'src/config/static';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';

const Genre = lazy(() => import('src/content/Genre/Genre'));

const CategoryPolitics = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.rubric.politika' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Genre
				type={transcription[props.location.pathname.substring(1)]}
				pathname={props.location.pathname}
			/>
		</MainLayout>
	);
};

export default CategoryPolitics;
