import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { isMR7 } from 'src/api';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const RegulationContent = lazy(() => import('src/content/Tech/Regulation'));

const Regulation = (props) => {
	const title = useChangeWindowTitle({
		currentTitle: isMR7 ? 'Правила использования материалов сайта' : 'all.regulation'
	});
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<RegulationContent {...props} />
		</MainLayout>
	);
};

export default Regulation;
