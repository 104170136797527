import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const VacanciesContent = lazy(() => import('src/content/Tech/Vacancy'));

const Vacancies = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.footer.vacancies' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<VacanciesContent {...props} />
		</MainLayout>
	);
};

export default Vacancies;
