import useAnalytics from 'src/hooks/useAnalytics';
import useAppHeight from 'src/hooks/useAppHeight';
import useAppLanguage from 'src/hooks/useAppLanguage';
import useColorTheme from 'src/hooks/useColorTheme';
import useConstructorTheme from 'src/hooks/useConstructorTheme';
import useDynamicAds from 'src/hooks/useDynamicAds';
import useEuropeTheme from 'src/hooks/useEuropeTheme';
import useMr7Theme from 'src/hooks/useMr7Theme';
import useScrollToTop from 'src/hooks/useScrollToTop';

const GlobalManager = () => {
	useAppLanguage();
	useColorTheme();
	useEuropeTheme();
	useDynamicAds();
	useScrollToTop();
	useConstructorTheme();
	useMr7Theme();
	useAppHeight();
	useAnalytics();

	return null;
};

export default GlobalManager;
