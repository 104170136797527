import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isMultiLang, route } from 'src/api';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import useTranslates from 'src/hooks/useTranslates';

const MainMetaTags = () => {
	const { language } = useSelector((state) => state.global);
	const location = useLocation();
	const isEnglish = language === 'en';
	const seoState = useSelector((state) => state.seo);
	const title = useChangeWindowTitle({ currentTitle: seoState.short_title });
	const [keywords, description] = useTranslates(seoState.keywords, seoState.description);
	const [ogUrl, setOgUrl] = useState(`https://${route}/`);
	const isMainPage = location.pathname === '/';
	const [mainSnippet, setMainSnippet] = useState(seoState.picture);

	useEffect(() => {
		const newOgUrl = `https://${route}${location.pathname}${isMainPage? '' : '/'}`;
		if (isMultiLang && isEnglish) {
			const isCurrentPic = mainSnippet === seoState.picture_eng;
			!isCurrentPic && setMainSnippet(seoState.picture_eng);

			if (ogUrl === `https://${route}/`) {
				setOgUrl(`${ogUrl}en`);
			} else {
				setOgUrl(newOgUrl);
			}
		} else {
			const isCurrentPic = mainSnippet === seoState.picture;
			!isCurrentPic && setMainSnippet(seoState.picture);
			setOgUrl(newOgUrl);
		}
	}, [location, language]);

	return (
		<Helmet>
			<title>{title}</title>
			<link rel='image_src' href={mainSnippet} />
			<meta name='description' content={description} />
			<meta name='keywords' content={keywords} />
			<meta itemProp='image' content={mainSnippet} />
			<meta name='twitter:image' content={mainSnippet} />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			<meta property='og:url' content={ogUrl} />
			<meta name='url' content={ogUrl} />
			<meta name='identifier-URL' content={ogUrl} />
			<meta property='og:site_name' content={title} />
			<meta property='og:image' content={mainSnippet} />
			<meta property='og:description' content={description} />
			<meta property='og:image:width' content='800' />
			<meta property='og:image:height' content='420' />
			<meta property='og:title' content={title} />
			<meta property='og:locale' content={isEnglish ? 'en_US' : 'ru_RU'} />
			<meta property='og:long_title' content={title} />
			<meta property='og:type' content='webpage' />
		</Helmet>
	);
};

export default MainMetaTags;
