import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { socialAuthentication } from 'src/store/slices/profileSlice';
import {
	// Fb, 
	Vk
} from 'src/layouts/MainLayout/Footer/SocialIcons/icons';
// import { loadFbSdk } from 'src/utils/asyncScripts';
// import notify from 'components/Base/Elements/Toast';
import Popup from './Popup';


const socialIcons = {
	// Facebook: <Fb />,
	Vkontakte: <Vk />,
};

// export const OAUTH_PROVIDER_FACEBOOK = 'OAUTH_PROVIDER_FACEBOOK';
export const OAUTH_PROVIDER_VK = 'OAUTH_PROVIDER_VK';

const Oauth = (props) => {
	const dispatch = useDispatch();
	const [vkPopupOpen, setVkPopupOpen] = useState(false);

	// const onFbResponse = (response) => {
	// 	if(response.authResponse && response.authResponse.accessToken){
	// 		const body = {
	// 			token: response.authResponse.accessToken,
	// 			type: 2,
	// 		};
	// 		dispatch(socialAuthentication(body));
	// 	}else{
	// 		notify('Авторизация через сервис Facebook временно недоступна.');
	// 	}

	// };

	// const authFacebook = () => {
	// 	loadFbSdk(() => FB.login(onFbResponse, { scope: 'public_profile,email' }));
	// };

	const onVkResponse = (data) => {
		const body = {
			token: data.code,
			type: 1,
		};
		dispatch(socialAuthentication(body));
		setVkPopupOpen(false);
	};

	const getRedirectUri = () => {
		const path = window.location.origin;
		return `${path}/oauth/vk/callback`;
	};

	const getVkOAuthPath = () => {
		const options = {
			redirectUri: getRedirectUri(),
			authorizationUrl: 'https://oauth.vk.com/authorize',
		};

		const params = {
			client_id: 7477477,
			redirect_uri: getRedirectUri(),
			display: 'popup',
			response_type: 'code',
			scope: 'email',
			v: '5.103',
		};
		let oauthpath = '?';
		for (const [key, value] of Object.entries(params)) {
			oauthpath += `${key}=${value}&`;
		}
		return options.authorizationUrl + oauthpath.slice(0, -1);
	};

	const auth = (authType) => {
		switch (authType) {
			// case OAUTH_PROVIDER_FACEBOOK:
			// 	authFacebook();
			// 	break;
			case OAUTH_PROVIDER_VK:
				setVkPopupOpen(true);
				break;
			default:
				break;
		}
	};
	return (
		<div className={props.className} onClick={() => auth(props.provider)}>
			{vkPopupOpen ? (
				<Popup
					redirectUri={getRedirectUri()}
					onAuth={onVkResponse}
					url={getVkOAuthPath()}
				/>
			) : null}
			{socialIcons[props.icon]}
		</div>
	);
};

export default Oauth;
