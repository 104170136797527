import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { dev, root } from 'src/api';

export const fetchMeta = createAsyncThunk(`META/fetchMeta`, async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${root}/get/meta`).then((response) => {
			if (!response.ok) {
				throw response.statusText;
			} else {
				return response.json();
			}
		});
		return response;
	} catch (err) {
		if (dev) console.log(`Error fetchMeta: ${err}`);
		return rejectWithValue(err);
	}
});

const metaSlice = createSlice({
	name: 'META',
	initialState: {
		donatePopupBecome: {},
		donateTextBecome: {},
		donateTextSupport: {},
		favorites: {},
		error: false
	},
	reducers: {},
	extraReducers: {
		[fetchMeta.fulfilled]: (state, { payload }) => {
			const arr = Object.keys(payload);
			arr.forEach((key) => {
				const isString = typeof payload[key] === 'string';
				const isObject = typeof payload[key] === 'object';

				if (isString) {
					state[key] = JSON.parse(payload[key]);
				}

				if (isObject && !isEmpty(payload[key])) {
					state[key] = payload[key];
				}
			});
			state.error = false;
		},
		[fetchMeta.rejected]: (state, { payload }) => {
			state.error = payload;
			state.donatePopupBecome = {};
			state.donateTextBecome = {};
			state.donateTextSupport = {};
			state.favorites = {};
		}
	}
});

export default metaSlice.reducer;
