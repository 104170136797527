import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { forgotPassword } from 'src/store/slices/profileSlice';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import styles from './Forgot.module.scss';

const Forgot = (props) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const profile = useSelector(state => state.profile);

	const handleSubmit = () => {
		dispatch(forgotPassword(email))
			.then((res) => {
				if (!res.payload.error) {
					props.onClose();
				}
			});
	}

	return (<>
		<Helmet>
			<title>Восстановление пароля</title>
		</Helmet>
		<form
			className={styles.root}
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit();
			}}
		>
			<input
				type='email'
				name='email'
				placeholder='E-mail'
				value={email}
				className={styles.input}
				onChange={e => setEmail(e.target.value)}
				required
			/>
			{profile.errors && <div className={styles.error}>{profile.errors}</div>}
			<footer className={styles.footer}>
				<button
					type='submit'
					className={cx(styles.submit, {
						[styles.pending]: profile.isPending,
					})}
				>
					{!profile.isPending ? <span>Отправить</span>
						: <Loader type='TailSpin' color='#8b8c8c' height='30' width='100' />}
				</button>
			</footer>
		</form>
	</>);
}

export default Forgot;
