import React from 'react';
import { Link } from 'react-router-dom';
import { footer_text } from 'src/api';
import useTranslates from 'src/hooks/useTranslates';
import useUrlParams from 'src/hooks/useUrlParams';
import styles from './index.module.scss';

const Registered = () => {
	const { isPrint } = useUrlParams();
	const [text, agreement, privacy] = useTranslates(
		footer_text,
		'all.footer.agreement',
		'all.footer.privacy'
	);

	return !isPrint ? (
		<div className={styles.root}>
			<small className={styles.copyright}>
				{text} <Link to='/terms'>{agreement}</Link>. <Link to='/privacy'>{privacy}</Link>.{' '}
			</small>
		</div>
	) : null;
};

export default Registered;
