// список языков стран СНГ
export const CIS_languages = [
    'ru',
    'kk',
    'uk',
    'ky',
    'mo',
    'az',
    'hy',
    'be',
    'tg',
    'uz'
];