import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { root } from 'src/api';
import { filterRecordsByLanguage } from 'src/utils/filtersArr';
import MainLayout from 'src/layouts/MainLayout';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';

const Posts = lazy(() => import('src/content/Posts'));

const Tag = (props) => {
	const language = useSelector((state) => state.global.language);
	const [posts, setPosts] = useState({});
	const [tag, setTag] = useState(null);
	const newTag = new URLSearchParams(window.location.search).get('name');
	const title = useChangeWindowTitle({ currentTitle: newTag });
	const { search } = props.location;

	const getRecordsByTags = async (tag) => {
		const data = await fetch(`${root}/search?q=${tag}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json());

		const { records } = data;
		if (records) {
			setPosts({ records: filterRecordsByLanguage(data.records, language) });
		}
	};

	useEffect(() => {
		setTag(newTag);
	}, [search]);

	useEffect(() => {
		if (!tag) return;
		setPosts({});
		getRecordsByTags(tag);
	}, [tag, language]);

	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Posts {...props} posts={posts} />
		</MainLayout>
	);
};

export default Tag;
