import { useIntl } from 'react-intl';

const useTranslates = (...messages) => {
  const intl = useIntl();

  return messages.map((message) => {
    if (!message) return '';

    if (typeof message === 'string') return intl.formatMessage({ id: message });

    return intl.formatMessage(...message);
  });
};

export default useTranslates;

