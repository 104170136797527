import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';

const VirusChart = lazy(() => import('src/content/VirusChart'));

const CoronaVirus = (props) => {
  const title = 'Трекер COVID от «Новой газеты»';
  const description =
    'Альтернативные индикаторы, раскрывающие суть пандемии коронавируса в России с марта по сегодняшний день';

  return (
    <>
      <Helmet>
        <title>Трекер COVID от «Новой газеты»</title>
        <link rel='image_src' href='https://novayagazeta.ru/covid_log.png' />
        <meta
          itemProp='image'
          content='https://novayagazeta.ru/covid_log.png'
        />
        <meta
          name='twitter:image'
          content='https://novayagazeta.ru/covid_log.png'
        />
        <meta
          property='og:image'
          content='https://novayagazeta.ru/covid_log.png'
        />
        <meta name='twitter:title' content={title} />
        <meta property='og:title' content={title} />
        <meta property='og:url' content='https://novayagazeta.ru/coronavirus' />
        <meta name='url' content='https://novayagazeta.ru/coronavirus' />
        <meta
          name='identifier-URL'
          content='https://novayagazeta.ru/coronavirus'
        />
        <meta property='og:long_title' content={title} />
        <meta property='og:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
      </Helmet>
      <VirusChart />
    </>
  );
}

export default CoronaVirus;
