import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useTranslates from 'src/hooks/useTranslates';
import Title from '../Title/Title';
import Text from './Text';
import styles from './Column.module.scss';

const Column = ({
	title,
	links,
	notShowOnMobile,
	notShowOnDesktop,
	isBaltia,
	countVacancies = 1,
	children
}) => {
	let type = '';
	if (links[0]?.data) {
		type = links && links[0].data;
	}
	const isWide = type === 'connection';
	return (
		<div
			className={cx(styles.root, {
				[styles.wide]: isWide,
				[styles.mobileShow]: notShowOnMobile,
				[styles.notShowOnDesktop]: notShowOnDesktop,
				[styles.columnBaltia]: isBaltia
			})}
		>
			<Title title={title} />
			<ul className={styles.list}>
				{links
					? links.map(({ url, title, icon, external, isNew, text }, i) => {
							const spb = title === 'all.footer.spb';
							const [newTitle] = useTranslates(title);
							return (
								<li className={styles.item} key={i}>
									{text ? (
										<Text type={type} links={links} />
									) : icon || external ? (
										<a
											href={url || '/'}
											rel='noreferrer'
											className={isNew ? styles.newitem : styles.link}
											target='_blank'
										>
											{newTitle}
										</a>
									) : spb ? (
										<Link
											to={url || '/'}
											activeclassname={styles.active}
											className={styles.link}
											title={newTitle}
										>
											<b>{newTitle}</b>
										</Link>
									) : (
										<Link to={url || '/'} activeclassname={styles.active} className={styles.link}>
											{newTitle}{' '}
											{url === '/vacancies' && (countVacancies ? `(${countVacancies})` : '(0)')}
										</Link>
									)}
								</li>
							);
					  })
					: null}
				{children}
			</ul>
		</div>
	);
};

export default Column;
