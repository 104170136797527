import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dev, root } from 'src/api';

export const fetchNetwork = createAsyncThunk(
	`NETWORK/fetchNetwork`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/network`).then((response) => {
				if (!response.ok) {
					throw response.statusText;
				} else {
					return response.json();
				}
			});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchNetwork: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const networkSlice = createSlice({
	name: 'NETWORK',
	initialState: {
		data: {},
		error: false,
	},
	reducers: {},
	extraReducers: {
		[fetchNetwork.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.error = false;
		},
		[fetchNetwork.rejected]: (state, action) => {
			state.error = action.payload;
			state.data = {};
		},
	},
});

export default networkSlice.reducer;
