import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
	name: 'SEARCH',
	initialState: {
        isOpen: false,
        value: '',
        items: [],
        count: 0,
        tagValue: '',
        search: 'all'
    },
	reducers: {
        getTagValue: (state, action) => {
            state.tagValue = action.payload;
        },
        openSearch: (state) => {
            state.isOpen = true;
        },
        closeSearch: (state) => {
            state.isOpen = false;
            state.tagValue = '';
        },
        setSearch: (state, action) => {
            state.search = action.payload;
            state.tagValue = '';
        },
        removeTagValue: (state) => {
            state.tagValue = '';
        }
    },
});
export const { getTagValue, removeTagValue, setSearch, openSearch, closeSearch } = searchSlice.actions;
export default searchSlice.reducer;