import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const AdvertisingContent = lazy(() => import('src/content/Tech/Advertising'));

const Advertising = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'Реклама на сайте' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<AdvertisingContent {...props} />
		</MainLayout>
	);
};

export default Advertising;
