import useTranslates from 'src/hooks/useTranslates';
import React, { useEffect, useState } from 'react';
import * as api from 'src/api';
import { getRangeObject } from 'src/utils/dom';
import styles from './Typo.module.scss';

const Typo = () => {
	const availableClassName = /ignore-typo/g;

	const [comment, setComment] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const [beforeText, setBeforeText] = useState('');
	const [mistake, setMistake] = useState('');
	const [afterText, setAfterText] = useState('');
	const [slug, setSlug] = useState('');
	const [code, setCode] = useState('');

	const [
		errorTitle,
		errorButton,
		errorInput,
		finish,
		finishText
	] = useTranslates('all.find.error', 'all.find.error.button', 'all.find.error.input', 'all.find.error.finish', 'all.find.error.finish.text');

	const handleWindowKeypress = (e) => {
		if (
			e.ctrlKey &&
			(e.code === 'Enter' ||
				e.which === 13 ||
				e.charCode === 13 ||
				e.keyCode === 13)
		) {
			e.preventDefault();
			const range = getRangeObject();
			const {
				startOffset,
				endOffset,
				startContainer,
				endContainer,
				commonAncestorContainer,
			} = range;

			let isAvailable = true;
			let code = window.location.pathname;
			let parent = commonAncestorContainer;
			const slug = window.location.pathname.split('/').slice(-4).join('/');

			while (parent !== null) {
				if (parent.getAttribute && parent.getAttribute('data-code')) {
					code = parent.dataset.code;
				}
				if (
					parent.getAttribute &&
					availableClassName.test(parent.getAttribute('class'))
				) {
					isAvailable = false;
					break;
				}

				parent = parent.parentNode;
			}

			if (isAvailable) {
				setIsShown(true);
				setBeforeText(
					startOffset > 20
						? `«...${startContainer.textContent.substr(startOffset - 20, 20)}`
						: startContainer.textContent.substr(0, startOffset)
				);
				setMistake(String(range));
				setAfterText(
					endOffset > endContainer.textContent.length - 20
						? endContainer.textContent.substr(endOffset, 20)
						: `${endContainer.textContent.substr(endOffset, 20)}...»`
				);
				setCode(code);
				setSlug(slug);
			}
		}
	};

	const handleClose = () => {
		setIsShown(false);
		setComment('');
		setIsSubmitted(false);
		setIsSubmitting(false);
		setBeforeText('');
		setMistake('');
		setAfterText('');
		setSlug('');
		setCode('');
	};

	useEffect(() => {
		window.addEventListener('keydown', handleWindowKeypress, false);
		return () =>
			window.removeEventListener('keydown', handleWindowKeypress, false);
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			slug,
			error: `${beforeText}\`${mistake}\`${afterText}`,
			comment,
		};

		if (!isSubmitting) {
			setIsSubmitting(true);
			fetch(`${api.root}/add/record/error`, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(() => {
					setIsSubmitted(true);
					setIsSubmitting(false);
				})
				.catch((error) => {
					if (api.dev) {
						console.log('error', error);
					}
				});
		}
	};

	return isShown ? (
		<div className={styles.root}>
			<div className={styles.overlay} onClick={handleClose}>
				<div className={styles.container}>
					<button
						type='button'
						className={styles.close}
						onClick={handleClose}
					/>

					{isSubmitted ? (
						<h2 className={styles.success}>
							<span>{finish}</span>
							<small>{finishText}</small>
						</h2>
					) : (
						<form
							action={`${api.root}/${'emptyapi'}`}
							method='POST'
							onClick={(e) => e.stopPropagation()}
							onSubmit={handleSubmit}
						>
							<h2 className={styles.title}>{errorTitle}</h2>
							<p className={styles.text}>
								<span>{beforeText}</span>
								<strong>{mistake}</strong>
								<span>{afterText}</span>
							</p>
							<input
								type='hidden'
								name='body'
								value={beforeText + mistake + afterText}
							/>
							<input type='hidden' name='code' value={code} />
							<div className={styles.textarea}>
								<input
									type='text'
									name='comment'
									value={comment}
									className={styles.input}
									maxLength={140}
									onChange={(e) => setComment(e.target.value)}
									placeholder={errorInput}
								/>
								<span>{comment.length}/140</span>
							</div>
							<button type='submit' className={styles.submit}>
								{errorButton}
							</button>
						</form>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default Typo;
