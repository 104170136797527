import { useLayoutEffect } from 'react';
import * as api from 'src/api';

const useEuropeTheme = () => {
  useLayoutEffect(() => {
    const novayaEuropeClass = 'novaya-europe-theme';
    if (api.isEurope) {
      const mainContainer = document.getElementById('ng-root');
      mainContainer.classList.add(novayaEuropeClass);
    }
  }, []);
};

export default useEuropeTheme;
