import React from 'react';
import MainLayout from 'src/layouts/MainLayout';
import ConfirmationContent from 'src/content/Profile/Confirmation';

const Confirmation = (props) => (
  <MainLayout>
    <ConfirmationContent {...props} />
  </MainLayout>
);

export default Confirmation;
