import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import useChangeWindowTitle from 'src/hooks/useChangeWindowTitle';
import MainLayout from 'src/layouts/MainLayout';

const Subscriptions = lazy(() => import('src/content/Tech/Subscription'));

const SubscriptionsDigital = (props) => {
	const title = useChangeWindowTitle({ currentTitle: 'all.footer.subscribe.title' });
	return (
		<MainLayout>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Subscriptions {...props} />
		</MainLayout>
	);
};

export default SubscriptionsDigital;
