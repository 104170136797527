export const SERVICE = {
	ARTICLE_PREVIEW: '/preview/:id',
	PRIVACY: '/privacy',
	REGULATION: '/regulation',
	SUBSCRIPTIONS_DIGITAL: '/subscription/digital',
	SUBSCRIPTIONS_PRINTED: '/subscription/printed',
	SUBSCRIPTIONS_EMAIL: '/landing/email',
	TERMS: '/terms',
	VACANCIES: '/vacancies',
	ADVERTISING: '/reklama',
	AGE_LIMIT: '/18plus',
};
