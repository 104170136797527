import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { root, dev } from 'src/api';

export const fetchAuthors = createAsyncThunk(
	`AUTHORS/fetchAuthors`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/authors`, {
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchAuthors: ${err}`);
			return rejectWithValue(err);
		}
	}
);

export const fetchAuthor = createAsyncThunk(
	`AUTHORS/fetchAuthor`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetch(`${root}/get/author?id=${id}&page=0`, {
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' }
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.error) {
						throw data.error;
					} else {
						return data;
					}
				});
			return response;
		} catch (err) {
			if (dev) console.log(`Error fetchAuthor: ${err}`);
			return rejectWithValue(err);
		}
	}
);

const authorSlice = createSlice({
	name: 'AUTHORS',
	initialState: {
		authors: null,
		author: null,
		error: false,
		isPending: false
	},
	reducers: {
		clearError: (state) => (state.error = false)
	},
	extraReducers: {
		// fetchAuthors
		[fetchAuthors.fulfilled]: (state, action) => {
			state.authors = action.payload.authors.sort((a, b) =>
				a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1
			);
		},
		[fetchAuthors.rejected]: (state, action) => {
			state.authors = null;
		},
		// fetchAuthor
		[fetchAuthor.fulfilled]: (state, action) => {
			state.author = action.payload.author;
			state.isPending = false;
		},
		[fetchAuthor.rejected]: (state, action) => {
			state.author = null;
			state.isPending = false;
		},
		[fetchAuthor.pending]: (state) => {
			state.isPending = true;
		}
	}
});

export default authorSlice.reducer;
