export const shuffleArray = (array) => {
    const arrayCopy = [...array];

    let currentIndex = arrayCopy.length;
    let randomIndex;

    while (currentIndex != 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [arrayCopy[currentIndex], arrayCopy[randomIndex]] = [
            arrayCopy[randomIndex], arrayCopy[currentIndex]];
    }

    return arrayCopy;
};

export const getMostFrequentItemInArray = (array) => {
    const arrayCopy = [...array];

    return arrayCopy.sort((a, b) =>
        arrayCopy.filter(v => v === a).length
        - arrayCopy.filter(v => v === b).length
    ).pop();
};
