export const loadFbSdk = (callback) => {
	const existingScript = document.getElementById('fbSdk');
	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.src = 'https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v3.2';
		script.id = 'fbSdk';
		script.crossorigin = 'anonymous';
		document.body.appendChild(script);
		script.addEventListener('load', () => {
			window.isFbSdkLoaded = true;
		});
	}

	if (window.isFbSdkLoaded && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};

export const loadCloudpayments = (callback) => {
	const existingScript = document.getElementById('cloudpayments');
	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
		script.id = 'cloudpayments';
		document.body.appendChild(script);
		script.addEventListener('load', () => {
			window.isCloudpaymentsLoaded = true;
		});
	}

	if (window.isCloudpaymentsLoaded && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};

export const loadYoomoney = (callback) => {
	const existingScript = document.getElementById('yoomoneycheckout');
	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.id = 'yoomoneycheckout';
		script.src = 'https://static.yoomoney.ru/checkout-js/v1/checkout.js';
		document.body.appendChild(script);
		script.addEventListener('load', () => {
			window.isYooMoneyCheckout = true;
		});
	}

	if (window.YandexCheckout && window.YooMoneyCheckout && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};
export const loadStripe = (callback) => {
	const existingScript = document.getElementById('stripecheckout');
	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.id = 'stripecheckout';
		script.src = 'https://js.stripe.com/v3/';
		document.head.appendChild(script);
		script.addEventListener('load', () => {
			window.isStripeCheckout = true;
		});
	}

	if (window.isStripeCheckout && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};

export const loadGoogleAccount = (callback) => {
	const existingScript = document.getElementById('googleAccount');
	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.id = 'googleAccount';
		script.src = 'https://accounts.google.com/gsi/client';
		document.head.appendChild(script);
		script.addEventListener('load', () => {
			window.isGoogleAccountLoaded = true;
		});
	}

	if (window.isGoogleAccountLoaded && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};

export const loadPochtaWidget = (callback) => {
	const existingScript = document.getElementById('pochtaWidget');

	let script;

	if (!existingScript) {
		script = document.createElement('script');
		script.id = 'pochtaWidget';
		script.defer = true;
		script.type = "text/javascript";
		script.src = 'https://widget.pochta.ru/map/widget/widget.js';
		document.body.appendChild(script);
		script.addEventListener('load', () => {
			window.isPochtaWidgetLoaded = true;
		});
	}

	if (window.isPochtaWidgetLoaded && callback) return callback();

	(script || existingScript).addEventListener('load', () => {
		if (callback) callback();
	});
};
