import { LOCAL_STORAGE_KEYS } from 'src/config/localStorageKeys';
import { getLocalStorageParsedValue } from './utils';

// ⚠️ Following lines can't be changed.
export const host = window.location.host;
export const dev = host === 'novayagazeta.dev';

// ✔️ This line can be changed if you need another host on the local or dev
const devHost = host;

// ⚠️ This line can't be changed.
export const route = dev ? devHost : host;

const variableСonversionFromYML = (variable) => {
	// ⚠️ Use when a boolean or a string without objects or arrays can come
	let newVar;
	try {
		newVar = JSON.parse(variable);
	} catch (e) {
		newVar = variable;
	}
	return newVar;
};

export const isDevelopment = process.env.NODE_ENV === 'development';
export const root = `https://${route}/api/v1`;
export const article = `https://${route}/articles`;
export const articleAmp = `https://${route}/amp/articles`;
export const socket = `wss://${route}/socket`;
export const staticUrl = `https://${route}/static`;
export const isBaltia = route === 'novayagazeta.ee';
export const constructor = route === 'novaya.no' || route === 'novaya.media';
export const isEurope = route === 'novayagazeta.eu' || route === 'novayagazeta.dev';
export const isMoscow = route === 'novayagazeta.ru';
export const isNO = route === 'novaya.no';
export const isMedia = route === 'novaya.media';
export const isMR7 = route === 'new.mr-7.ru' || route === 'mr-7.ru';
export const isNovayaEdition = isMoscow || isNO || isMedia;

export const NOVAYA = 'https://novayagazeta.ru';
export const NOVAYA_AMP =
	'https://novayagazeta-ru.cdn.ampproject.org/c/s/novayagazeta.ru/amp/articles';
export const NOVAYA_DEV = 'https://novayagazeta.dev';
export const NOVAYA_BALTIA = 'https://novayagazeta.ee';
export const NOVAYA_BALTIA_AMP =
	'https://novayagazeta-ee.cdn.ampproject.org/c/s/novayagazeta.ee/amp/articles';
export const NOVAYA_EUROPE = 'https://novayagazeta.eu';
export const NOVAYA_EUROPE_AMP =
	'https://novayagazeta-eu.cdn.ampproject.org/c/s/novayagazeta.eu/amp/articles';
export const NOVAYA_NO = 'https://novaya.no';
export const NOVAYA_NO_AMP = 'https://novaya-no.cdn.ampproject.org/c/s/novaya.no/amp/articles';
export const NOVAYA_MEDIA = 'https://novaya.media';
export const NOVAYA_MEDIA_AMP =
	'https://novaya-media.cdn.ampproject.org/c/s/novaya.media/amp/articles';

export const snippet_picture_rus =
	process.env.REACT_APP_BASE_PICTURE_RUS || '/main_snippet_europe_v4.png';
export const snippet_picture_eng =
	process.env.REACT_APP_BASE_PICTURE_ENG || '/main_snippet_europe_v4_eng.png';
export const snippet_keywords = process.env.REACT_APP_BASE_KEYWORDS || 'all.keywords.europe';
export const snippet_description =
	process.env.REACT_APP_BASE_DESCRIPTION || 'all.description.europe';

export const push_topic = process.env.REACT_APP_BASE_PUSH_TOPIC || 'dev-main-news-v4';

export const pushNotification = process.env.REACT_APP_BASE_PUSH_NOTIFICATION
	? JSON.parse(process.env.REACT_APP_BASE_PUSH_NOTIFICATION)
	: {
			vapidKey:
				'BKDsHxr9DL-yQwds9lzclrG39cRLvS7r8mVP9R2MgoQEowYMyMpJsdylEGHcNp5_9tHIZEk5z3RCqO2xWbGwiSY',
			config: {
				apiKey: 'AIzaSyC61N_N9qMI9Wlx70pNVWRU0Dnjp_Ny_3E',
				authDomain: 'novaya-project.firebaseapp.com',
				databaseURL: 'https://novaya-project.firebaseio.com',
				projectId: 'novaya-project',
				storageBucket: 'novaya-project.appspot.com',
				messagingSenderId: '376149819123',
				appId: '1:376149819123:web:559ebed4a81eccb3fe0186',
				measurementId: 'G-C2XW7S0QCK'
			}
	  };

export const logo = process.env.REACT_APP_BASE_LOGO || '/ic_mainLogo_mr.svg';

export const windowTitle = process.env.REACT_APP_BASE_WINDOW_TITLE || 'all.title.europe';

export const isMultiLang = JSON.parse(process.env.REACT_APP_BASE_IS_MULTI_LANG || true);

export const defaultLang = process.env.REACT_APP_BASE_IS_DEFAULT_LANG || 'ru';

export const previews = JSON.parse(
	process.env.REACT_APP_BASE_PREVIEWS ||
		'{"news":"/novaya_news.jpg","infographics":"/novaya_infographics.jpg","investigations":"/novaya_investigations.jpg","opinions":"/novaya_opinions.jpg","podcasts":"/novaya_podcasts.jpg"}'
);

export const showCookiesPopup = JSON.parse(process.env.REACT_APP_BASE_IS_COOKIE_POLICY || false);

export const isTop6Enabled = JSON.parse(process.env.REACT_APP_BASE_IS_TOP6_ENABLED || false);

export const isTagsEnabled = JSON.parse(process.env.REACT_APP_BASE_IS_TAGS_ENABLED || false);

export const isDarkThemeEnabled = JSON.parse(
	process.env.REACT_APP_BASE_IS_DARK_THEME_ENABLED || false
);

export const showProfile = JSON.parse(process.env.REACT_APP_BASE_IS_PROFILE || false);

export const showDonate = process.env.REACT_APP_BASE_IS_DONATE_ENABLED
	? variableСonversionFromYML(process.env.REACT_APP_BASE_IS_DONATE_ENABLED)
	: 'stripe'; // 'stripe', 'yoomoney' or 'cloudpayments' or false

export const stripeKey = process.env.REACT_APP_BASE_STRIPE_KEY
	? variableСonversionFromYML(process.env.REACT_APP_BASE_STRIPE_KEY)
	: 'pk_test_51LBdC3CKm8wAsWKGbPvNsNLqJ2PvDxUf1UiYhQSPym5CopRImszmLWiKUyDa6CU9Bl2kC1MMvRVnBUxHhpf1MM8j00Itpw3KGY';

// pk_live_51LBdC3CKm8wAsWKGYrtL3zjgAnFOGrD0DXtBpZ8rj0b1GMuQmlslZcR8hkLiq3Ds9kwSuZ64U5eIYpLPBDJxqDn900YSWGPndb
// для тестирования pk_test_51LBdC3CKm8wAsWKGbPvNsNLqJ2PvDxUf1UiYhQSPym5CopRImszmLWiKUyDa6CU9Bl2kC1MMvRVnBUxHhpf1MM8j00Itpw3KGY

export const reCaptchaKey = process.env.REACT_APP_BASE_RECAPTCHA_KEY
	? variableСonversionFromYML(process.env.REACT_APP_BASE_RECAPTCHA_KEY)
	: '6Lc_0FAfAAAAAO-usyIvvc7bdhJ5IP9ntqVvPs4b';

export const showShop = JSON.parse(process.env.REACT_APP_BASE_IS_SHOP_ENABLED || true);

export const showViews = JSON.parse(process.env.REACT_APP_BASE_IS_VIEWS_ENABLED || false);

export const showSocialIconsInFooter = JSON.parse(
	process.env.REACT_APP_BASE_IS_FOOTER_SOCIAL_ICONS_ENABLED || false
);

export const showNewsroom = JSON.parse(process.env.REACT_APP_BASE_IS_NEWSROOM_ENABLED || true);

export const showRead = JSON.parse(process.env.REACT_APP_BASE_IS_READ_ENABLED || false);

export const showListen = JSON.parse(process.env.REACT_APP_BASE_IS_LISTEN_ENABLED || false);

export const showPush = JSON.parse(process.env.REACT_APP_BASE_IS_PUSH_ENABLED || true);

export const showRubrics = JSON.parse(process.env.REACT_APP_BASE_IS_RUBRICS_ENABLED || false);

export const showSubscriptionFormInFooter = JSON.parse(
	process.env.REACT_APP_BASE_IS_SUBSCRIPTION_IN_FOOTER_ENABLED || true
);

export const footer_text = process.env.REACT_APP_BASE_FOTTER_TEXT || 'all.footer.constructor.text';

export const headerMaterials = JSON.parse(
	process.env.REACT_APP_BASE_HEADERMATERIALS ||
		'{"ru":[{"title":"all.news","url":"/news","icon":"/ic_speaker_24dp.svg"},{"title":"all.stories","url":"/stories","icon":"/ic_newspaper_24dp.svg"},{"title":"all.opinions","url":"/views","icon":"/ic_user_24dp.svg"},{"title":"all.video","url":"/video","icon":"/ic_video_24dp.svg"}],"en":[{"title":"all.news","url":"/news","icon":"/ic_speaker_24dp.svg"},{"title":"all.stories","url":"/stories","icon":"/ic_newspaper_24dp.svg"}]}'
);

export const socials = JSON.parse(
	process.env.REACT_APP_BASE_FOOTER_SOCIALS ||
		'{"title":"all.footer.socials","links":[{"icon":"Twitter","external":true,"url":"https://twitter.com/novayagazeta_eu","title":"Twitter"},{"icon":"Twitter","external":true,"url":"https://twitter.com/novayagazeta_en","title":"Twitter", "isNew":true},{"icon":"Youtube","external":true,"url":"https://www.youtube.com/channel/UCO1lJlI3gjuOMrKppvPlDww","title":"YouTube"},{"icon":"Instagram","external":true,"url":"https://www.instagram.com/novayagazeta_eu","title":"Instagram"},{"icon":"Instagram","external":true,"url":"https://www.instagram.com/novayagazeta_eu.eng","title":"Instagram", "isNew":true},{"icon":"Telegram","external":true,"url":"https://t.me/novaya_europe","title":"Telegram"},{"icon":"Facebook","external":true,"url":"https://www.facebook.com/Novaya-Gazeta-Europe-107512075260896","title":"Facebook"},{"icon":"TikTok","external":true,"url":"https://www.tiktok.com/@novayagazeta_eu?_t=8WdrffkgqOo&_r=1","title":"TikTok"}],"isSocial":true}'
);

export const materials = JSON.parse(
	process.env.REACT_APP_BASE_FOOTER_READ || '{"title":"all.footer.read","links":[]}'
);

export const newsroom = JSON.parse(
	process.env.REACT_APP_BASE_FOOTER_NEWSROOM ||
		'{"title":" all.footer.editorial","links":[{"title":"all.authors","url":"/authors"},{"title":"all.footer.newspaper_archive","url":"/issues"},{"title": "Магазин «Новой газеты»","external": "true","target": "_blank","url": "https://bit.ly/puzzle_footer_shop_new"}]}'
);

export const footerListen = JSON.parse(process.env.REACT_APP_BASE_FOOTER_LISTEN || '[]');

export const footerPush = JSON.parse(
	process.env.REACT_APP_BASE_FOOTER_PUSH ||
		'[{"title":"«КОНВЕРТ»: лучшее из подписок","external":true,"target":"_blank","url":"https://forms.sendpulse.com/8670eb6d40"},{"title":"«ПОВЕСТКА»: новости репрессий","external":true,"target":"_blank","url":"https://forms.sendpulse.com/dcf19babd6"},{"title":"Newsletter","url":"/newsletter"},{"title":"RSS","external":true,"target":"_blank","url":"https://novaya.media/feed/rss"},{"title":"all.footer.push.rss.telegram","external":true,"target":"_blank","url":"https://t.me/novaya_media_rss"}]'
);

export const informer = JSON.parse(
	process.env.REACT_APP_BASE_INFORMER ||
		'{"short": "all.informer.short", "long": "all.informer.long", "shortButton": "all.informer.short.button"}'
);

export const isPreOrderForm =
	(isNO || isMedia) && JSON.parse(process.env.REACT_APP_BASE_IS_PROMO_PREORDERFORM || false);

export const mainComponent = process.env.REACT_APP_MAIN_COMPONENT || 'PictureOfDayComponent';

export const isConstructor = mainComponent === 'ConstructorComponent';
export const isLandingConstructor = mainComponent === 'LandingConstructorComponent';
export const isPictureOfDayComponent = mainComponent === 'PictureOfDayComponent';
export const isMR7Component = mainComponent === 'MR7Component';

export const mainArticleForLandingConstructor = dev
	? '2022/02/18/zdes-nikto-ne-priznaetsia-vse-oblazhalis-po-polnoi-programme'
	: '2022/07/14/no-iiul-2022';

// hide ads on Baltia and mr-7 website
if (isBaltia) {
	localStorage.setItem(LOCAL_STORAGE_KEYS.NO_ADS, true);
}

export const isNoAdsProfile = getLocalStorageParsedValue(LOCAL_STORAGE_KEYS.NO_ADS);

export const iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const googleAuthClientId =
	process.env.REACT_APP_GOOGLE_AUTH_CLIENTID ||
	'782762183020-e6v3oa8cbojsra75ou9br1m22t0tvshs.apps.googleusercontent.com';

export const onthe_key = process.env.REACT_APP_BASE_ONTHE_KEY || 'Vn5QGLgZ9j8q';

export const pochtaWidgetKey = process.env.REACT_APP_BASE_POCHTA_WIDGET_KEY || 35173;

export const yandexTrackingId = process.env.REACT_APP_BASE_YANDEX_TRACKING_ID || 38558415;

export const googleTrackingId = process.env.REACT_APP_BASE_GOOGLE_TRACKING_ID || 'G-XRXV3GVQH5';

if (isNO) {
	console.log('Special thanks to Amund Trellevik for domain name rescue');
}
